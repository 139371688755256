import { combineReducers } from "redux";
import emptyStateReducer from "./emptyStateReducer";
import principalOutstandingReducer from "./principalOutstandingReducer";
import filteringReducer from "./filteringReducer";
import holdingsReducer from "./holdingsReducer";
import loadingReducer from "./loadingReducer";
import paginationReducer from "./paginationReducer";
import sortingReducer from "./sortingReducer";

import viewReducer from "../../common/reducers/viewReducer";
import configReducer from "../../common/reducers/configReducer";
import errorReducer from "../../common/reducers/errorReducer";

const rootReducer = combineReducers({
  config: configReducer,
  emptyState: emptyStateReducer,
  error: errorReducer,
  filtering: filteringReducer,
  principalOutstanding: principalOutstandingReducer,
  holdings: holdingsReducer,
  loading: loadingReducer,
  pagination: paginationReducer,
  sorting: sortingReducer,
  view: viewReducer,
});

export default rootReducer;
