import moment from "moment";

export const formatDate = (date = new Date(), format = "YYYY-MM-DD") => moment(date).format(format);

export const getDateWithCurrentTime = (year, month, date) => {
  const dateTime = new Date();
  dateTime.setFullYear(year);
  dateTime.setMonth(month);
  dateTime.setDate(date);
  return new Date(dateTime);
};

export const ordinalizeNumber = (number) => {
  let monthDateDisplay;
  if (number.substr(-1) === "1" && (number.substr(-2) !== "11")) {
    monthDateDisplay = `${number}st`;
  } else if (number.substr(-1) === "2" && number.substr(-2) !== "12") {
    monthDateDisplay = `${number}nd`;
  } else if (number.substr(-1) === "3" && number.substr(-2) !== "13") {
    monthDateDisplay = `${number}rd`;
  } else {
    monthDateDisplay = `${number}th`;
  }
  return monthDateDisplay;
};

export const capitalize = string => (string[0].toUpperCase() + string.substring(1));
export const formatMoney = (amount) => {
  if (amount !== undefined && amount !== null && amount !== "") {
    return (amount / 100).toLocaleString("USD", { minimumFractionDigits: 2 });
  }
  return "";
};
