import React from "react";
import { connect } from "react-redux";
import { createField } from "../containers/Field";
import { createStep } from "../containers/Step";
import ConcealedTextField from "../components/ConcealedTextField";
import Submit from "../components/Submit";

const STEP_NAME = "setPassword";

const Field = createField(STEP_NAME);

const View = ({
  handleSubmit,
  step
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-mb-none">Set your password</h2>

        <p className="u-mb-large">When you log in for the first time, you will be asked to complete your investor profile before you start investing.</p>

        <form onSubmit={handleSubmit()}>
          <Field
            label="Password"
            name="password"
            component={ConcealedTextField}
            validates={["passwordFormat"]} />

          <Field
            label="Confirm password"
            name="passwordConfirmation"
            component={ConcealedTextField}
            validates={["matchesPassword", "passwordFormat"]} />

          <p className="u-mb-large">By clicking ‘Create account’ you agree to our privacy policy and terms of use. </p>

          <Submit value="Create account" step={step} />
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = ({ stepModes }) => {
  return {
    step: STEP_NAME,
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
