import _ from "lodash";
import {
  LOAD, EDIT_FREQUENCY, EDIT_RECURRENCE,
  EDIT_DAY_OF_WEEK, EDIT_DATE_OF_MONTH, EDIT_TRANSFER_START_DATE,
  EDIT_AMOUNT, SHOW_REVIEW_AND_CONFIRM_MODAL,
  SHOW_ADD_BANK_ACCOUNT_MODAL, SHOW_REST_OF_THE_TRANSFER_FORM,
  UPDATE_FIELDS_AND_VALUES, TRANSFER_SUCCESS, TRANSFER_FAILURE,
  ADD_FUNDS, RECURRING_TRANSFERS, ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILURE, SHOW_MODAL, CLOSE_MODAL,
  SHOW_TRANSFER_INITIATED_MODAL, SUBMIT_TRANSFER, SELECT_TRANSFER_TYPE,
} from "../constants";
import { getDateWithCurrentTime } from "../helpers";

export const createOrUpdate = (payload) => {
  const fieldsAndValues = {};
  payload.forEach((fieldAndValue) => {
    fieldsAndValues[fieldAndValue.field] = fieldAndValue.value;
  });
  return fieldsAndValues;
};

const calculateStartDate = (state, action) => {
  const actionField = action.payload.field;

  if (actionField === "recurring" && action.payload.value === "false") {
    return new Date();
  }

  const stateFields = _.without(["recurring", "frequency", "dayOfWeek", "dateOfMonth"], actionField);

  let fields = {};
  stateFields.forEach((field) => {
    fields[field] = state[field];
  });
  fields = _.mergeWith(fields, { [actionField]: action.payload.value });
  let transferDate = new Date();

  if (!_.isEmpty(fields.recurring) && !_.isEmpty(fields.frequency)) {
    const now = new Date();
    if (fields.frequency === "monthly" && fields.dateOfMonth) {
      const currentDateOfMonth = now.getDate();
      const currentMonth = now.getMonth() + 1;
      const month = fields.dateOfMonth < currentDateOfMonth ? currentMonth : currentMonth - 1;
      transferDate = getDateWithCurrentTime(now.getFullYear(), month, fields.dateOfMonth);
    }

    if (fields.frequency === "weekly" && fields.dayOfWeek) {
      const dayOffset = fields.dayOfWeek >= now.getDay()
        ? fields.dayOfWeek - now.getDay()
        : fields.dayOfWeek - now.getDay() + 7;
      transferDate = new Date();
      transferDate.setDate(now.getDate() + dayOffset);
    }
  }
  return transferDate;
};

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD:
      return { ...action.payload };
    case EDIT_AMOUNT:
    case EDIT_TRANSFER_START_DATE:
    case SHOW_REVIEW_AND_CONFIRM_MODAL:
    case SHOW_TRANSFER_INITIATED_MODAL:
    case SHOW_REST_OF_THE_TRANSFER_FORM:
    case SHOW_ADD_BANK_ACCOUNT_MODAL:
    case SHOW_MODAL:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case EDIT_DAY_OF_WEEK:
    case EDIT_DATE_OF_MONTH:
    case EDIT_FREQUENCY:
    case EDIT_RECURRENCE:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        transferStartDate: calculateStartDate(state, action),
      };
    case UPDATE_FIELDS_AND_VALUES:
      return {
        ..._.omit(state, action.payload.omit),
        ...createOrUpdate(action.payload.fieldsAndValues),
      };
    case SUBMIT_TRANSFER:
      return {
        ...state,
        showReviewAndConfirmModal: false,
        showTransferInitiatedModal: true,
      };
    case SELECT_TRANSFER_TYPE:
      return {
        ...state,
        transferType: action.payload.transferType,
        recurring: action.payload.transferType === "withdrawal" ? "false" : state.recurring,
        showRestOfTheTransferForm: action.payload.transferType === "withdrawal" ? state.availableFunds > 0 : true,
      };
    case ADD_FUNDS:
      return {
        ...state,
        showTransferType: false,
        showRestOfTheTransferForm: true,
        transferType: "deposit",
        submitText: "Review transfer details",
      };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        lastFourBankAccountNumber: action.payload.last_four_bank_account_number,
        hasBankAccount: action.payload.has_bank_account,
        bankName: action.payload.bank_name,
        showRestOfTheTransferForm: true,
        showTransferType: state.view === RECURRING_TRANSFERS,
        transferType: state.view === ADD_FUNDS ? "deposit" : state.transferType,
        submitText: state.view === ADD_FUNDS ? "Review transfer details" : state.submitText,
      };
    case CLOSE_MODAL:
      return {
        ..._.omit(state, ["transferType", "transferStartDate"]),
        showRestOfTheTransferForm: false,
      };
    case ADD_BANK_ACCOUNT_FAILURE:
    case TRANSFER_SUCCESS:
    case TRANSFER_FAILURE:
    default:
      return state;
  }
};
