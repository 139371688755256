import React from "react";
import PrincipalOutstanding from "./PrincipalOutstanding";
import PrincipalOutstandingMobile from "./PrincipalOutstandingMobile";
import Filters from "./Filters";
import Holdings from "./Holdings";
import Pagination from "./Pagination";
import EmptyState from "./EmptyState";
import PageRange from "./PageRange";
import { renderIf } from "../../common/utils/matchers";
import ErrorMessage from "../../common/components/ErrorMessage";

const Layout = (props) => {
  const ifMobile = props.mediaEq("mobile");
  const ifTabletDesktop = props.mediaGte("tablet");
  const holdingsSummaryEnabled = renderIf(props.config.holdings_summary_enabled)
  if (props.emptyState.isEmpty) {
    return (
      <div>
        <div className="paragraph-heading paragraph-heading--2x-large u-ml-small">My Portfolio</div>
        <Filters {...props} />
        <EmptyState {...props} />
        <ErrorMessage {...props} />
      </div>
    );
  }
  return (
    <div>
      {
        ifMobile (
          <div className="mobile">
            <div className="paragraph-heading paragraph-heading--large u-ml-small">My Portfolio</div>
            {holdingsSummaryEnabled(
              <PrincipalOutstandingMobile {...props} />
            )}
            <Filters {...props} />
            <PageRange {...props.pagination} />
            <Holdings {...props} />
            <Pagination {...props} />
            <ErrorMessage {...props} />
          </div>
        )
      }
      {
        ifTabletDesktop(
          <div>
            <div className="paragraph-heading paragraph-heading--2x-large u-ml-small">My Portfolio</div>
            {holdingsSummaryEnabled(
              <PrincipalOutstanding {...props} />
            )}
            <Filters {...props} />
            <Pagination {...props} />
            <Holdings {...props} />
            <ErrorMessage {...props} />
          </div>
        )
      }
    </div>
  );

}

export default Layout;
