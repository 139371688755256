import React from "react";

const LineItem = ({
  className,
  credit,
  currency,
  debit,
  label,
  value,
}) => {
  let type;
  let figure = `$${value.dollars}.${value.cents}`;

  if (debit) { figure = `(${figure})`; }

  if (credit) {
    type = "credit";
  } else if (debit) {
    type = "debit";
  } else {
    type = "";
  }

  return (
    <div className={`line-item ${className} ${type}`}>
      <span className="label">{label}</span>
      <span className="figure">{figure}</span>
    </div>
  );
};

const TotalEarnedDetails = ({ filterData }) => (
  <div className="total-earned-details">
    <section className="top">
      <LineItem
        className="header mobile-none"
        label="Total Earned"
        value={filterData.total_earned} />

      <LineItem
        className="sub-header"
        credit={true}
        label="Interest"
        value={filterData.interest} />

      <LineItem
        className="sub-header"
        credit={true}
        label="Late Fees"
        value={filterData.late_fees} />

      <LineItem
        className="sub-header"
        debit={true}
        label="Servicing Fees"
        value={filterData.servicing_fees} />
    </section>

    <LineItem
      className="header"
      label="Net Recoveries"
      value={filterData.recoveries} />

    <section className="bottom">
      <LineItem
        className="header"
        label="Charge-Offs"
        value={filterData.charge_offs} />
    </section>
  </div>
);

export default TotalEarnedDetails;
