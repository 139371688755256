export default function profileReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return action.profile;
    case "MOUNT_FIELD":
      return { ...state, [action.field]: action.value };
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    case "UPDATE_SUCCESS":
      return { ...state, ...action.fields };
    case "SUBMIT_SUCCESS":
      return action.nextProfile;
    default:
      return state;
  }
}
