//
// Conditionally require this so that tests can run.
//
let Honeybadger;

if (global.document) {
  Honeybadger = require("honeybadger-js"); // eslint-disable-line global-require
}

export const errorNotifier = (apiKey, environment) => {
  Honeybadger.configure({
    apiKey,
    environment,
    onerror: false, // handle onerror ourselves
    maxErrors: 5, // limits the number of errors sent per pageload
  });

  // Ignore errors that match stack of react-onclickoutside and message of
  // Unable to get property 'contains' of undefined or null reference
  return Honeybadger.beforeNotify((notice) => {
    if (/react-onclickoutside/.test(notice.stack) && /Unable to get property 'contains' of undefined or null reference/.test(notice.message)) {
      return false;
    }
    return notice;
  });
};

export function contextFromState({ config }) {
  return {
    investor_id: config.investor_id,
    tags: "react, frontend",
  };
}
