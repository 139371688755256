import { flipBool } from "../../common/utils/common";

export function direction(state, action) {
  if (state.activeColumn !== action.columnName) {
    return "descending";
  }
  return state.direction === "descending"
    ? "ascending" : "descending";
}

export function toggleSortColumn(state, action) {
  return Object.assign({}, state, {
    activeColumn: action.columnName,
    direction: direction(state, action),
    sortOptionsVisible: false,
  });
}

export function toggleSortOptions(state) {
  return Object.assign({}, state, {
    sortOptionsVisible: flipBool(state.sortOptionsVisible),
  });
}

export default function sortingReducer(state = {
  activeColumn: "purchased_at",
  direction: "descending",
  sortOptionsVisible: false,
}, action) {
  switch (action.type) {
    case "TOGGLE_SORT_COLUMN":
      return toggleSortColumn(state, action);
    case "TOGGLE_SORT_OPTIONS":
      return toggleSortOptions(state, action);
    case "FETCH_HOLDINGS_FAILURE":
      return action.prevState.sorting;
    default:
      return state;
  }
}
