import React from "react";
import ConcealedTextField from "../InvestorOnboarding/components/ConcealedTextField";
import { renderIf } from "../common/utils/matchers";
import { validations } from "../InvestorOnboarding/validations";
import { RADIUS_ASSET_PATH } from '../InvestorOnboarding/constants'

const formValidations = {
  password: [
    ["passwordFormat", "Your password must be between 8 and 128 characters and include one uppercase letter, one lowercase letter, and a number"],
  ],
  password_confirmation: [
    ["matchesPassword", "The password and confirmation password must match"],
    ["passwordFormat", "Your password must be between 8 and 128 characters and include one uppercase letter, one lowercase letter, and a number"],
  ],
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    let { errors } = props;
    errors = _.defaults(errors, { password: [], password_confirmation: [] });
    const passwords = { password: "", password_confirmation: "" };

    const fields = ["password", "password_confirmation"];

    const initialFlags = (obj, fields, fn) => {
      const flagObj = {};
      _.forEach(fields, (f) => {
        flagObj[f] = fn(obj[f]);
      });
      return flagObj;
    };

    const entity = this.props.admin ? "admin" : "user";

    this.state = {
      entity,
      passwords,
      errors,
      cache:          { ...passwords },
      isTouched:      initialFlags(errors, fields, _.some),
      isInvalid:      initialFlags(errors, fields, _.some),
      isValid:        initialFlags(errors, fields, _.isEmpty),
    };
  }

  handleValidations(evt) {
    const newVal = evt.target.value;
    const fieldName = evt.target.id;

    if (newVal == this.state.cache[fieldName]) return;

    this.handleValidateField(fieldName, newVal);
  }

  handleBlur(evt) {
    this.handleValidations(evt);
  }

  handleFocus(evt) {
    this.handleValidations(evt);
  }

  handleValidateField(fieldName, newVal) {
    const validationResults = _.map(formValidations[fieldName], (validator) => {
      const fn = validator[0];
      const msg = () => validator[1];
      return validations[fn](newVal, msg, fieldName, this.state.passwords);
    });

    const errors = _.compact(_.map(validationResults, 'message'));
    const isValid = _.every(validationResults, 'isValid');
    this.setState({
      errors:     { ...this.state.errors,     [fieldName]: errors },
      isTouched:  { ...this.state.isTouched,  [fieldName]: true },
      isInvalid:  { ...this.state.isInvalid,  [fieldName]: !isValid},
      isValid:    { ...this.state.isValid,    [fieldName]: isValid}
    })
  }

  ifStatus(fieldName) {
    return (status) => {
      return renderIf(this.state[status] && this.state[status][fieldName]);
    };
  }

  handleUpdate(evt) {
    this.setState({ passwords: { ...this.state.passwords, [evt.target.id]: evt.target.value } });
  }

  handleSubmit() {
    this.setState({ isProcessing: true })
  }

  showProcessing() {
    if (this.state.isProcessing) {
     return(
       <svg className="svg-icon cta-primary__icon">
         <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#processing`}></use>
       </svg>
     )
   }
  }

  render() {
    return (
      <div className="wrapper--margin-top">
        <section className="step-card u-mb-medium">
          <div className="step-card__content">
            <h2 className="section-heading section-heading--small">
              Reset password
            </h2>

            <form action={this.props.action} method="post" onSubmit={this.handleSubmit.bind(this)}>
              <input type="hidden" name="authenticity_token" value={this.props.authToken} />
              <input type="hidden" value={this.props.resetPasswordToken} name={`${this.state.entity}[reset_password_token]`} id="user_reset_password_token" />
              <input type="hidden" name="_method" value="put" />
              <ConcealedTextField
                value={this.state.passwords.password}
                handleUpdate={this.handleUpdate.bind(this)}
                handleBlur={this.handleBlur.bind(this)}
                handleFocus={this.handleFocus.bind(this)}
                errors={this.state.errors.password}
                label="New password"
                ifStatus={this.ifStatus("password").bind(this)}
                name={`${this.state.entity}[password]`}
                id="password"
              />
              <ConcealedTextField
                value={this.state.passwords.password_confirmation}
                handleUpdate={this.handleUpdate.bind(this)}
                handleBlur={this.handleBlur.bind(this)}
                handleFocus={this.handleFocus.bind(this)}
                errors={this.state.errors.password_confirmation}
                label="Confirm new password"
                ifStatus={this.ifStatus("password_confirmation").bind(this)}
                name={`${this.state.entity}[password_confirmation]`}
                id="password_confirmation"
              />

              <div className="u-text-center">
                <div className="u-mb-medium">
                  <button className={`cta-primary cta--block ${this.state.isProcessing ? "is-processing" : ""}`} data-process-text="Processing" type="submit">
                    {this.showProcessing()}
                    Reset my password
                  </button>
                </div>
                <div>
                  <a href={this.props.signInPath} className="cta-link">Sign in</a>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default ChangePassword;
