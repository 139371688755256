import axios from "axios";
import {
  CANCEL_TRANSFER, CANCEL_TRANSFER_SCHEDULE,
  CANCEL_TRANSFER_SUCCESS, CANCEL_TRANSFER_FAILURE,
  CANCEL_TRANSFER_SCHEDULE_SUCCESS, CANCEL_TRANSFER_SCHEDULE_FAILURE,
  CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL,
  MODAL_TIME_OUT,
} from "../constants";

export const cancelTransfer = payload => ({
  type: CANCEL_TRANSFER,
  payload,
});

export const cancelTransferSchedule = payload => ({
  type: CANCEL_TRANSFER_SCHEDULE,
  payload,
});


const closeCancelTransferConfirmModal = () => ({
  type: CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL,
});

const cancelTransferSuccess = () => ({
  type: CANCEL_TRANSFER_SUCCESS,
});

const cancelTransferFailure = () => ({
  type: CANCEL_TRANSFER_FAILURE,
});

export const submitCancelTransfer = () => (dispatch, getState) => {
  const { authToken } = getState().transferValues;
  const { id } = getState().selectedTransfer;
  const url = getState().transfersPath;
  const data = { id, cancel_transfer: "true" };

  axios({
    data,
    headers: { "X-CSRF-Token": authToken },
    method: "PATCH",
    url,
  }).then(() => {
    dispatch(cancelTransferSuccess());
    setTimeout(() => dispatch(closeCancelTransferConfirmModal()), MODAL_TIME_OUT);
  }, dispatch(cancelTransferFailure()));
};

const cancelRecurringTransferSuccess = () => ({
  type: CANCEL_TRANSFER_SCHEDULE_SUCCESS,
});

const cancelRecurringTransferFailure = () => ({
  type: CANCEL_TRANSFER_SCHEDULE_FAILURE,
});

export const submitCancelRecurringTransfer = () => (dispatch, getState) => {
  const { authToken } = getState().transferValues;
  const { id } = getState().selectedTransfer;
  const url = getState().transfersPath;
  const data = { id, cancel_schedule: "true" };

  axios({
    data,
    headers: { "X-CSRF-Token": authToken },
    method: "PATCH",
    url,
  }).then(() => {
    dispatch(cancelRecurringTransferSuccess());
    setTimeout(() => dispatch(closeCancelTransferConfirmModal()), MODAL_TIME_OUT);
  }, dispatch(cancelRecurringTransferFailure()));
};
