import React from "react";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  handleToggleConcealed() {
    this.setState({ display: !this.state.display });
  }

  render() {
    return (
      <div className="wrapper--margin-top">
        <section className="step-card u-mb-medium">
          <div className="step-card__content">
            <h1 className="section-heading section-heading--small u-mb-none">
              Sign in to your investor account
            </h1>
            <p>
              US investors only. UK investors please visit
              {" "}
              <a href="https://www.fundingcircle.com/uk/investors" className="cta-link">Funding Circle UK</a>
              .
            </p>

            <form action={this.props.signInUrl} method="post">
              <input type="hidden" name="authenticity_token" value={this.props.authToken} />
              <div className="form-element u-mb-large">
                <label className="form-label form-element__label">
                  <span>Email</span>
                </label>
                <div className="form-element__elements">
                  <div className="form-input form-input--icon">
                    <input
                      name="user[email]"
                      id="user_email"
                      type="text"
                      className="form-input__element"
                    />
                  </div>
                </div>
              </div>
              <div className="form-element u-mb-large">
                <label className="form-label form-element__label">
                  <span>Password</span>
                </label>
                <div className="form-element__elements">
                  <div className="form-input form-input--icon">
                    <input
                      name="user[password]"
                      id="user_password"
                      type={this.state.display ? "input" : "password"}
                      className="form-input__element"
                    />
                    <button tabIndex="-1" className="input-item__toggle-password" type="button" onClick={() => this.handleToggleConcealed()}>{this.state.display ? "HIDE" : "SHOW"}</button>
                  </div>
                </div>
              </div>
              <div className="u-text-center">
                <button className="cta-primary cta--block u-mb-medium" type="submit">
                  Sign in
                </button>
                <div>
                  <a href={this.props.resetPasswordPath} className="cta-link">Reset password</a>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default SignIn;
