import React from "react";
import { connect } from "react-redux";
import PerformanceMetric from "./PerformanceMetric";

const mapStateToProps = ({ performanceFigures }) => (
  {
    totalInterestEarned: performanceFigures.totalInterestEarned,
    weightedAverageCoupon: performanceFigures.weightedAverageCoupon,
    totalAmountInvested: performanceFigures.totalAmountInvested,
  }
);

const View = ({ totalInterestEarned, weightedAverageCoupon, totalAmountInvested }) => (
  <div className="performance">
    <PerformanceMetric {...totalInterestEarned} />
    <PerformanceMetric {...weightedAverageCoupon} />
    <PerformanceMetric {...totalAmountInvested} />
  </div>
);

const Performance = connect(mapStateToProps)(View);

export default Performance;
