import React from "react";
import { renderIf } from "../../common/utils/matchers";

const PortfolioBalanceLegend = ({
  config,
  portfolioBalance,
  availableFunds,
  totalPending,
  pendingRepayments,
  outstandingPrincipal,
  pendingRepaymentsVisible,
  redactedViewEnabled,
}) => {
  let portfolioBalanceView = (<span className="portfolioBalance"></span>)

  const ifPendingRepayments = renderIf(pendingRepaymentsVisible);

  if (!redactedViewEnabled) {
    portfolioBalanceView = (
      <span>
        <dt className="bold mobile-none">PORTFOLIO VALUE</dt>
        <dd className="bold mobile-none">${portfolioBalance.dollars}.{portfolioBalance.cents}</dd>
        <hr className="mobile-none" />
      </span>
    )
  }

  return (
    <div className="portfolio-balance">
      <dl className="investor-funds-summary--legend">
        {portfolioBalanceView}
        <dt className="investor-funds-summary--available">
          <i className="swatch"></i>
          AVAILABLE FUNDS
        </dt>
        <dd id="available-funds">{availableFunds}</dd>
        {ifPendingRepayments(
          <dt className="investor-funds-summary--note">
            <span>Pending repayments of {pendingRepayments} will be added to your Available Funds, typically within 3 hours.</span>
          </dt>
        )}
        <hr />
        <dt onClick={(e) => e.stopPropagation()} className="investor-funds-summary--lender-holding-account-balance">
          <a href={config.marketplace_url}>
            <i className="swatch"></i>
            PENDING ORDERS
            <div className="arrow-right"></div>
          </a>
        </dt>

        <dd>
          <a href={config.marketplace_url}>
            {totalPending}
          </a>
        </dd>
        <hr />

        <dt onClick={(e) => e.stopPropagation()} className="investor-funds-summary--principal-outstanding">
          <a href={config.holdings_url}>
            <i className="swatch"></i>
            <span>PERFORMING<br />OUTSTANDING PRINCIPAL</span>
            <div className="arrow-right"></div>
          </a>
        </dt>
        <dd>
            <a href={config.holdings_url}>
              {outstandingPrincipal}
            </a>
        </dd>
      </dl>
    </div>
  );
};

export default PortfolioBalanceLegend;
