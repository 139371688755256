/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { recurringOptions, transferDisclaimers } from "../constants";
import { Error, Message } from "./Status";
import { required } from "../validations";
import { editRecurrence } from "../actions";
import { renderIf } from "../../common/utils/matchers";

const Radio = ({
  input,
  recurringOption,
  formProps,
}) => {
  const {
    id, name, value, displayText, isDisabled, dataTestAttribute,
  } = recurringOption;

  const { recurring, editRecurrence } = formProps;
  return (
    <div data-test={dataTestAttribute} className="form-stylised-radio radio-medium">
      <input
        {...input}
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={value === recurring}
        disabled={isDisabled}
        onChange={() => editRecurrence("recurring", value)}
      />
      <label id={id} className="form-stylised-radio__label" htmlFor={id}>
        <span>{displayText}</span>
      </label>
    </div>
  );
};

const RadioList = ({
  label, input, formProps, meta: { error },
}) => {
  const { transferType } = formProps;
  const list = recurringOptions(transferType === "withdrawal");
  return (
    <div>
      <label className="form-label form-element__label">
        <span>{label}</span>
        {error && <Error />}
      </label>
      <div className="form-element__elements u-mb-x-small">
        <div>
          {
            list.map(option => (
              <Radio
                key={option.id}
                recurringOption={option}
                formProps={formProps}
                input={input}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

const Recurrence = (props) => {
  const { transferType } = props;
  const presence = required("");
  const isWithdrawal = transferType === "withdrawal";

  return (
    <div className="form-element">
      <Field
        name="recurring"
        label="Frequency"
        formProps={props}
        component={RadioList}
        validate={[presence]}
      />
      {renderIf(isWithdrawal)(<Message message={transferDisclaimers.recurringWithdrawal} />)}
    </div>
  );
};

const mapStateToProps = state => ({
  transferType: state.transferValues.transferType,
  recurring: state.transferValues.recurring,
});

export default connect(mapStateToProps, { editRecurrence })(Recurrence);
