import { reducer as formReducer } from "redux-form";
import { combineReducers } from "redux";
import transfersPathReducer from "./transfersPathReducer";
import transferReducer from "./transferReducer";
import {
  selectTransferReducer,
  isCancelTransferScheduleReducer,
} from "./cancelTransferReducer";
import activeModalReducer from "./activeModalReducer";

export default combineReducers({
  form: formReducer,
  transferValues: transferReducer,
  transfersPath: transfersPathReducer,
  activeModal: activeModalReducer,
  selectedTransfer: selectTransferReducer,
  isCancelTransferSchedule: isCancelTransferScheduleReducer,
});
