import {
  ADD_FUNDS,
  CANCEL_TRANSFER, CANCEL_TRANSFER_SCHEDULE, CLOSE_MODAL,
  CANCEL_TRANSFER_SUCCESS, CANCEL_TRANSFER_SCHEDULE_SUCCESS,
  CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL, ADD_BANK_ACCOUNT_SUCCESS,
  UPDATE_FIELDS_AND_VALUES, SELECT_TRANSFER_TYPE, MODAL_NAME, BANK_FORM,
} from "../constants";

const getModal = ({ hasBankAccount, allowReEnterBankAccount, view }) => {
  let modal = null;
  if (view === ADD_FUNDS && hasBankAccount) {
    modal = MODAL_NAME.ADD_FUNDS;
  }
  if (!hasBankAccount && allowReEnterBankAccount) {
    modal = MODAL_NAME.ADD_BANK_ACCOUNT;
  }
  if (!hasBankAccount && !allowReEnterBankAccount) {
    modal = MODAL_NAME.CONTACT_SUPPORT;
  }
  return modal;
};

export default (activeModalName = null, action) => {
  switch (action.type) {
    case ADD_FUNDS:
    case SELECT_TRANSFER_TYPE:
      return getModal(action.payload);
    case CANCEL_TRANSFER:
      return MODAL_NAME.CANCEL_TRANSFER;
    case CANCEL_TRANSFER_SCHEDULE:
      return MODAL_NAME.CANCEL_TRANSFER_SCHEDULE;
    case CANCEL_TRANSFER_SUCCESS:
    case CANCEL_TRANSFER_SCHEDULE_SUCCESS:
      return MODAL_NAME.CANCEL_TRANSFER_CONFIRM;
    case CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL:
      window.location.reload();
      return null;
    case ADD_BANK_ACCOUNT_SUCCESS: {
      if (action.payload.view === BANK_FORM) {
        window.location.reload();
        return null;
      }
      return action.payload.view === ADD_FUNDS ? MODAL_NAME.ADD_FUNDS : null;
    }
    case UPDATE_FIELDS_AND_VALUES:
    case CLOSE_MODAL:
      return null;
    default:
      return activeModalName;
  }
};
