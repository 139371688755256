import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import { RADIUS_ASSET_PATH } from '../constants'

const Container = ({
  ifSubmitting,
  isSubmitting,
  value,
  children,
}) => {
  return (
    <div className="u-text-center submit-button">
      <button className={`cta-primary cta--block cta-primary--process ${ifSubmitting("is-processing")}`} data-process-text="Processing" disabled={isSubmitting} type="submit">
        {ifSubmitting(
          <svg className="svg-icon cta-primary__icon">
            <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#processing`}></use>
          </svg>
        )}
        <span>{value || "Next"}</span>
        <span>{children}</span>
      </button>
    </div>
  );
};

export function isSubmitting(steps, step) {
  return steps[step] && steps[step].isSubmitting;
}

export function ifSubmitting(steps, step) {
  return renderIf(isSubmitting(steps, step));
}

export function mapStateToProps(state, ownProps) {
  const { step, value } = ownProps;
  const { steps } = state;

  return {
    value: value,
    ifSubmitting: ifSubmitting(steps, step),
    isSubmitting: isSubmitting(steps, step)
  };
}

const Submit = connect(mapStateToProps)(Container);

export default Submit;
