import React from "react";
import ValidatedField from "./ValidatedField";
import CurrencyInput from "react-money-input";


class CurrencyField extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleUpdate(props.name, props.value)
  }

  handleChange = f => (event, value, maskedValue, fn) => {
    f(this.props.name, value);
  }

  handleBlur = f => (event, value) => {
    f(this.props.name, value);
  }

  handleFocus = f => (event, value) => {
    f(this.props.name, value);
  }


  render() {
    return(
      <ValidatedField
        label={this.props.label}
        errors={this.props.errors}
        ifStatus={this.props.ifStatus}>
        <div className="form-input form-input--icon">
          <CurrencyInput
            name={this.props.name}
            className="form-input__element"
            initialValue={this.props.value}
            value={this.props.value}
            locale="en-US"
            onChange={this.handleChange(this.props.handleUpdate)}
            onBlur={this.handleBlur(this.props.handleBlur)}
            onFocus={this.handleFocus(this.props.handleFocus)}
            currency="USD"
            currencyDisplay="symbol"
          />

        </div>
      </ValidatedField>
    );
  }
};

export default CurrencyField;
