import React from "react";

const ResetPassword = ({ action, authToken, onboardingsPath, signInPath, admin }) => {
  return (
    <div className="wrapper--margin-top">
      <section className="step-card u-mb-medium">
        <div className="step-card__content">
          <h2 className="section-heading section-heading--small u-mb-none">
            Reset your password
          </h2>
          <p>Please enter the email address that is registered to your account.</p>

          <form action={action} method="post">
            <input type="hidden" name="authenticity_token" value={authToken} />
            <div className="form-element u-mb-large">
              <label className="form-label form-element__label">
                <span>Email</span>
              </label>
              <div className="form-element__elements">
                <div className="form-input form-input--icon">
                  <input
                    type="text"
                    className="form-input__element"
                    id="user_email"
                    name={admin ? "admin[email]" : "user[email]"}
                  />
                </div>
              </div>
            </div>
            <div className="u-text-center">
              <button className="cta-primary cta--block u-mb-medium" type="submit">
                Send reset instructions
              </button>
              <div>
                <a href={signInPath} className="cta-link">Sign in</a>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;
