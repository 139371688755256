import React from "react";
import { RADIUS_ASSET_PATH } from "../constants";

const Notification = ({
  phase,
}) => {
  return(
    <div className={`step-card--max-width u-mb-large ${phase =="documentUploadComplete" ? "" : "progress-bar--hidden"}`}>
      <div className="notice notice--attention step-card--max-width step-card--max-width-mobile-margin u-mb-large">
        <div className="notice__content">
          <div className="notice__copy notice__copy--flex">
            <svg className="svg-icon svg-icon--notice-info" aria-hidden="true">
              <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`}></use>
            </svg>
            <div>
              <div><strong>Your documents are being reviewed</strong></div>
              <div>
                You can continue to your dashboard or <a href="https://cloud.sookasa.com/upload_page/23e9ae68-6ec6-4cb5-94dd-2ef8d8d9fe3f" target="_blank" className="show-alert-modal">upload additional documents</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Notification.defaultProps = { phase: "" };

export default Notification;
