import _ from "lodash";

export function itemRanges(totalPages, totalItems) {
  const ranges = [];

  _.range(1, totalPages + 1).forEach((page) => {
    let max = page * 10;
    const min = max - 9;
    if (max > totalItems) {
      max = totalItems;
    }
    ranges.push(`${min} - ${max}`);
  });
  return ranges;
}

export function pageCount(state, action) {
  const totalCount = action.data.total_count;
  const itemsPerPage = action.data.size;
  const totalPages = _.ceil(totalCount / itemsPerPage);

  return Object.assign({}, state, {
    totalPages,
    totalItems: totalCount,
    itemRanges: itemRanges(totalPages, totalCount),
  });
}

export default function paginationReducer(state = {
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  itemsPerPage: 10,
  itemRanges: [],
  dropdownOpen: false,
}, action) {
  switch (action.type) {
    case "APPLY_FILTERS":
      return Object.assign({ ...state }, { currentPage: 1 });
    case "CLEAR_FILTERS":
      return Object.assign({ ...state }, { currentPage: 1 });
    case "INIT":
      return pageCount(state, action);
    case "FETCH_HOLDINGS_SUCCESS":
      return pageCount(state, action);
    case "NEXT_PAGE":
      return Object.assign({ ...state }, { currentPage: state.currentPage + 1 });
    case "PREVIOUS_PAGE":
      return Object.assign({ ...state }, { currentPage: state.currentPage - 1 });
    case "SELECT_PAGE":
      return Object.assign({ ...state }, { currentPage: action.pageNumber });
    case "TOGGLE_SORT_COLUMN":
      return Object.assign({ ...state }, { currentPage: 1 });
    case "TOGGLE_PAGINATION_DROPDOWN":
      return Object.assign({ ...state }, { dropdownOpen: !state.dropdownOpen });
    case "CLOSE_PAGINATION_DROPDOWN":
      return Object.assign({ ...state }, { dropdownOpen: false });
    case "FETCH_HOLDINGS_FAILURE":
      return action.prevState.pagination;
    default:
      return state;
  }
}
