import React from "react";
import TextField from "./InvestorOnboarding/components/TextField";
import { renderIf } from "./common/utils/matchers"
import { validations } from "./InvestorOnboarding/validations";
import { RADIUS_ASSET_PATH } from './InvestorOnboarding/constants'

const formValidations = {
  firstName: [
    ["nameFormat", "Please enter your last name without numbers or special characters"],
    ["maxLength", "Must not exceed 80 characters", 80]
  ],
  lastName: [
    ["nameFormat", "Please enter your last name without numbers or special characters"],
    ["maxLength", "Must not exceed 80 characters", 80]
  ],
  email: [
    ["emailFormat", "Please enter a valid email address, containing '@' and '.'"]
  ]
}

class InvestorOnboardingWelcome extends React.Component {
  constructor(props) {
    let { profile, errors } = props;
    super(props);

    errors  = _.defaults(errors,  { firstName: [], lastName: [], email: [] });
    profile = _.defaults(profile, { firstName: '', lastName: '', email: '' });

    const fields = ['firstName', 'lastName', 'email'];

    const initialFlags = (obj, fields, fn) => {
      let flagObj = {};
      _.forEach(fields, (f) => {
        flagObj[f] = fn(obj[f]);
      });
      return flagObj;
    }

    this.state = {
      profile:        profile,
      cache:          {...profile },
      errors:         errors,
      isTouched:      initialFlags(profile, fields, _.some),
      isInvalid:      initialFlags(errors, fields, _.some),
      isValid:        initialFlags(errors, fields, _.isEmpty),
      isOptional:     {},
      disableSubmit:  !_.every(initialFlags(errors, fields, _.some)),
    }
  }

  handleValidations(evt) {
    const newVal = evt.target.value;
    const fieldName = evt.target.name;

    if (newVal === this.state.cache[fieldName] && !_.isBlank(newVal)) return;

    this.handleValidateField(fieldName, newVal);
  }

  handleBlur(evt) {
    this.handleValidations(evt);
  }

  handleUpdate(evt) {
    this.setState({
      profile: { ...this.state.profile, [evt.target.name]: evt.target.value },
    });
  }

  handleValidateField(fieldName, newVal) {
    const validationResults = _.map(formValidations[fieldName], (validator) => {
      let fn = validator[0];
      let msg = () => validator[1];
      let size = validator[2];
      return validations[fn](newVal, msg, null, null, size);
    });

    const errors = _.compact(_.map(validationResults, 'message'));
    const isValid = _.every(validationResults, 'isValid');
    this.setState({
      disableSubmit: errors.length,
      errors:     { ...this.state.errors,     [fieldName]: errors },
      isTouched:  { ...this.state.isTouched,  [fieldName]: true },
      isInvalid:  { ...this.state.isInvalid,  [fieldName]: !isValid },
      isValid:    { ...this.state.isValid,    [fieldName]: isValid },
    })
  }

  ifStatus(fieldName) {
    return (status) => {
      return renderIf(this.state[status][fieldName]);
    }
  }

  handleSubmit() {
    this.setState({ isProcessing: true });
  }

  showProcessing() {
    if (this.state.isProcessing) {
      return (
        <svg className="svg-icon cta-primary__icon">
          <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#processing`}></use>
        </svg>
      );
    }
  }

  render() {
    return (
      <div>
        <section className="step-card u-mb-x-large">
          <div className="step-card__content">
            <h2 className="section-heading section-heading--small">
              Open an investor account
            </h2>

            <form action="/us/investors/onboardings" method="post" onSubmit={this.handleSubmit.bind(this)}>
              <div className="u-mb-large">
                <TextField
                  name="firstName"
                  value={this.state.profile.firstName}
                  label="First Name"
                  ifStatus={this.ifStatus('firstName').bind(this)}
                  errors={this.state.errors.firstName}
                  handleUpdate={this.handleUpdate.bind(this)}
                  handleBlur={this.handleBlur.bind(this)}
                />
                <TextField
                  name="lastName"
                  value={this.state.profile.lastName}
                  label="Last Name"
                  ifStatus={this.ifStatus('lastName').bind(this)}
                  errors={this.state.errors.lastName}
                  handleUpdate={this.handleUpdate.bind(this)}
                  handleBlur={this.handleBlur.bind(this)}
                />
                <TextField
                  name="email"
                  value={this.state.profile.email}
                  label="Email"
                  ifStatus={this.ifStatus('email').bind(this)}
                  errors={this.state.errors.email}
                  handleUpdate={this.handleUpdate.bind(this)}
                  handleBlur={this.handleBlur.bind(this)}
                />
                <p className="text_block--small text-block--negative-margin-top">Your email address will be your username</p>
              </div>

              <div className="u-text-center submit-button">
                <button className={`cta-primary cta--block cta-primary--process ${this.state.isProcessing ? "is-processing" : ""}`} data-process-text="Processing" disabled={this.state.disableSubmit} type="submit">
                  {this.showProcessing()}
                  <span>Next</span>
                </button>
              </div>
            </form>
          </div>
        </section>

        <p className="u-mb-none u-text-center">
          Already have an account? Click here to <a href="/users/sign_in" className="cta-link">sign in</a>.
        </p>
      </div>
    );
  }
}

export default InvestorOnboardingWelcome;
