import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { createStep } from "../containers/Step";

const STEP_NAME = "submitProfile";

const View = ({ handleSubmit }) => {
  return (
    <div className="u-pv-x-small u-text-center">
      <p className="u-pv-medium">Once your profile has been submitted it cannot be edited.</p>
      <button className="cta-primary" onClick={handleSubmit()}>
        Submit Profile
      </button>
    </div>
  );
}

const mapStateToProps = () => {
  return {};
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
