import React from "react";
import { connect } from "react-redux";
import DonutChart from "./DonutChart";
import PortfolioBalanceLegend from "./PortfolioBalanceLegend";
import Performance from "./Performance";

const mapStateToProps = ({ config, portfolioBalance }) => {
  const {
    portfolio_balance,
    outstanding_principal,
    available_funds,
    total_pending,
    pending_repayments,
  } = portfolioBalance.generalAttributes

  return {
    chartAttributes: portfolioBalance.chartAttributes,
    config,
    portfolioBalance: portfolio_balance,
    outstandingPrincipal: outstanding_principal,
    availableFunds: available_funds,
    totalPending: total_pending,
    pendingRepayments: pending_repayments,
    pendingRepaymentsVisible: portfolioBalance.pendingRepaymentsVisible,
    flipped: portfolioBalance.flipped,
    expanded: portfolioBalance.expanded,
  };
};

const LimitedPortfolioCard = ({
  availableFunds,
  chartAttributes,
  config,
  outstandingPrincipal,
  portfolioBalance,
  redactedViewEnabled,
  pendingRepaymentsVisible,
  totalPending,
  pendingRepayments,
}) => (
  <div className="limited-view-card-container">
    <div className="donut-legend">
      <DonutChart
        chartAttributes={chartAttributes}
        portfolioBalance={portfolioBalance} />
      <div className="legend">
        <PortfolioBalanceLegend
          availableFunds={availableFunds}
          config={config}
          outstandingPrincipal={outstandingPrincipal}
          portfolioBalance={portfolioBalance}
          redactedViewEnabled={redactedViewEnabled}
          totalPending={totalPending}
          pendingRepayments={pendingRepayments}
          pendingRepaymentsVisible={pendingRepaymentsVisible}
        />
      </div>
    </div>
    <Performance />
  </div>
);

export default connect(mapStateToProps)(LimitedPortfolioCard);
