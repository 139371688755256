import React from "react";
import { ACCOUNT_TYPE } from "../constants";
import { Error, Message } from "./Status";

const Radio = ({ input, accountProps }) => {
  const {
    id, name, value, displayText, dataTestAttribute,
  } = accountProps;
  const uniqueId = _.uniqueId(id)

  return (
    <div data-test={dataTestAttribute} className="form-stylised-radio radio-medium">
      <input
        {...input}
        id={uniqueId}
        type="radio"
        value={value}
        name={name}
      />
      <label id={id} className="form-stylised-radio__label" htmlFor={uniqueId}>
        <span>{displayText}</span>
      </label>
    </div>
  );
};

const AccountType = ({ input, label, meta: { touched, error } }) => {
  const list = ACCOUNT_TYPE;
  return (
    <div className={`form-element ${touched && error ? "is-error" : ""}`}>
      <label className="form-label form-element__label">
        <span>{label}</span>
        {touched && error && <Error />}
      </label>
      <div className="form-element__elements u-mb-x-small">
        <div>
          {
            list.map(type => (
              <Radio
                key={type.id}
                name={type.name}
                input={input}
                accountProps={type}
              />
            ))}
        </div>
      </div>
      {touched && error && <Message message={error} />}
    </div>
  );
};

export default AccountType;
