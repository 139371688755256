import React from "react";
import { connect } from "react-redux";
import { submitCancelRecurringTransfer, submitCancelTransfer } from "../actions/cancelTransfer";
import { ordinalizeNumber } from "../helpers";
import { MONTHS, MODAL_NAME } from "../constants";
import Modal from "../modals/Modal";

const CancelTransferScheduleContainer = ({
  submitCancelTransfer,
  submitCancelRecurringTransfer,
  visible,
  month,
  date,
  year,
  ordinalizedDate,
}) => (
  <Modal visible={visible}>
    <div className="modal__content u-grid-141">
      <h2 className="section-heading section-heading--x-small">This is a recurring transfer.</h2>
      <p className="lead-paragraph u-mb-large">
        {`Do you want to cancel only the next upcoming transfer on ${month} ${date}, ${year}
        or all monthly transfers on the ${ordinalizedDate}?`}
      </p>
      <div>
        <button
          type="button"
          className="cta-primary cta--block u-grid-1 u-mb-small"
          onClick={() => submitCancelTransfer()}
        >
          Cancel next transfer only
        </button>
        <button
          type="button"
          className="cta-secondary cta--block u-grid-1 u-mb-large"
          onClick={() => submitCancelRecurringTransfer()}
        >
          Cancel recurring transfers
        </button>
      </div>
    </div>
  </Modal>
);

const mapStateToProps = (state) => {
  const { selectedTransfer, activeModal } = state;

  if (!selectedTransfer || !activeModal) {
    return {
      visible: false,
    };
  }

  const { transfer_at } = selectedTransfer;
  const transferAt = new Date(transfer_at);
  const date = String(transferAt.getDate());
  return {
    visible: activeModal === MODAL_NAME.CANCEL_TRANSFER_SCHEDULE,
    month: MONTHS[transferAt.getMonth()],
    date,
    year: transferAt.getFullYear(),
    ordinalizedDate: ordinalizeNumber(date),
  };
};

export default connect(mapStateToProps, {
  submitCancelTransfer, submitCancelRecurringTransfer,
})(CancelTransferScheduleContainer);
