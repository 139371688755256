import _ from "lodash";

export function setPhase(stepList, step) {
  return _.find(stepList, stepListItem => stepListItem.stepName === step).phase;
}

export default function phaseReducer(state = "", action) {
  switch (action.type) {
    case "INIT":
      return setPhase(action.stepList, action.currentStep);
    case "SUBMIT_SUCCESS":
      return setPhase(action.stepList, action.nextStep);
    default:
      return state;
  }
}
