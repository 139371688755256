import React from "react";

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: true });
  }

  handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e);
      e.dataTransfer.clearData();
    }
  }

  render() {
    return (
      <div
        className={`dotted-file-input-box u-text-center ${this.state.dragging ? "dragging" : ""}`}
        onDragEnter={e => this.handleDragIn(e)}
        onDragLeave={e => this.handleDragOut(e)}
        onDragOver={e => this.handleDrag(e)}
        onDrop={e => this.handleDrop(e)}
      >
        {this.props.children}
      </div>
    );
  }
}
export default DragAndDrop;
