export default function holdingsReducer(state = [], action) {
  switch (action.type) {
    case "INIT":
      return action.data.holdings;
    case "FETCH_HOLDINGS_SUCCESS":
      return action.data.holdings;
    default:
      return state;
  }
}
