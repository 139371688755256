/* eslint-disable no-shadow */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import Icon from "radius/dist/m2dm/react/icon";
import { TRANSFER_TYPES } from "../constants";
import { selectTransferType } from "../actions";
import { INVESTOR_RELATIONS_EMAIL } from "../constants";

const handleChange = (formProps, transferType) => {
  const {
    allowReEnterBankAccount, hasBankAccount, view, selectTransferType,
  } = formProps;

  selectTransferType({
    allowReEnterBankAccount,
    hasBankAccount,
    view,
    transferType,
  });
};

const Radio = ({ transferOption, formProps }) => {
  const {
    id, name, value, displayImage, boldText, displayText, dataTestAttribute,
  } = transferOption;

  const { transferType } = formProps;
  return (
    <div data-test={dataTestAttribute} className="form-stylised-radio form-stylised-radio--small-width">
      <input
        id={id}
        type="radio"
        value={value}
        name={name}
        checked={value === transferType}
        onChange={() => handleChange(formProps, value)}
      />
      <label className="form-stylised-radio__label form-stylised-radio__label--large u-mb-small" htmlFor={id}>
        <div className="image-wrapper">
          {displayImage ? <div className={displayImage} /> : (
            <Icon
              name="bank"
              extraClasses="svg-icon--bank"
            />
          )}

          <div className="image-text">
            <span>
              <b>{boldText}</b>
              {displayText}
            </span>
          </div>
        </div>
      </label>
    </div>
  );
};

const TransferType = (props) => {
  const {
    hasBankAccount,
    lastFourBankAccountNumber,
  } = props;

  const bankAccount = hasBankAccount ? `ending in ${lastFourBankAccountNumber}` : "";
  const list = TRANSFER_TYPES(bankAccount);
  return (
    <div className="form-element">
      <h2 className="section-heading section-heading--x-small">Withdrawal of Funds</h2>
      <div>
        <p className="paragraph-heading paragraph-heading--normal u-mb-medium">
          Please reach out to <a href={`mailto:${INVESTOR_RELATIONS_EMAIL}`}>{` ${INVESTOR_RELATIONS_EMAIL} `}</a> to have funds wired to your account.
        </p>
        <div className="cta-combo">
        <a
          className="cta-primary cta-combo__primary u-mb-medium"
          href={`mailto:${INVESTOR_RELATIONS_EMAIL}`}
        >
          Email Support team
        </a>
      </div>
      </div>
    </div>
  );
};


TransferType.defaultProps = {
  hasBankAccount: false,
  lastFourBankAccountNumber: "",
  transferType: "",
};

const mapStateToProps = state => ({
  hasBankAccount: state.transferValues.hasBankAccount,
  allowReEnterBankAccount: state.transferValues.allowReEnterBankAccount,
  lastFourBankAccountNumber: state.transferValues.lastFourBankAccountNumber,
  transferType: state.transferValues.transferType || "",
  availableFunds: state.transferValues.availableFunds,
  view: state.transferValues.view,
});

export default connect(mapStateToProps, {
  selectTransferType,
})(TransferType);
