import axios from "axios";
import {
  LOAD, EDIT_AMOUNT, EDIT_FREQUENCY, EDIT_RECURRENCE,
  EDIT_DAY_OF_WEEK, EDIT_DATE_OF_MONTH, EDIT_TRANSFER_START_DATE,
  TRANSFER_SUCCESS, TRANSFER_FAILURE, SHOW_REST_OF_THE_TRANSFER_FORM,
  UPDATE_FIELDS_AND_VALUES, SHOW_MODAL, ADD_BANK_ACCOUNT_FAILURE,
  SUBMIT_TRANSFER, CLOSE_MODAL, ADD_BANK_ACCOUNT_SUCCESS,
  ADD_FUNDS, SELECT_TRANSFER_TYPE,
} from "../constants";

export const loadInitialValues = data => ({
  type: LOAD,
  payload: data,
});

export const editAmount = (field, value) => ({
  type: EDIT_AMOUNT,
  payload: { field, value },
});

export const editFrequency = (field, value) => ({
  type: EDIT_FREQUENCY,
  payload: { field, value },
});

export const editRecurrence = (field, value) => ({
  type: EDIT_RECURRENCE,
  payload: { field, value },
});

export const editDayOfWeek = (field, value) => ({
  type: EDIT_DAY_OF_WEEK,
  payload: { field, value },
});

export const editDateOfMonth = (field, value) => ({
  type: EDIT_DATE_OF_MONTH,
  payload: { field, value },
});

export const editTransferStartDate = (field, value) => ({
  type: EDIT_TRANSFER_START_DATE,
  payload: { field, value },
});

export const showModal = (modalName, show) => ({
  type: SHOW_MODAL,
  payload: {
    field: modalName,
    value: show,
  },
});

export const showRestOfTheTransferForm = (field, value) => ({
  type: SHOW_REST_OF_THE_TRANSFER_FORM,
  payload: { field, value },
});

export const updateFieldsAndValues = payload => ({
  type: UPDATE_FIELDS_AND_VALUES,
  payload,
});

export const selectTransferType = payload => ({
  type: SELECT_TRANSFER_TYPE,
  payload,
});

export const addFunds = () => (dispatch, getState) => {
  const { hasBankAccount, allowReEnterBankAccount, view } = getState().transferValues;
  dispatch({ type: ADD_FUNDS, payload: { hasBankAccount, allowReEnterBankAccount, view } });
};

const submitSuccess = dispatch => () => dispatch({
  type: TRANSFER_SUCCESS,
});

const submitFailure = dispatch => () => dispatch({
  type: TRANSFER_FAILURE,
});

const addBankAccountSuccess = payload => ({
  type: ADD_BANK_ACCOUNT_SUCCESS,
  payload,
});

const addBankAccountFailure = dispatch => dispatch({
  type: ADD_BANK_ACCOUNT_FAILURE,
});

export function submitTransfer() {
  return (dispatch, getState) => {
    dispatch({ type: SUBMIT_TRANSFER });

    const { authToken } = getState().transferValues;
    const url = "/us/investors/transfers";

    return axios({
      data: getState().transferValues,
      headers: { "X-CSRF-Token": authToken },
      method: "POST",
      url,
    }).then(submitSuccess(dispatch), submitFailure(dispatch));
  };
}

export const createBankAccount = values => (dispatch, getState) => {
  const { authToken, view } = getState().transferValues;
  const url = "/us/investors/bank_accounts.json";
  const data = { bank_account: values };
  axios({
    data,
    headers: { "X-CSRF-Token": authToken, "Content-Type": "application/json" },
    method: "POST",
    url,
  }).then(response => dispatch(addBankAccountSuccess({ ...response.data, view })),
    addBankAccountFailure(dispatch));
};

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
