/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { DATES_OF_MONTH } from "../constants";
import { Error, Message } from "./Status";
import { required } from "../validations";
import { editDateOfMonth } from "../actions";

const Radio = ({ input, formProps, monthDate }) => {
  const {
    id, name, value, displayText, isDisabled, dataTestAttribute,
  } = monthDate;

  const { dateOfMonth, editDateOfMonth } = formProps;
  return (
    <div data-test={dataTestAttribute} className="form-stylised-radio radio-x-small u-mb-small">
      <input
        {...input}
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={value == dateOfMonth}
        onChange={() => editDateOfMonth("dateOfMonth", value)}
        disabled={isDisabled}
      />
      <label id={id} className="form-stylised-radio__label" htmlFor={id}>
        <span>{displayText}</span>
      </label>
    </div>
  );
};

const RadioList = ({
  label, formProps, input, meta: { touched, error },
}) => {
  const list = DATES_OF_MONTH;

  return (
    <div className={`form-element ${touched && error ? "is-error" : ""}`}>
      <label className="form-label form-element__label">
        <span>{label}</span>
        {touched && error && <Error />}
      </label>
      <div>
        {
          list.map(date => (
            <Radio
              key={date.id}
              monthDate={date}
              formProps={formProps}
              input={input}
            />
          ))
        }
      </div>
      <div className="u-mt-negative-x-small">
        {touched && error && <Message message={error} />}
        {!(touched && error) && <Message message="The 29th, 30th, 31st are not recurring transfer date options." />}
      </div>
    </div>
  );
};

const presence = required("Please select the day you want this transfer to reoccur. The 29th, 30th, 31st are not recurring transfer date options.");

const DatesOfMonth = (props) => {
  const { dateOfMonth, editDateOfMonth } = props;
  const formProps = { dateOfMonth, editDateOfMonth };
  return (
    <Field
      name="dateOfMonth"
      label="On this date:"
      formProps={formProps}
      validate={[presence]}
      component={RadioList}
    />
  );
};

const mapStateToProps = state => ({
  dateOfMonth: state.transferValues.dateOfMonth,
});

export default connect(mapStateToProps, { editDateOfMonth })(DatesOfMonth);
