import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import SelectOption from "../components/SelectOption";
import Submit from "../components/Submit";
import FileUpload from "../components/FileUpload";
import { updateStep } from "../actions";

import { ACCREDITATION_METHODS, DOCUMENT_UPLOAD_TEXT, DOCUSIGN_LINK } from "../constants";

const STEP_NAME = "uploadAccreditationDocuments";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  step,
  title,
  accreditationMethod,
  selectMethod,
  clearMethod,
  authToken,
  taxDocsLastYear,
  taxDocsYearBeforeLast,
  assetStatements,
  consumerReports,
  investorRepresentativeLetters,
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 3 OF 3</p>

      <h2 className="section-heading section-heading--small u-mb-x-small">{ title }</h2>

      <form onSubmit={handleSubmit({ validate: false })}>
        {!accreditationMethod &&
          <Field
            name="accreditationMethod"
            optionList={ACCREDITATION_METHODS}
            component={SelectOption}
            selectMethod={selectMethod}
            authToken={authToken}
            validates={[
              ["presence", "Please select an option"]
            ]}
          />
        }

        {accreditationMethod && (
          <div>
            <div className="u-mb-medium">
              <a className="cta-link" onClick={clearMethod}>‹ Change method</a>
            </div>
            <p className="paragraph-heading paragraph-heading--medium">{_.find(ACCREDITATION_METHODS, { 'value': accreditationMethod}).label}</p>
            <p>{DOCUMENT_UPLOAD_TEXT[accreditationMethod].p}</p>
            {accreditationMethod === "thirdPartyVerification" ? <p>Send your Investor Representative the following <a className="cta-link" href={DOCUSIGN_LINK} target="_blank">Docusign form</a>.</p> : ""}
            <div>{DOCUMENT_UPLOAD_TEXT[accreditationMethod].ul}</div>
            <ul>
              {_.map(DOCUMENT_UPLOAD_TEXT[accreditationMethod].li, (li) => <li key={li} className="u-mb-x-small">{li}</li>)}
            </ul>

            {accreditationMethod === "income" && (
              <div>
                <p className="paragraph-heading--light-grey">{DOCUMENT_UPLOAD_TEXT[accreditationMethod].i}</p>
                <Field
                  component={FileUpload}
                  name="taxDocsLastYear"
                  documentType="personal_tax_return"
                  documents={taxDocsLastYear}
                  authToken={authToken}
                  validates={[
                    ["presence", "Please upload a file"],
                    ["successfulUpload"]
                  ]}
                />
                <Field
                  component={FileUpload}
                  name="taxDocsYearBeforeLast"
                  documentType="personal_tax_return"
                  documents={taxDocsYearBeforeLast}
                  authToken={authToken}
                  validates={[
                    ["presence", "Please upload a file"],
                    ["successfulUpload"]
                  ]}
                />
              </div>
            )}

            {accreditationMethod === "netWorth" && (
              <div>
                <Field
                  component={FileUpload}
                  name="assetStatements"
                  documentType="asset_statements"
                  authToken={authToken}
                  documents={assetStatements}
                  validates={[
                    ["presence", "Please upload a file"],
                    ["successfulUpload"]
                  ]}
                />
                <p>{DOCUMENT_UPLOAD_TEXT[accreditationMethod].p2}</p>
                <p className="paragraph-heading--light-grey">{DOCUMENT_UPLOAD_TEXT[accreditationMethod].i}</p>
                <Field
                  component={FileUpload}
                  name="consumerReports"
                  documentType="consumer_credit_reports"
                  authToken={authToken}
                  documents={consumerReports}
                  validates={["successfulUpload"]}
                />
              </div>
            )}

            {accreditationMethod === "thirdPartyVerification" && (
              <div>
                <p>{DOCUMENT_UPLOAD_TEXT[accreditationMethod].p2}</p>
                <Field
                  component={FileUpload}
                  name="investorRepresentativeLetters"
                  documentType="investor_representative_letters"
                  authToken={authToken}
                  documents={investorRepresentativeLetters}
                  validates={["successfulUpload"]}
                />
              </div>
            )}
          </div>
        )}

        <Submit value="Continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(() => {
  return <div>Details</div>;
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ profile, authToken }) => {
  const {
    accreditationMethod,
    taxDocsLastYear,
    taxDocsYearBeforeLast,
    assetStatements,
    consumerReports,
    investorRepresentativeLetters,
  } = profile;

  return {
    step: STEP_NAME,
    title: "Upload Accreditation Documents",
    accreditationMethod,
    authToken,
    taxDocsLastYear,
    taxDocsYearBeforeLast,
    assetStatements,
    consumerReports,
    investorRepresentativeLetters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectMethod: selection => () => dispatch(updateStep(STEP_NAME, "accreditationMethod", selection)),
    clearMethod: () => dispatch(updateStep(STEP_NAME, "accreditationMethod", null)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);
