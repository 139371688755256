import React from "react";
import Icon from "radius/dist/m2dm/react/icon";

export const Error = () => (
  <Icon
    name="cross-circle-solid"
    extraClasses="svg-icon--solid-error"
  />
);

export const Success = () => (
  <Icon
    name="tick-circle-solid"
    extraClasses="svg-icon--solid-success"
  />
);

export const Message = ({ message }) => (
  <div className="form-field-status status-disclaimer">
    <Icon
      name="info-circle-outline"
      extraClasses="form-field-status__icon form-field-status__icon--top"
    />
    <span className="form-field-status__copy">
      {message}
    </span>
  </div>
);

export const AnimatedSuccessSvg = () => (
  <svg className="check-animation u-mb-medium" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
    <circle className="path circle" fill="none" stroke="#FFF" strokeWidth="2" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
    <polyline className="path check" fill="none" stroke="#70D8A6" strokeWidth="12" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
  </svg>
);
