import axios from "axios";
import _ from "lodash";

import { debouncedAction } from "../common/utils/common";

export { uncaughtError } from "../common/actions";

export function init(props) {
  return {
    type: "INIT",
    config: props.config,
    data: props.data,
  };
}

export function clearError() {
  return {
    type: "CLEAR_ERROR",
  };
}

export const setErrorTimer = debouncedAction(clearError, 5000);

export function fetchHoldingsSuccess(opts) {
  return {
    type: "FETCH_HOLDINGS_SUCCESS",
    ...opts,
  };
}

export function fetchHoldingsFailure(prevState) {
  return {
    type: "FETCH_HOLDINGS_FAILURE",
    prevState,
  };
}

export function fetchHoldings(params, prevState) {
  return (dispatch, getState) => {
    const success = (response) => {
      const filtersApplied = _.some(getState().filtering.filtersApplied);

      dispatch(fetchHoldingsSuccess({
        data: response.data,
        filtersApplied,
      }));
    };

    const failure = () => {
      dispatch(fetchHoldingsFailure(prevState));
    };

    dispatch({
      type: "FETCH_HOLDINGS_START",
    });

    return axios
      .get("holdings_data.json", { params, timeout: 30000 })
      .then(success, failure)
      .catch(failure);
  };
}

function withScrollToTop(dispatch, getState, win, action) {
  const isMobile = getState().view.lte.mobile.large;
  if (isMobile) {
    win.scrollTo(0, 0);
  }

  dispatch(action);
}

export function selectPage(pageNumber, win) {
  const action = {
    type: "SELECT_PAGE",
    pageNumber,
  };

  return (dispatch, getState) => {
    withScrollToTop(dispatch, getState, win, action);
  };
}

export function selectPrevious(win) {
  return (dispatch, getState) => {
    withScrollToTop(dispatch, getState, win, { type: "PREVIOUS_PAGE" });
  };
}

export function selectNext(win) {
  return (dispatch, getState) => {
    withScrollToTop(dispatch, getState, win, { type: "NEXT_PAGE" });
  };
}

export function togglePaginationDropdown() {
  return {
    type: "TOGGLE_PAGINATION_DROPDOWN",
  };
}

export function toggleFilter(group, filter) {
  return {
    type: "TOGGLE_FILTER",
    group: group.code,
    filter,
  };
}

export function applyFilters() {
  return { type: "APPLY_FILTERS" };
}

export function clearFilters() {
  return { type: "CLEAR_FILTERS" };
}

export function toggleFilterBox() {
  return { type: "TOGGLE_FILTER_BOX" };
}

export function toggleSortOptions() {
  return { type: "TOGGLE_SORT_OPTIONS" };
}

export function toggleSortColumn(columnName) {
  return {
    type: "TOGGLE_SORT_COLUMN",
    columnName,
  };
}

export function slideLegendItem(itemName) {
  return {
    type: "SLIDE_LEGEND_ITEM",
    item: itemName,
  };
}

export function toggleExpand(cardId) {
  return {
    type: "EXPAND_CARD",
    cardId,
  };
}

export function closePaginationDropdown() {
  return (dispatch, getState) => {
    if (getState().pagination.dropdownOpen) {
      dispatch({
        type: "CLOSE_PAGINATION_DROPDOWN",
      });
    }
  };
}
