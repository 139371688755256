import React from "react";
import _ from "lodash";
import Icon from "radius/dist/m2dm/react/icon";
import { gaEvent } from "../utils/common";
import { TERMS_GLOSSARY } from "../constants";

class Glossary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  toggleModal() {
    const { showModal } = this.state;
    if (!showModal) {
      gaEvent("summary", "open-modal", "glossary-of-terms");
    }
    this.setState({ showModal: !showModal });
  }

  render() {
    const { showModal } = this.state;

    return (
      <div>
        <div className="modal-link" onClick={this.toggleModal.bind(this)}>
          <span className="modal-link-text">Glossary of terms</span>
          <Icon
            name="new-window"
            modifiers={["small"]}
          />
        </div>
        <div className={`modal glossary-modal ${showModal ? "open" : ""}`}>
          <div className="modal__overlay" tabIndex="-1" onClick={this.toggleModal.bind(this)}></div>
          <div className="modal__box" role="dialog" aria-labelledby="dialog-title">
            <div className="modal__header">
              <div className="close-icon" onClick={this.toggleModal.bind(this)}>
                <Icon
                  name="cross"
                  modifiers={["medium"]}
                />
              </div>
            </div>
            <div className="modal__content u-grid-141">
              <h2 className="section-heading section-heading--small">Glossary of Terms</h2>
              <div>
                <dl>
                  {_.map(TERMS_GLOSSARY, entry => (
                    <div key={entry.term}>
                      <h2 className="paragraph-heading paragraph-heading--small">{entry.term}</h2>
                      <p className="definition">{entry.definition}</p>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Glossary;
