import React from "react";
import { connect } from "react-redux";
import { createStep } from "../containers/Step";
import Submit from "../components/Submit";

const STEP_NAME = "profileComplete";

const View = ({
  handleSubmit,
  step
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--medium text-block__heading-bar--primary">Profile complete</h2>

        <div className="profile-complete-image"></div>

        <p className="lead-paragraph lead-paragraph--x-large u-mb-small">
          You have successfully completed your profile.
        </p>

        {/* <p> */}
        {/*   You will be asked to upload documents to verify the information you’ve */}
        {/*   provided. This will include a valid government-issued U.S. ID, a bank */}
        {/*   statement, and additional documentation depending on how you wish to verify */}
        {/*   your income or net worth. */}
        {/* </p> */}

        <p>Your profile and documents are being reviewed by our investor success team. A team member will contact you within the next two business days to let you know when your account is activated.</p>
        <p>You can now explore the rest of your investor account.</p>
        <div className="u-text-center submit-button">
          <button className="cta-primary cta--block" type="button">
            <a className="cta-primary--link" href="/us/investors/summary">Go to dashboard</a>
          </button>
        </div>

        {/* <form onSubmit={handleSubmit()}> */}
        {/*   <Submit value="Upload documents" step={step} /> */}
        {/* </form> */}
      </div>
    </section>
  );
};

const mapStateToProps = ({ stepModes }) => {
  return {
    step: STEP_NAME
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
