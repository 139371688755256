import React from "react";
import ValidatedField from "./ValidatedField";
import { RADIUS_ASSET_PATH } from "../constants";

const SelectBetween = ({
  startVal,
  endVal,
  errors,
  handleBlur,
  handleFocus,
  handleUpdate,
  ifStatus,
  label,
  name,
  value
}) => {
  const callTime = { startVal: "", endVal: "", ...value };

  const onStartVal = f => evt => {
    f(name, { ...value, startVal: evt.target.value })
  };

  const onEndVal = f => evt => {
    f(name, { ...value, endVal: evt.target.value })
  };

  const renderOption = (option, index) => (
    <option key={index} value={option.value}>{option.text}</option>
  );

  return(
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      <div className="field--flex">
        <div className="form-select form-input--icon form-select__element--48">
          <select
            className="form-select__element"
            name={name}
            value={callTime.startVal}
            onChange={onStartVal(handleUpdate)}
            onFocus={onStartVal(handleFocus)}
            onBlur={onStartVal(handleBlur)}>
            {startVal.map(renderOption)}
          </select>
          <svg className="svg-icon form-select__icon" aria-labelledby="arrowhead-down-icon-title" role="img">
            <title id="arrowhead-down-icon-title">Arrowhead down icon</title>
            <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#arrowhead-down`}></use>
          </svg>
        </div>
        <div className="form-select form-input--icon form-select__element--48">
          <select
            className="form-select__element"
            name={name}
            value={callTime.endVal}
            onChange={onEndVal(handleUpdate)}
            onFocus={onEndVal(handleFocus)}
            onBlur={onEndVal(handleBlur)}>
            {endVal.map(renderOption)}
          </select>
          <svg className="svg-icon form-select__icon" aria-labelledby="arrowhead-down-icon-title" role="img">
            <title id="arrowhead-down-icon-title">Arrowhead down icon</title>
            <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#arrowhead-down`}></use>
          </svg>
        </div>
      </div>
    </ValidatedField>
  );
};

SelectBetween.defaultProps = { name: "", value: { startVal: "", endVal: "" } };

export default SelectBetween;
