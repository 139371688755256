import React from "react";
import * as d3 from "d3";
import chevronArc from "../../common/d3-chevronArc"

class DonutChart extends React.Component {
  componentDidMount() {
    let width = 245,
        height = 245,
        radius = Math.min(width, height) / 2;

    let arc = chevronArc()
        .outerRadius(radius - 10)
        .innerRadius(radius - 26);

    let pie = d3.pie()
        .sort(null)
        .value(function(d) { return d.value; });

    let svg = d3.select("#portfolio-balance-donut-chart").append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let g = svg.selectAll(".arc")
        .data(pie(_.sortBy(this.props.chartAttributes, function(d) {return d.cssClass;})))
        .enter().append("g")
        .attr("class", "arc");

    g.append("path")
        .attr("d", arc)
        .attr("class", d => {
          return d.data.cssClass;
        });
  }

  render() {
    const portfolioBalance = this.props.portfolioBalance;

    return (
      <div id="portfolio-balance-donut-chart">
        <div className="donut-chart-middle">
          <div className="paragraph-heading u-mb-none">
            <span>$</span>
            <span className={`dollars ${portfolioBalance.dollars.length > 9 ? "paragraph-heading--fixed-large" : "paragraph-heading--2x-large paragraph-heading--line-height-small"}`}>{portfolioBalance.dollars}</span>
            <span>.{portfolioBalance.cents}</span>
          </div>
          <div className="title">PORTFOLIO VALUE</div>
        </div>
      </div>
    );
  }
}

export default DonutChart;
