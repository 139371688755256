import React from 'react';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';
import PageRange from './PageRange';

const clickOutsideConfig = {
  handleClickOutside: function(instance) {
    return instance.props.closePaginationDropdown;
  }
};

const View = ({view, pagination, selectPage, selectPrevious, selectNext, togglePaginationDropdown, closePaginationDropdown}) => {

  let {totalPages, currentPage, dropdownOpen, itemRanges} = pagination;
  let pages = _.range(1, totalPages + 1);

  return (
    <div className='pagination-container'>
      <PageRange {...pagination} />
      <span className='pagination-nav-box'>
        <div
          className={'previous pagination-nav ' + (currentPage == 1 ? 'disabled' : '')}
          onClick={() => { selectPrevious(window) }}>
        </div>
        <div
          className={'next pagination-nav ' + (currentPage == totalPages ? 'disabled' : '')}
          onClick={() => { selectNext(window) }}>
        </div>
      </span>
      <div
        onClick={() => togglePaginationDropdown()}
        className='pagination-dropdown'>
        <div className={'pagination-dropdown-items ' + (dropdownOpen ? 'open' : 'closed')}>
          {pages.map(pageNumber => (
            <div
              className={'pagination-dropdown-item ' + (pageNumber == currentPage ? 'current' : '')}
              key={pageNumber}
              onClick={() => selectPage(pageNumber, window)}>{itemRanges[pageNumber - 1]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const Pagination = onClickOutside(View, clickOutsideConfig);

export default Pagination;
