import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Icon from "radius/dist/m2dm/react/icon";
import { MONTHS, DAYS_OF_WEEK } from "../constants";
import { ordinalizeNumber, capitalize, formatMoney } from "../helpers";
import { cancelTransfer, cancelTransferSchedule } from "../actions/cancelTransfer";
import { renderIf } from "../../common/utils/matchers";

const TransfersTable = (props) => {
  const {
    name, bankName, lastFourBankAccountNumber, transfers, noTransfersFoundMessage,
    firstColumnHeading, dispatch,
  } = props;
  return (
    <div className="u-mb-4x-large">
      <h2 className="paragraph-heading paragraph-heading--medium">{name}</h2>
      {transfers.length === 0 && (
        <p className="lightgrey u-mb-6x-large">
          {noTransfersFoundMessage}
        </p>
      )}
      {transfers.length > 0 && (
        <table className="upcoming-table">
          <thead>
            <tr>
              <th>{firstColumnHeading}</th>
              <th>From</th>
              <th>To</th>
              <th>Recurrence</th>
              <th className="right">Amount</th>
              <th className="right"><span className="invisible">Action</span></th>
            </tr>
          </thead>
          <tbody>
            {transfers.map((transfer) => {
              const { transfer_at, action, transfer_frequency } = transfer;
              const date = new Date(transfer_at);
              const from = action === "withdrawal" ? "Funding Circle" : `${bankName} ${lastFourBankAccountNumber}`;
              const to = action === "withdrawal" ? `${bankName} ${lastFourBankAccountNumber}` : "Funding Circle";
              const recurring = transfer_frequency !== "One time";
              const frequency = recurring ? capitalize(transfer_frequency) : transfer_frequency;
              const cancel = recurring ? cancelTransferSchedule : cancelTransfer;
              let day = "";

              if (frequency === "Weekly") {
                day = `(${_.find(DAYS_OF_WEEK, weekDay => weekDay.value === date.getDay()).abbreviatedDisplayText})`;
              } else if (frequency === "Monthly") {
                day = `(${ordinalizeNumber((date.getDate()).toString())})`;
              }

              return (
                <tr key={transfer.id}>
                  <td>
                    <div className="transfers__heading">{name === "Processing" ? "Request date" : "Next transfer"}</div>
                    <div className="transfers__data">{`${MONTHS[date.getMonth()]} ${date.getDate()}`}</div>
                  </td>
                  <td>
                    <div className="transfers__heading">From</div>
                    <div className="transfers__data">{from}</div>
                  </td>
                  <td>
                    <div className="transfers__heading">To</div>
                    <div className="transfers__data">{to}</div>
                  </td>
                  <td>
                    <div className="transfers__heading">Recurrence</div>
                    <div className="transfers__data">{`${frequency} ${day}`}</div>
                  </td>
                  <td className="right">
                    <div className="transfers__heading">Amount</div>
                    <div className="transfers__data">{`$${formatMoney(transfer.amount_cents)}`}</div>
                  </td>
                  <td className="right">
                    {renderIf(name === "Processing")(
                      <div className="processing-icon">
                        <Icon
                          name="clock"
                          extraClasses="svg-icon--disabled u-mb-tiny"
                        />
                      </div>,
                    )}
                    { renderIf(name === "Scheduled")(
                      <div>
                        <span className="delete-icon" onClick={() => dispatch(cancel(transfer))}>
                          <Icon
                            name="delete"
                            extraClasses="svg-icon--action pointer u-mb-tiny"
                          />
                        </span>
                        <div className="delete-text">
                          <button type="button" onClick={() => dispatch(cancel(transfer))}>
                            Delete transfer
                          </button>
                        </div>
                      </div>,
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    bankName, processingTransfers, scheduledTransfers, lastFourBankAccountNumber,
  } = state.transferValues;

  const { name } = ownProps;
  const transfers = name === "Processing" ? processingTransfers : scheduledTransfers;
  const noTransfersFoundMessage = name === "Processing" ? "You don’t have any processing transfers at the moment." : "You don’t have any scheduled transfers.";
  const firstColumnHeading = name === "Processing" ? "Request date" : "Next transfer";
  return {
    ...ownProps,
    bankName,
    lastFourBankAccountNumber,
    transfers,
    noTransfersFoundMessage,
    firstColumnHeading,
  };
};

export default connect(mapStateToProps)(TransfersTable);
