import React from "react";
import ValidatedField from "./ValidatedField";

import { MONTHS, RADIUS_ASSET_PATH } from "../constants";


const DateField = ({
  errors,
  handleBlur,
  handleFocus,
  handleUpdate,
  ifStatus,
  label,
  name,
  value
}) => {
  const date = { year: "", month: "", day: "", ...value };

  const onMonthVal = f => evt => {
    f(name, { ...date, month: evt.target.value })
  };

  const onDayVal = f => evt => {
    f(name, { ...date, day: evt.target.value })
  };

  const onYearVal = f => evt => {
    f(name, { ...date, year: evt.target.value })
  };

  const renderOption = (option, index) => (
    <option key={index} value={option.value} disabled={option.disabled}>{option.text}</option>
  );

  return (
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      <div className="field--flex">
        <div className="form-select form-select--40 form-input--icon">
          <select
            className="form-select__element"
            name={name}
            value={date.month}
            onChange={onMonthVal(handleUpdate)}
            onFocus={onMonthVal(handleFocus)}
            onBlur={onMonthVal(handleBlur)}>
            <option value="" disabled>Month</option>
            {MONTHS.map(renderOption)}
          </select>
          <svg className="svg-icon form-select__icon" aria-labelledby="arrowhead-down-icon-title" role="img">
            <title id="arrowhead-down-icon-title">Arrowhead down icon</title>
            <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#arrowhead-down`}></use>
          </svg>
        </div>
        <div className="form-input--25">
          <input
            className="form-input__element"
            type="text"
            name={name}
            value={date.day}
            placeholder="DD"
            onChange={onDayVal(handleUpdate)}
            onFocus={onDayVal(handleFocus)}
            onBlur={onDayVal(handleBlur)} />
        </div>
        <div className="form-input--30">
          <input
            className="form-input__element"
            type="text"
            name={name}
            value={date.year}
            placeholder="YYYY"
            onChange={onYearVal(handleUpdate)}
            onFocus={onYearVal(handleFocus)}
            onBlur={onYearVal(handleBlur)} />
        </div>
      </div>
    </ValidatedField>
  );
};

DateField.defaultProps = { name: "", value: {} };

export default DateField;
