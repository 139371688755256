import { combineReducers } from "redux";
import authTokenReducer from "./authTokenReducer";
import currentStepReducer from "./currentStepReducer";
import fieldsReducer from "./fieldsReducer";
import phaseReducer from "./phaseReducer";
import profileReducer from "./profileReducer";
import onboardingIdReducer from "./onboardingIdReducer";
import errorReducer from "./errorReducer";
import stepModesReducer from "./stepModesReducer";
import stepsReducer from "./stepsReducer";
import stateListReducer from "./stateListReducer";
import questionsReducer from "./questionsReducer";
import configReducer from "../../common/reducers/configReducer";

const eligible = (state = false, action) => {
  switch (action.type) {
    case "INIT":
      return action.eligible;
    case "SUBMIT_SUCCESS":
      return action.eligible;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  config: configReducer,
  authToken: authTokenReducer,
  currentStep: currentStepReducer,
  eligible,
  fields: fieldsReducer,
  onboardingId: onboardingIdReducer,
  error: errorReducer,
  phase: phaseReducer,
  profile: profileReducer,
  stepModes: stepModesReducer,
  steps: stepsReducer,
  stateList: stateListReducer,
  questions: questionsReducer,
});

export default rootReducer;
