import React from "react";
import PrincipalOutstandingLegend from "./PrincipalOutstandingLegend";
import SlidePanel from "./SlidePanel";
import LateBreakdown from "./LateBreakdown";
import DefaultedBreakdown from "./DefaultedBreakdown";
import PieChart from "./PieChart";
import { renderIf } from "../../common/utils/matchers";
import { DEFAULTED_ID, LATE_ID } from "../constants";

const PrincipalOutstanding = ({
  principalOutstanding,
  slideLegendItem,
  mediaClass,
  config
}) => {
  const limitedViewEnabled = config.limited_view_enabled;

  return (
    <div className={`principal-outstanding-container ${mediaClass}`}>
      <div className="principal-outstanding-header">
        <span className="paragraph-heading label">Outstanding Principal</span>
        {principalOutstanding.data.total}
      </div>
      <div className="principal-outstanding-data">
        <PieChart
          dimensions={{ width: 182, height: 182 }}
          data={principalOutstanding.data.principalTypes}
        />
        <PrincipalOutstandingLegend
          principalTypes={principalOutstanding.data.principalTypes}
          slidePanelItem={principalOutstanding.slidePanelItem}
          slideLegendItem={slideLegendItem}
          limitedViewEnabled={limitedViewEnabled}
        />
        <SlidePanel
          slideLegendItem={slideLegendItem}
          slidePanelItem={principalOutstanding.slidePanelItem}
        >
          {renderIf(principalOutstanding.slidePanelItem === DEFAULTED_ID)(
            <DefaultedBreakdown
              limitedViewEnabled={limitedViewEnabled}
              netRecoveries={principalOutstanding.data.netRecoveries}
              chargeOffs={principalOutstanding.data.chargeOffs}
            />
          )}
          {renderIf(principalOutstanding.slidePanelItem === LATE_ID)(
            <LateBreakdown lateHoldings={principalOutstanding.data.lateHoldings} />
          )}
        </SlidePanel>
      </div>
    </div>
  );
};

export default PrincipalOutstanding;
