export const LATE_ID = "late";
export const DEFAULTED_ID = "defaulted";

export const filters = {
  current: {
    displayPosition: 0,
  },
  defaulted: {
    displayPosition: 1,
  },
  late: {
    displayPosition: 2,
  },
  paid_in_full: {
    displayPosition: 3,
    displayName: "Paid",
  },
  charged_off: {
    displayPosition: 4,
    displayName: "Charged-off",
  },
  "A+": {
    displayPosition: 5,
  },
  A: {
    displayPosition: 6,
  },
  B: {
    displayPosition: 7,
  },
  C: {
    displayPosition: 8,
  },
  D: {
    displayPosition: 9,
  },
  6: {
    displayPosition: 10,
  },
  12: {
    displayPosition: 11,
  },
  24: {
    displayPosition: 12,
  },
  36: {
    displayPosition: 13,
  },
  48: {
    displayPosition: 14,
  },
  60: {
    displayPosition: 15,
  },
};

//
// These are sorted in rendering order for the mobile
// view, so please don't alphabetize them.
//
export const columnNames = {
  purchased_at: "Purchased",
  status: "Status",
  original_principal_cents: "Original Principal",
  remaining_principal_cents: "Remaining Principal",
  credit_rating: "Risk",
  term_months: "Term",
  interest_rate: "Rate",
};
