import "core-js/stable";

import ReactOnRails from "react-on-rails";
import Dashboard from "../bundles/Dashboard/Dashboard";
import Portfolio from "../bundles/Portfolio/Portfolio";
import InvestorOnboarding from "../bundles/InvestorOnboarding/InvestorOnboarding";
import ResetPassword from "../bundles/Authentication/ResetPassword";
import SignIn from "../bundles/Authentication/SignIn";
import ChangePassword from "../bundles/Authentication/ChangePassword";
import InvestorOnboardingWelcome from "../bundles/InvestorOnboardingWelcome";
import RecurringTransfersWrapper from "../bundles/RecurringTransfers/RecurringTransfersWrapper";
import Notification from "../bundles/common/components/Notification";
import Glossary from "../bundles/common/components/Glossary";

// This is how react_on_rails can see the bundles in the browser.
ReactOnRails.register({
  Dashboard,
  Portfolio,
  InvestorOnboarding,
  ResetPassword,
  SignIn,
  ChangePassword,
  InvestorOnboardingWelcome,
  RecurringTransfersWrapper,
  Notification,
  Glossary,
});
