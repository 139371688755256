import React from "react";
import ValidatedField from "./ValidatedField";
import { RADIUS_ASSET_PATH } from "../constants";

const SelectField = ({
  children,
  errors,
  handleBlur,
  handleFocus,
  handleUpdate,
  ifStatus,
  label,
  name,
  value
}) => {
  return (
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      <div className="form-select form-input--icon">
        <select
          className="form-select__element"
          type="text"
          name={name}
          value={value}
          onChange={handleUpdate}
          onFocus={handleFocus}
          onBlur={handleBlur}>
          {children}
        </select>
        <svg className="svg-icon form-select__icon" aria-labelledby="arrowhead-down-icon-title" role="img">
          <title id="arrowhead-down-icon-title">Arrowhead down icon</title>
          <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#arrowhead-down`}></use>
        </svg>
      </div>
    </ValidatedField>
  );
};

SelectField.defaultProps = { name: "", value: "" };

export default SelectField;
