import React from "react";
import { connect } from "react-redux";
import { showModal } from "../actions";

const TransferInitiatedModal = ({ showModal, showTransferInitiatedModal, transferType }) => (
  <div className={`modal transfer-initiated-modal ${showTransferInitiatedModal ? "show" : ""}`}>
    <div className="modal__overlay modal__overlay--dark" tabIndex="-1" />
    <div className="modal__box modal__box--no-scroll modal__transfer-initiated">
      <div className="modal__content u-text-center">
        <svg className="check-animation u-mb-medium" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle className="path circle" fill="none" stroke="#FFF" strokeWidth="2" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <polyline className="path check" fill="none" stroke="#70D8A6" strokeWidth="12" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
        </svg>
        <h2 className="section-heading section-heading--small section-heading--green u-mb-x-small">
          Transfer initiated
        </h2>
        <div className="white">
          <p className="lead-paragraph lead-paragraph--x-large u-mb-2x-large">
            {transferType === "deposit" ? "Allow up to 8 business days for funds to show up in your account." : "Allow 3-5 business days for funds to show up in your account."}
          </p>
          <div className="font-large">
            <a href="/transfers/upcoming_transfers" onClick={() => showModal("showTransferInitiatedModal", false)}>
              <button className="cta-secondary white u-mb-small" type="button">View upcoming transfers</button>
            </a>
            <p>Or</p>
            <a href="/summary" className="cta-link" onClick={() => showModal("showTransferInitiatedModal", false)}>Return to Summary</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

TransferInitiatedModal.defaultProps = {
  showTransferInitiatedModal: false,
  transferType: "",
};

export default connect(state => ({ ...state.transferValues}), { showModal })(TransferInitiatedModal);
