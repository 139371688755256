import React from "react";
import ValidatedField from "./ValidatedField";

import InputMask from "react-input-mask";

const TextField = ({
  errors,
  handleBlur,
  handleFocus,
  handleUpdate,
  ifStatus,
  label,
  mask,
  name,
  value
}) => {
  return(
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      <InputMask
        type="text"
        className="form-input__element"
        name={name}
        mask={mask}
        value={value}
        onChange={handleUpdate}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </ValidatedField>
  );
};

TextField.defaultProps = { name: "", value: "" };

export default TextField;
