import React from "react";
import { Error, Success, Message } from "./Status";

const TextInputField = ({ label, input, meta: { touched, error } }) => (
  <div className={`form-element ${touched && error ? "is-error" : ""}`}>
    <label className="form-label form-element__label">
      <span>{label}</span>
      {touched && error && <Error />}
      {touched && !error && <Success />}
    </label>
    <div className="form-element__elements u-mb-x-small">
      <div className="form-input form-input--icon">
        <input
          type="text"
          {...input}
          className="form-input__element"
          data-test={input.name.replace(/_/g, "-")}
        />
      </div>
    </div>
    {touched && error && <Message message={error} />}
  </div>
);

export default TextInputField;
