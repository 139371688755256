import React from 'react';

const EmptyState = ({
  clearFilters,
  emptyState,
  filtering: { canClear }
}) => {
  if (emptyState.isEmpty && emptyState.filtersApplied) {
    return (
      <div className='no-holdings'>
        <div className='message'>
          No loans found. Please try again with a different set of filters.
          <span
            className={'filter-clear ' + (canClear ? 'active' : 'inactive')}
            onClick={clearFilters}
          >
            Clear filters
          </span>
        </div>
      </div>
    );
  } else if (emptyState.isEmpty && !emptyState.filtersApplied) {
    return (
      <div className='no-holdings'>
        <div className='message'>
          No loans found.
        </div>
      </div>
    );
  };
};

export default EmptyState;
