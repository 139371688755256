import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";

import {
  observeStore,
} from "./observer";

import {
  observeMedia,
  observeScroll,
  observeErrors,
} from "../common/observers";

import { init } from "./actions";

const middlewares = [
  thunk,
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const configureStore = (props) => {
  const store = createStore(rootReducer, applyMiddleware(...middlewares));

  store.dispatch(init(props));

  //
  // Hydrate the store with the holdings. Since this is the initial fetch, we
  // can say no filters are applied.
  //
  observeStore(store);
  observeMedia(store.dispatch);
  observeScroll(store.dispatch);
  observeErrors(store.dispatch);

  return store;
};

export default configureStore;
