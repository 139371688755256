import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import { attemptSubmit, submitStep } from "../actions";
import _ from "lodash";

const Container = ({
  WrappedComponent,
  handleSubmit,
  isValid,
  ...rest
}) => {
  return (
    <WrappedComponent handleSubmit={handleSubmit(isValid)} {...rest} />
  );
};

export function isValid(steps, step) {
  return steps[step] && steps[step].isValid;
}

export function handleSubmit(dispatch, step) {
  return (isValid) => {
    return (opts = {}, cb = () => {}) => {
      const skipValidations = opts.validate === false;

      return (evt) => {
        evt.preventDefault();

        dispatch(attemptSubmit(step));

        if (isValid || skipValidations) {
          dispatch(submitStep(step));
        }

        cb();
      };
    };
  };
}

export function mapStateToProps(state, ownProps) {
  const { component, step } = ownProps;
  const { steps } = state;

  return {
    WrappedComponent: component,
    isValid: isValid(steps, step),
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  const { step } = ownProps;

  return {
    handleSubmit: handleSubmit(dispatch, step)
  };
}

const Form = connect(mapStateToProps, mapDispatchToProps)(Container);

export function createForm(component) {
  return props => <Form component={component} {...props} />;
}

export default Form;
