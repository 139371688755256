import React from 'react';
import ExpandBar from '../components/ExpandBar';

export const makeExpandable = (toggleExpand, isExpanded) => (ExpandableComponent) => {
  return (
    <div className='expandable'>
      <ExpandableComponent isExpanded={isExpanded} />
      <ExpandBar onClick={toggleExpand} isExpanded={isExpanded} />
   </div>
  );
}

export const makeExpandableInline = (toggleExpand, isExpanded) => (ExpandableComponent) => {
  return (
    <ExpandableComponent isExpanded={isExpanded} toggleExpand={toggleExpand} />
  );
}
