import React from "react";
import _ from "lodash";
import axios from "axios";
import { renderIf } from "../utils/matchers";
import { RADIUS_ASSET_PATH } from "../constants";

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: props.notifications,
      authToken: props.config.authToken
    };

    this.removeNotification = this.removeNotification.bind(this);
  }

  removeNotification(notification) {
    const remainingNotifications = _.reject(this.state.notifications, { code: notification.data.acknowledged });
    this.setState({ notifications: remainingNotifications });
  }

  dismissNotification(notification = {}, authToken) {
    if (notification.dismissal_url) {
      axios.post(
        notification.dismissal_url,
        {
          code: notification.code,
        },
        {
          headers: {
            "content-type": "application/json",
            "X-CSRF-Token": authToken,
          },
        },
      ).then(this.removeNotification);
    }
  }

  render() {
    const notification = this.state.notifications[0];
    const { authToken } = this.state;

    if (!notification) {
      return null;
    }

    return (
      <div className="notice notice--relative u-mb-small">
        <div className="notice__content">
          <div className="notice__copy">
            <p>
              {
                notification.text.map((displayText) => {
                  if (notification[displayText]) {
                    const { text, url } = notification[displayText];
                    return renderIf(url)(
                      <a
                        onClick={() => { this.dismissNotification(notification, authToken); }}
                        className="cta-link"
                        href={url}
                      >
                        {text}
                      </a>
                    );
                  }
                  return displayText;
                })
              }
            </p>
          </div>
          <a onClick={() => this.dismissNotification(notification, authToken) } aria-label="close">
            <svg className="svg-icon svg-icon--dismiss">
              <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#cross-circle-outline`} />
            </svg>
          </a>
        </div>
      </div>
    );
  }
}

export default Notification;
