import React from 'react';

const PageRange = ({totalItems, currentPage, itemRanges}) => {
  return (
    <div className='item-range-container'>
      <span className='item-range'><strong>{itemRanges[currentPage - 1]}</strong> of <strong>{totalItems}</strong></span>
    </div>
  );
}

export default PageRange;
