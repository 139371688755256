import _ from "lodash";
import { TERMS_GLOSSARY } from "../../common/constants";

const getGlossaryFor = code => _.find(TERMS_GLOSSARY, ["code", code]);

const formatPerformanceFigureData = (portfolioSummaries) => {
  const performanceFigureLabels = {
    total_amount_invested: "totalAmountInvested",
    weighted_average_coupon: "weightedAverageCoupon",
    total_interest_earned: "totalInterestEarned",
  };

  const portfolioBalanceData = _.pick(portfolioSummaries, Object.keys(performanceFigureLabels));

  return _.mapKeys(portfolioBalanceData, (value, key) => performanceFigureLabels[key]);
};

const formatCentsSuffix = (cents) => {
  if (cents) {
    return `.${cents}`;
  }

  return "";
};

const performanceFigures = (portfolioSummaries) => {
  let glossaryEntry; const
    performanceFiguresData = formatPerformanceFigureData(portfolioSummaries);

  return _.mapValues(performanceFiguresData, (value, key) => {
    glossaryEntry = getGlossaryFor(key);

    return {
      amount: {
        prefix: value.formatted_amount.dollars ? "$" : "",
        body: value.formatted_amount.dollars || value.formatted_amount,
        suffix: formatCentsSuffix(value.formatted_amount.cents) || "%",
      },
      label: glossaryEntry.label,
      info: glossaryEntry.definition,
      code: glossaryEntry.code,
    };
  });
};

export default function performanceFiguresReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return performanceFigures(action.data.portfolio_summaries);
    default:
      return state;
  }
}
