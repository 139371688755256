import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import Submit from "../components/Submit";
import FileUpload from "../components/FileUpload";

const STEP_NAME = "uploadBankStatement";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  step,
  title,
  accountNumber,
  bankName,
  authToken,
  bankStatements,
}) => {
  const bankAccountConcealedNumber = () => {
    return accountNumber.length > 4 ? ("*".repeat(accountNumber.length - 4) + accountNumber.slice(-5, -1)) : accountNumber;
  };
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 2 OF 3</p>

      <h2 className="section-heading section-heading--small">
        {title}
      </h2>

      <p>
        Upload a statement, dated within the past 3 months, from <b>{bankName} {bankAccountConcealedNumber()}</b>.
      </p>

      <p className="u-mb-large">
        Please ensure your name and account number are visible on your documents.
      </p>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          component={FileUpload}
          name="bankStatements"
          documentType="bank_statement"
          authToken={authToken}
          documents={bankStatements}
          validates={[
            ["presence", "Please upload a file"],
            ["successfulUpload"]
          ]}
        />
        <Submit value="Continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(() => {
  return <div></div>;
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ profile, authToken }) => {
  return {
    step: STEP_NAME,
    title: "Upload Bank Statement",
    accountNumber: profile.accountNumber,
    bankName: profile.bankName,
    authToken,
    bankStatements: profile.bankStatements,
  };
};

export default connect(mapStateToProps)(Step);
