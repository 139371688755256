import React from "react";
import { connect } from "react-redux";
import TransferForm from "../components/TransferForm";
import Modal from "../modals/Modal";
import { MODAL_NAME } from "../constants";

const AddFundsContainer = ({ visible }) => (
  <Modal visible={visible}>
    <div className="modal__content u-pv-medium">
      <div className="add-funds-header--x-large u-grid-141 u-mb-medium">
        <h2 className="section-heading u-mb-x-small">Add funds</h2>
        <p className="lead-paragraph">to your Funding Circle account</p>
      </div>
      <TransferForm />
    </div>
  </Modal>
);

const mapStateToProps = state => ({
  visible: state.activeModal === MODAL_NAME.ADD_FUNDS,
});

export default connect(mapStateToProps)(AddFundsContainer);
