import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import ProgressBar from "../components/ProgressBar";
import Notification from "../components/Notification";
import ContactInfo from "../steps/ContactInfo";
import Eligibility from "../steps/Eligibility";
import Ineligible from "../redirectSteps/Ineligible";
import Reachout from "../redirectSteps/Reachout";
import VerifyAccount from "../steps/VerifyAccount";

import SetPassword from "../steps/SetPassword";
import StartProfile from "../steps/StartProfile";
import Address from "../steps/Address";
import PersonalDetails from "../steps/PersonalDetails";
import Affiliations from "../steps/Affiliations";
import Suitability from "../steps/Suitability";
import BankInformation from "../steps/BankInformation";
import ElectronicSignature from "../steps/ElectronicSignature";
import ReviewProfile from "../steps/ReviewProfile";
import SubmitProfile from "../steps/SubmitProfile";
import ProfileComplete from "../steps/ProfileComplete";
import UploadIdentification from "../steps/UploadIdentification";
import UploadBankStatements from "../steps/UploadBankStatements";
import UploadAccreditationDocuments from "../steps/UploadAccreditationDocuments";
import FinishedUploading from "../steps/FinishedUploading";
import DocumentUploadComplete from "../steps/DocumentUploadComplete";
import { ACCOUNT_TYPES } from "../constants";
import ErrorMessage from '../../common/components/ErrorMessage';

import { setErrorTimer, clearError } from '../actions.js'

const mapStateToProps = (state) => {
  const investorAccountType = state.profile.investorAccountType;
  const eligible = (state.eligible);
  const ineligible = (!eligible && investorAccountType == ACCOUNT_TYPES.INDIVIDUAL);
  const nonIndividual = (!eligible && investorAccountType != ACCOUNT_TYPES.INDIVIDUAL);

  const { phase, error } = state;
  return {
    ifEligible:      renderIf(eligible),
    ifIneligible:    renderIf(ineligible),
    ifNotIndividual: renderIf(nonIndividual),
    phase,
    error
  };
};

const Container = ({ ifIneligible, ifEligible, ifNotIndividual, phase, error, clearError, setErrorTimer }) => {
  return (
    <div className="onboarding-container--margin-top">
      <ErrorMessage error={error} clearError={clearError} setErrorTimer={setErrorTimer} />
      {ifEligible(
        <div>
          <Notification phase={phase} />
          <ProgressBar phase={phase} />
          <Eligibility />
          <ContactInfo />
          <SetPassword />
          <VerifyAccount />
          <StartProfile />
          <PersonalDetails />
          <Address />
          <Affiliations />
          <Suitability />
          <BankInformation />
          <ElectronicSignature />
          <ReviewProfile />
          <SubmitProfile />
          <ProfileComplete />
          <UploadIdentification />
          <UploadBankStatements />
          <UploadAccreditationDocuments />
          <FinishedUploading />
          <DocumentUploadComplete />
        </div>
      )}
      {ifIneligible(
        <Ineligible />
      )}
      {ifNotIndividual(
        <Reachout />
      )}
    </div>
  );
};

const Layout = connect(mapStateToProps, {setErrorTimer, clearError})(Container);

export default Layout;
