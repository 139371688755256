import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import { createField } from "../containers/Field";
import { createStep } from "../containers/Step";
import SelectField from "../components/SelectField";
import Submit from "../components/Submit";
import YesNo from "../components/YesNo";

import {
  ACCOUNT_TYPES,
  ACCOUNT_TYPE_LABELS
} from "../constants";

const STEP_NAME = "eligibility";

const Field = createField(STEP_NAME);

const View = ({
  step,
  firstName,
  handleSubmit,
  ifIndividualInvestor,
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-mb-none">
          Welcome, {firstName}!
        </h2>

        <p className="u-mb-medium">
          We need to ask a few questions before we can set up your account.
        </p>

        <form onSubmit={handleSubmit()}>
          <Field
            name="investorAccountType"
            component={SelectField}
            validates={[
              ["presence", "Please select an account type"]
            ]}>
            <option value="" disabled>Select account type</option>
            <option value={ACCOUNT_TYPES.INDIVIDUAL}>
              {ACCOUNT_TYPE_LABELS[ACCOUNT_TYPES.INDIVIDUAL]}</option>
            <option value={ACCOUNT_TYPES.SINGLE_MEMBER_ENTITY}>
              {ACCOUNT_TYPE_LABELS[ACCOUNT_TYPES.SINGLE_MEMBER_ENTITY]}</option>
            <option value={ACCOUNT_TYPES.ENTITY}>
              {ACCOUNT_TYPE_LABELS[ACCOUNT_TYPES.ENTITY]}</option>
            <option value={ACCOUNT_TYPES.IRA}>
              {ACCOUNT_TYPE_LABELS[ACCOUNT_TYPES.IRA]}</option>
          </Field>

          {ifIndividualInvestor(
            <div>
              <Field
                name="exceedsIncomeThreshold"
                component={YesNo}
                validates={[
                  ["presentIfIndividual", "Please select an option"]
                ]}
              />

              <Field
                name="exceedsAssetsThreshold"
                component={YesNo}
                validates={[
                  ["presentIfIndividual", "Please select an option"]
                ]}
              />
            </div>
          )}

          <Submit step={step} />
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = ({ profile }) => {
  const { firstName, investorAccountType } = profile;

  return {
    step: STEP_NAME,
    ifIndividualInvestor: renderIf(investorAccountType === ACCOUNT_TYPES.INDIVIDUAL),
    firstName: firstName,
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
