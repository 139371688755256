import React from 'react';
import FilterGroup from './FilterGroup';
import { gaEvent } from "../../common/utils/common";

const FilterBox = ({
  applyFilters,
  canApply,
  error,
  filterBoxOpen,
  groups,
  toggleFilter,
  toggleFilterBox
}) => {
  const handleApply = () => {
    gaEvent("my-portfolio", "apply-filters", "filters");
    applyFilters();
  }

  return (
    <div className={'filter-box ' + (filterBoxOpen ? 'open' : 'closed')}>
      <div className='top'>
        <button
          className='cancel-apply'
          onClick={toggleFilterBox}>
          Cancel
        </button>
        <button
          className={'apply ' + (canApply ? 'active' : 'inactive')}
          onClick={handleApply}>
          Apply
        </button>
      </div>
      <hr />
      {Object.keys(groups).map(group => {
        return (
        <FilterGroup
          group={groups[group]}
          toggleFilter={toggleFilter}
          key={group} />
        )
      }
      )}
    </div>
  );
}

export default FilterBox;
