import _ from "lodash";
import { flipBool } from "../../common/utils/common";
import { PORTFOLIO_BALANCE_ID } from "../constants";

export function flipCard(state, action) {
  if (action.cardId === PORTFOLIO_BALANCE_ID) {
    return _.assign({}, state, { flipped: flipBool(state.flipped) });
  }
  return state;
}

export function expandCard(state, action) {
  if (action.cardId === PORTFOLIO_BALANCE_ID) {
    return _.assign({}, state, { expanded: flipBool(state.expanded) });
  }
  return state;
}

const chartAttributes = (portfolioBalanceData) => {
  const cssClasses = {
    outstanding_principal: "investor-funds-summary--principal-outstanding",
    available_funds: "investor-funds-summary--available",
    total_pending: "investor-funds-summary--lender-holding-account-balance",
  };

  return _.reduce(portfolioBalanceData, (memo, value, key) => {
    const summaryData = {
      value: value.in_cents,
      cssClass: cssClasses[key],
    };

    memo.push(summaryData);

    return memo;
  }, []);
};

const generalAttributes = portfolioBalanceData => _.reduce(
  portfolioBalanceData,
  (memo, value, key) => {
    const newAttribute = {};

    newAttribute[key] = value.formatted_amount;

    return { ...memo, ...newAttribute };
  }, {},
);

const pendingRepaymentsVisible = ({ pending_repayments }) => pending_repayments.in_cents; // eslint-disable-line

const portfolioAttributes = (portfolioSummaries) => {
  const portfolioBalanceFields = [
    "outstanding_principal",
    "total_pending",
    "portfolio_balance",
    "available_funds",
    "pending_repayments",
  ];

  const chartFields = [
    "outstanding_principal",
    "total_pending",
    "available_funds",
  ];

  const portfolioBalanceData = _.pick(portfolioSummaries, portfolioBalanceFields);
  const chartData = _.pick(portfolioSummaries, chartFields);

  return {
    generalAttributes: generalAttributes(portfolioBalanceData),
    chartAttributes: chartAttributes(chartData),
    flipped: false,
    expanded: false,
    pendingRepaymentsVisible: pendingRepaymentsVisible(portfolioBalanceData),
  };
};

export default function portfolioBalanceReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return portfolioAttributes(action.data.portfolio_summaries);
    case "FLIP_CARD":
      return flipCard(state, action);
    case "EXPAND_CARD":
      return expandCard(state, action);
    default:
      return state;
  }
}
