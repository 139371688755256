export const INVESTOR_RELATIONS_EMAIL = "support@fundingcircle-invest.com";
export const SOOKASA_HOMEPAGE_LINK = "https://www.sookasa.com/";
export const SOOKASA_FC_LINK = "https://cloud.sookasa.com/upload_page/23e9ae68-6ec6-4cb5-94dd-2ef8d8d9fe3f";
export const DOCUSIGN_LINK = "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d36d80fa-404a-4e80-b832-9e34e5f1e480";
export const FAQ = "https://investor-portal.fundingcircle.us/us/investors/faq";
export const RADIUS_ASSET_PATH = "https://dgnvhpcjbd3ba.cloudfront.net/radius/9.5.1";
export const PRIVATE_PLACEMENT_MEMORANDUM_LINK = "https://s3.amazonaws.com/fc-investor-portal-pdf/pdf_guides/FC+Notes+Program+-+PPM.pdf";
export const SUBSCRIPTION_AGREEMENT_LINK = "https://s3.amazonaws.com/fc-investor-portal-pdf/pdf_guides/FC+Notes+Program+-+Subscription+Agreement.pdf";

export const PHASE = {
  CREATE_ACCOUNT: "createAccount",
  COMPLETE_PROFILE: "completeProfile",
  REVIEW_PROFILE: "reviewProfile",
  UPLOAD_DOCUMENTS: "uploadDocuments",
  DOCUMENT_UPLOAD_COMPLETE: "documentUploadComplete",
};

export const MODE = {
  SHOW: "show",
  EDIT: "edit",
  HIDDEN: "hidden",
  COLLAPSED: "collapsed",
};

export const ACCOUNT_TYPES = {
  INDIVIDUAL: "individual_investor",
  SINGLE_MEMBER_ENTITY: "single_member_entity",
  ENTITY: "entity",
  IRA: "ira",
};

export const ACCOUNT_TYPE_LABELS = {
  individual_investor: "Individual Investor",
  single_member_entity: "Single member Entity/Corp/Trust/LLC",
  entity: "Entity/Corp/Trust/LLC",
  ira: "Individual Retirement Account(IRA)",
};

export const MONTHS = [
  { value: 1, text: "January" },
  { value: 2, text: "February" },
  { value: 3, text: "March" },
  { value: 4, text: "April" },
  { value: 5, text: "May" },
  { value: 6, text: "June" },
  { value: 7, text: "July" },
  { value: 8, text: "August" },
  { value: 9, text: "September" },
  { value: 10, text: "October" },
  { value: 11, text: "November" },
  { value: 12, text: "December" },
];

export const EMPLOYMENT_AFFILIATIONS = [
  { value: "bankTrustOrInsuranceCompany", label: "A bank trust or insurance company" },
  { value: "investmentAdviser", label: "An investment adviser" },
  { value: "stockExchange", label: "A stock exchange" },
];

export const EMPLOYMENT_ROLES = [
  { value: "directorOrOfficerPublicCompany", label: "Director or officer of a publicly traded company" },
  { value: "shareholderPublicCompany", label: "10% or greater shareholder of a publicly traded company" },
  { value: "controllingPersonPublicCompany", label: "Other controlling person of a publicly traded company" },
];

export const ACCREDITATION_METHODS = [
  { value: "income", label: "Income over $200,000 (or $300,000 with spouse)", description: "Requires tax documents from the past two calendar years exhibiting your average annual income." },
  { value: "netWorth", label: "Net worth over $1 million", description: "Requires asset statements and a consumer credit report dated within the past 3 months exhibiting your net worth." },
  { value: "thirdPartyVerification", label: "Third-party verification", description: "Requires a letter from a third party such as a lawyer, accountant, broker, or financial adviser verifying your income or net worth." },
];

export const DOCUMENT_UPLOAD_TEXT = {
  income: {
    p: "Submitted documents must exhibit average annual income greater than $200,000, or $300,000 with a spouse, from the past two calendar years.",
    p2: "",
    ul: "Accepted tax documents include, but are not restricted to:",
    li: ["Federal filed tax returns* (e.g., IRS Form 1040)", "IRS Form W-2s provided to you by your employer (and/or, if applicable, provided to your spouse by your spouse’s employer)", "IRS Form 1099(s) for interest or dividend income", "Schedule K-1s to IRS Form 1065"],
    i: "*Tax returns must exhibit income from each of the last two years at least $200,000 of income individually or $300,000 with a spouse. If you wish to use individual income but have jointly filed a Form 1040 with your spouse, please provide documentation denoting what portion of the jointly filed income is apportioned to you alone (e.g. W-2). ",
  },
  netWorth: {
    p: "Submitted documents must be dated within the past 3 months and demonstrate that you, either individually or jointly with your spouse, have a net worth of at least $1 million.",
    p2: "Additionally, you are required to provide a consumer credit report no older than 3 months from at least one nationwide consumer reporting agency (e.g. Experian, TransUnion, Equifax) summarizing your current liabilities.*",
    ul: "Accepted asset statements include, but are not restricted to:",
    li: ["Bank statements or other statements of securities holdings", "Certificates of deposit", "Tax assessments not relating to your primary residence", "Independent third-party appraisal reports not relating to your primary residence "],
    i: "*If you choose to not provide a consumer credit report yourself, we will require your authorization to conduct a soft credit inquiry. This soft pull of your personal credit will not impact your credit score or show up on reports obtained by creditors. A member of our investor success team will reach out to you for authorization.",
  },
  thirdPartyVerification: {
    p: "A letter from a third-party Investor Representative such as a lawyer, accountant, broker, or financial adviser verifying your income or net worth.",
    p2: "If you have a letter from your Investor Representative dated from within the past 3 months, you can provide a copy in place of using the provided Docusign form.",
    ul: "Your Investor Representative must be one of the following entities or persons:",
    li: ["A broker-dealer registered with the SEC", "An investment adviser registered with the SEC", "A certified public accountant who is duly registered and in good standing under the laws of the place of his or her residence or principal office", "A licensed attorney who is in good standing under the laws of the jurisdictions in which he or she is admitted to practice law"],
    i: "",
  },
};

export const INVESTMENT_VEHICLES = [
  { value: "fixedIncome", label: "Fixed Income" },
  { value: "futures", label: "Futures and/or options" },
  { value: "hedgeFund", label: "Hedge funds" },
  { value: "p2p", label: "Marketplace/P2P investments" },
  { value: "mutualFund", label: "Mutual funds" },
  { value: "privateEquityFund", label: "Private equity funds" },
  { value: "reit", label: "Real estate/REITS" },
  { value: "stock", label: "Stocks" },
];

export const INVESTMENT_GOALS = [
  { value: "portfolioDiversity", label: "Diversify my portfolio" },
  { value: "protectCapital", label: "Protect capital" },
  { value: "smallBusinessSupport", label: "Support small businesses/ increase investing footprint" },
  { value: "liquidInvestment", label: "A liquid investment vehicle" },
  { value: "highRisk", label: "Take on risk for greater potential returns" },
];

export const NON_TEXT_FIELDS = [
  "investorAccountType",
  "exceedsAssetsThreshold",
  "exceedsIncomeThreshold",
  "employmentAffiliation",
  "employmentRole",
  "investmentGoals",
  "investmentVehicles",
  "financiallySuitable",
  "residentialState",
  "residentialMailingMatch",
  "idFront",
  "idBack",
  "bankStatements",
  "assetStatements",
  "consumerReports",
  "taxDocsLastYear",
  "taxDocsYearBeforeLast",
  "investorRepresentativeLetters",
];

export const OPTIONAL_FIELDS = [
  "callBetween",
  "idBack",
  "consumerReports",
  "investorRepresentativeLetters",
];
