import React from "react";
import { connect } from "react-redux";
import {
  applyFilter,
  flipCard,
  nextFilter,
  prevFilter
} from "../actions";
import FilterCard from "./FilterCard";
import FlippableCard from "./FlippableCard";
import TotalEarnedDetails from "./TotalEarnedDetails";
import { TOTAL_EARNED_ID, RADIUS_ASSET_PATH } from "../constants";

const mapStateToProps = (state, { limit }) => {
  return {
    filterOptions: state.totalEarned.filterOptions,
    flipped: state.totalEarned.flipped,
    limit: limit,
    loading: state.totalEarned.loading,
    offset: state.totalEarned.offset,
    selectedFilter: state.totalEarned.selectedFilter
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFlip: () => dispatch(flipCard(TOTAL_EARNED_ID)),
    onFilter: code => dispatch(applyFilter(code)),
    onNext: () => dispatch(nextFilter()),
    onPrev: () => dispatch(prevFilter())
  };
};

const View = ({
  filterOptions,
  flipped,
  limit,
  loading,
  offset,
  onFilter,
  onFlip,
  onNext,
  onPrev,
  selectedFilter
}) => {
  return (
    <FlippableCard cardName="total-earned" flipped={flipped}>
      <div side="front">
        <FilterCard
          limit={limit}
          loading={loading}
          offset={offset}
          onContentClick={onFlip}
          onSelect={onFilter}
          onNext={onNext}
          onPrev={onPrev}
          options={filterOptions}
          selected={selectedFilter.timeframe}>
          <div className="total-earned summary">
            <div className="paragraph-heading u-mb-none">
              <span className="paragraph-heading--fixed-large">$</span>
              <span className={`${selectedFilter.total_earned.dollars.length > 10 ? "paragraph-heading--3x-large" : "paragraph-heading--4x-large"}`}>{selectedFilter.total_earned.dollars}</span>
              <span className="paragraph-heading--fixed-large">{`.${selectedFilter.total_earned.cents}`}</span>
            </div>

            <div className="sub-text__block u-text-center">
              TOTAL EARNED
              <span className="info">
                <svg className="svg-icon cta-primary__icon svg-icon--info">
                  <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`} />
                </svg>
                <span className="pop">Total amount of interest payments and late fees, net of servicing fees</span>
              </span>
            </div>
          </div>
        </FilterCard>
      </div>
      <div side="back">
        <FilterCard
          limit={limit}
          loading={loading}
          offset={offset}
          onContentClick={onFlip}
          onSelect={onFilter}
          onNext={onNext}
          onPrev={onPrev}
          options={filterOptions}
          selected={selectedFilter.timeframe}>
          <TotalEarnedDetails filterData={selectedFilter} />
        </FilterCard>
      </div>
    </FlippableCard>
  );
};

const TotalEarnedCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(View);

export default TotalEarnedCard;
