import React from "react";
import { connect } from "react-redux";
import { createField } from "../containers/Field";
import { createStep } from "../containers/Step";
import SelectBetween from "../components/SelectBetween";
import Submit from "../components/Submit";
import TextField from "../components/TextField";

const STEP_NAME = "contactInfo";

const Field = createField(STEP_NAME);

const startTime = [
  { value: "",   text: "Select time" },
  { value: "8",  text: "8:00 AM PT" },
  { value: "9",  text: "9:00 AM PT" },
  { value: "10", text: "10:00 AM PT" },
  { value: "11", text: "11:00 AM PT" },
  { value: "12", text: "12:00 PM PT" },
  { value: "13", text: "1:00 PM PT" },
  { value: "14", text: "2:00 PM PT" },
  { value: "15", text: "3:00 PM PT" },
  { value: "16", text: "4:00 PM PT" },
];

const endTime = [
  { value: "",   text: "Select time" },
  { value: "9",  text: "9:00 AM PT" },
  { value: "10", text: "10:00 AM PT" },
  { value: "11", text: "11:00 AM PT" },
  { value: "12", text: "12:00 PM PT" },
  { value: "13", text: "1:00 PM PT" },
  { value: "14", text: "2:00 PM PT" },
  { value: "15", text: "3:00 PM PT" },
  { value: "16", text: "4:00 PM PT" },
  { value: "17", text: "5:00 PM PT" },
];

const View = ({
  handleSubmit,
  step,
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-mb-medium">
          Congratulations
        </h2>

        <p className="lead-paragraph lead-paragraph--x-large u-mb-medium">
          You qualify as an accredited investor.
        </p>

        <p className="u-mb-small">
          Please provide your preferred phone number to complete your account. If necessary, a member of our investor success team will contact you.
        </p>

        <form onSubmit={handleSubmit()}>
          <Field
            name="phoneNumber"
            component={TextField}
            mask="(999) 999-9999"
            validates={["phoneFormat"]}
          />

          <Field
            name="callBetween"
            component={SelectBetween}
            startVal={startTime}
            endVal={endTime}
            validates={["callBetween"]} />

          <Submit step={step} />
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = () => {
  return {
    step: STEP_NAME
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
