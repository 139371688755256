import React from "react";
import { connect } from "react-redux";
import { createStep } from "../containers/Step";

const STEP_NAME = "verifyAccount";

const View = ({
  email,
  firstName,
  onboardingId,
}) => {
  return (
    <section className="step-card u-text-center">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-text-center u-mb-none">
          Verify your email
        </h2>

        <div className="email-image"></div>

        <p>Thanks for opening an account, {firstName}!</p>

        <p>We've sent an email to: <b>{email}</b></p>

        <p>Please click the link in the email to verify your account.</p>
      </div>
    </section>
  );
};

const mapStateToProps = ({ profile, onboardingId }) => {
  const { firstName, email } = profile;

  return {
    firstName: firstName,
    email: email,
    onboardingId: onboardingId
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
