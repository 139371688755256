import React from 'react';

const LateBreakdown = ({lateHoldings}) => {
  return(
    <div className='late-holdings-breakdown'>
      <div className='panel-header'>BREAKDOWN OF LATE HOLDINGS</div>
      <table>
        <tbody>
          {lateHoldings.map((lateHolding, i) => (
            <tr
              className={`late-${i}`}
              key={lateHolding.bucketRange}>
              <td className='bucket'>
                {lateHolding.bucketRange} <span className='units'>days</span>
              </td>
              <td className='amount'>{lateHolding.amount}</td>
              <td className='percentage'>{lateHolding.percentOfWhole}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LateBreakdown;
