import React from "react";
import { connect } from "react-redux";
import TransferForm from "./components/TransferForm";
import UpcomingTransfers from "./components/UpcomingTransfers";

const RecurringTransfers = ({ selectedTab }) => (
  <div className="transfers u-grid-161 u-theme-contrast u-pv-x-small">
    <div className="tabs has-js">
      <ol className="tablist">
        <li className="tablist__item">
          <a className={`${selectedTab === "make_a_transfer" ? "is-selected" : ""} tablist__link`} href="/transfers/make_a_transfer">Make a transfer</a>
        </li>
        <li className="tablist__item">
          <a className={`${selectedTab === "upcoming_transfers" ? "is-selected" : ""} tablist__link`} href="/transfers/upcoming_transfers">Upcoming transfers</a>
        </li>
      </ol>
      <div className="tabpanels">
        <div className={`${selectedTab === "make_a_transfer" ? "" : "is-hidden"} tabpanels__item`}>
          <TransferForm />
        </div>
        <div className={`${selectedTab === "upcoming_transfers" ? "" : "is-hidden"} tabpanels__item`}>
          <UpcomingTransfers />
        </div>
      </div>
    </div>
  </div>
);

export default connect(state => ({
  selectedTab: state.transferValues.selectedTab,
}))(RecurringTransfers);
