import _ from "lodash";

import { TOTAL_EARNED_ID } from "../constants";
import { flipBool } from "../../common/utils/common";

const standardFilters = {
  all_time: "All time",
  ytd: "YTD",
  mtd: "MTD",
};

export function flipCard(state, action) {
  if (action.cardId === TOTAL_EARNED_ID) {
    return _.assign({}, state, { flipped: flipBool(state.flipped) });
  }
  return state;
}

export function expandCard(state, action) {
  if (action.cardId === TOTAL_EARNED_ID) {
    return _.assign({}, state, { expanded: flipBool(state.expanded) });
  }
  return state;
}

export function applyFilter(state, action) {
  return _.assign(
    {},
    state,
    { selectedFilter: _.find(state.data, i => i.timeframe === action.filterCode) },
  );
}

export const nextFilter = (state, action) => {
  if ((state.offset + action.limit) >= state.filterOptions.length) {
    return state;
  }
  return _.assign({ ...state }, { offset: state.offset + 1 });
};

export const prevFilter = (state) => {
  if (state.offset === 0) {
    return state;
  }
  return _.assign({ ...state }, { offset: state.offset - 1 });
};

export const timeframeToName = timeframe => _.get(standardFilters, timeframe, timeframe);

export const summaryToFilterOption = summary => ({
  code: summary.timeframe,
  name: timeframeToName(summary.timeframe),
});

export const init = (_state, action) => {
  const earningsData = action.data.earnings_summaries;
  const filterOptions = _.map(earningsData, summaryToFilterOption);
  const selectedFilter = _.first(earningsData);

  return {
    data: earningsData,
    expanded: false,
    filterOptions,
    flipped: false,
    offset: 0,
    selectedFilter,
  };
};

export default function totalEarnedReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return init(state, action);
    case "FLIP_CARD":
      return flipCard(state, action);
    case "EXPAND_CARD":
      return expandCard(state, action);
    case "APPLY_FILTER":
      return applyFilter(state, action);
    case "NEXT_FILTER":
      return nextFilter(state, action);
    case "PREV_FILTER":
      return prevFilter(state, action);
    default:
      return state;
  }
}
