import React from "react";

const CheckBox = ({
  checked,
  label,
  name,
  value,
  onChange
}) => {
  return (
    <div className="form-checkbox--custom-wrapper">
      <label className="form-checkbox">
        <input className="form-checkbox__input" type="checkbox" name={value} checked={checked} onChange={onChange} />
        <span className="form-checkbox__icon"></span>
        {label}
      </label>
    </div>
  );
};

CheckBox.defaultProps = { name: "", value: "", label: "", checked: false };

export default CheckBox;
