import React from "react";

import ValidatedField from "./ValidatedField";

import _ from "lodash";

const CheckboxGroup = ({
  children,
  errors,
  handleUpdate,
  ifStatus,
  label,
  name,
  value
}) => {
  const isChecked = ({ itemName }) => {
    return _.includes(value, itemName);
  };

  const handleChange = ({ itemName, onChange }) => {
    return () => {
      let nextValue;

      if (isChecked({ itemName })) {
        nextValue = value.filter(x => x !== itemName); // uncheck
      } else {
        nextValue = [...value, itemName]; // add to checked
      }

      // call back after (un)check
      handleUpdate(name, onChange(nextValue, itemName));
    };
  };

  const prepareBoxes = (children) => {
    return React.Children.map(children, child => {
      const itemName = child.props.name;
      const onChange = child.props.onChange || _.identity;

      return React.cloneElement(child, {
        checked: isChecked({ itemName }),
        onChange: handleChange({ itemName, onChange })
      });
    });
  };

  // clicking labels with auto focus nested label content
  // add a hidden input element so clicking a label will not auto click the first checkbox in the group

  return(
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>    
      <input className="hidden" />
      {prepareBoxes(children)}
    </ValidatedField>
  );
};

CheckboxGroup.defaultProps = { name: "", value: [] };

export default CheckboxGroup;
