import _ from "lodash";
import { errorNotifier, contextFromState } from "./utils/errors";

//
// Gets called from window.onerror with the error
// object. We then notify HB with the error and
// update the store with the error state.
//
export function uncaughtError({ error }) {
  return (dispatch, getState) => {
    const state = getState();

    const notifier = errorNotifier(
      state.config.honeybadger_key,
      state.config.environment,
    );

    if (error) {
      notifier.notify(error, { context: contextFromState(state) });
    }

    dispatch({
      type: "UNCAUGHT_ERROR",
      name: _.get(error, "name"),
      message: _.get(error, "message"),
      stack: _.get(error, "stack"),
    });
  };
}

export function screenResize(mediaSizes, width) {
  return {
    type: "SCREEN_RESIZE",
    mediaSizes,
    width,
  };
}

export function windowScroll(yPos) {
  return {
    type: "WINDOW_SCROLL",
    yPos,
  };
}
