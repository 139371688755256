import React from 'react';
import { connect } from 'react-redux';
import Icon from "radius/dist/m2dm/react/icon";

import { renderIf } from '../../common/utils/matchers';
import { selectDefaulted } from '../reducers/principalOutstandingReducer';

const mapStateToProps = (state) => {
  return {
    netRecoveries: state.principalOutstanding.data.netRecoveries,
    chargeOffs: state.principalOutstanding.data.chargeOffs,
    defaulted: selectDefaulted(state)
  };
}

const MobileDefaultedBreakdown = ({
  netRecoveries,
  chargeOffs,
  defaulted,
  limitedViewEnabled,
  isExpanded,
  toggleExpand
}) => {
  const expandedView = isExpanded ? 'expanded' : ''
  const limitedView = limitedViewEnabled ? 'limited-view-enabled' : ''

  return(
    <div className='trg'>
      <div onClick={toggleExpand} className={`defaulted tr ${expandedView} ${limitedView}`}>
        <span className='td label'>
          <i />
          Defaulted
        </span>
        <span className='td amount'>{defaulted.amount}</span>
        <span className='td percentage'>{defaulted.percentOfWhole}</span>
        <span>
          <Icon
            name={`${isExpanded ? "minus-circle-outline" : "plus-circle-outline"}`}
            extraClasses="expand-collapse svg-icon--action svg-icon--large"
          />
        </span>
      </div>
      {renderIf(isExpanded)(
        <div className='defaulted-breakdown'>
          <table>
            <tbody>
              <tr>
                <td>Net Recoveries</td>
                <td className='defaulted-amount'>{netRecoveries}</td>
              </tr>
              <tr>
                <td>Charge-Offs</td>
                <td className='defaulted-amount'>{chargeOffs}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MobileDefaultedBreakdown);
