import React from 'react';

import { renderIf } from '../../common/utils/matchers';
import { columnNames } from '../constants';
import { activeChecker } from '../utils/sorting';

const SortBar = ({sorting, toggleSortOptions, toggleSortColumn}) => {
  const optionsHidden  = renderIf(!sorting.sortOptionsVisible);
  const optionsVisible = renderIf(sorting.sortOptionsVisible);
  const activeColumn   = activeChecker(sorting.activeColumn);

  return (
    <div className='mobile-sort-bar-container'>
      <div className='mobile-sort-bar'>
        <div className='mobile-sort-bar-left' onClick={() => toggleSortColumn(sorting.activeColumn)}>
          <span>{columnNames[sorting.activeColumn]}</span>
          <div className={'sort-arrow ' + sorting.direction}></div>
        </div>
        <div className={'mobile-sort-bar-right ' + (sorting.sortOptionsVisible ? 'open' : '')} onClick={toggleSortOptions}>
          {optionsHidden(
            <div>
              <span>More sort options</span>
              <div className='arrow-dropdown' />
            </div>
          )}
          {optionsVisible(
            <div>
              <span>Fewer sort options</span>
              <div className='arrow-up' />
            </div>
          )}
        </div>
      </div>

      {optionsVisible(
        <div className='mobile-sort-options'>
          <ul>
            {Object.keys(columnNames).map((key) => (
              <li key={key}>
                {renderIf(!activeColumn(key))(
                  <a onClick={() => toggleSortColumn(key)}>{columnNames[key]}</a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SortBar;
