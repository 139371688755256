import camelcaseKeysDeep from "camelcase-keys-deep";
import _ from "lodash";
import { flipBool } from "../../common/utils/common";
import { DEFAULTED_ID, LATE_ID } from "../constants";

export const selectDefaulted = state => _.find(state.principalOutstanding.data.principalTypes, { displayName: "defaulted" });
export const selectLate = state => _.find(state.principalOutstanding.data.principalTypes, { displayName: "late" });

export function expandCard(state, action) {
  if (action.cardId === LATE_ID) {
    return _.assign({}, state, { lateExpanded: flipBool(state.lateExpanded) });
  } if (action.cardId === DEFAULTED_ID) {
    return _.assign({}, state, { defaultedExpanded: flipBool(state.defaultedExpanded) });
  }
  return state;
}

export default function principalOutstandingReducer(state = {
  slidePanelItem: null,
  lateExpanded: false,
  defaultedExpanded: false,
}, action) {
  switch (action.type) {
    case "INIT":
      return Object.assign({}, state, { data: camelcaseKeysDeep(action.data.summary) });
    case "SLIDE_LEGEND_ITEM":
      return Object.assign({}, state, {
        slidePanelItem: state.slidePanelItem === action.item ? null : action.item,
      });
    case "EXPAND_CARD":
      return expandCard(state, action);
    default:
      return state;
  }
}
