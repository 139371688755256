import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../../common/utils/matchers";
import { toggleExpand } from "../actions";
import DonutChart from "./DonutChart";
import PortfolioBalanceLegend from "./PortfolioBalanceLegend";

const mapStateToProps = ({portfolioBalance, config}) => {
  const {
    portfolio_balance,
    outstanding_principal,
    available_funds,
    total_pending,
    pending_repayments,
  } = portfolioBalance.generalAttributes

  return {
    config: config,
    chartAttributes: portfolioBalance.chartAttributes,
    portfolioBalance: portfolio_balance,
    outstandingPrincipal: outstanding_principal,
    availableFunds: available_funds,
    totalPending: total_pending,
    pendingRepayments: pending_repayments,
    pendingRepaymentsVisible: portfolioBalance.pendingRepaymentsVisible,
    flipped: portfolioBalance.flipped,
    expanded: portfolioBalance.expanded
  }
}

const PortfolioBalanceMobileCard = ({
  config,
  chartAttributes,
  portfolioBalance,
  outstandingPrincipal,
  availableFunds,
  totalPending,
  pendingRepayments,
  pendingRepaymentsVisible,
  isExpanded,
}) => (
  <div className="mobile-card-container">
    <DonutChart
      chartAttributes={chartAttributes}
      portfolioBalance={portfolioBalance} />
    {renderIf(isExpanded)(
      <PortfolioBalanceLegend
        config={config}
        portfolioBalance={portfolioBalance}
        availableFunds={availableFunds}
        totalPending={totalPending}
        pendingRepayments={pendingRepayments}
        pendingRepaymentsVisible={pendingRepaymentsVisible}
        outstandingPrincipal={outstandingPrincipal}
      />
    )}
  </div>
);

export default connect(mapStateToProps)(PortfolioBalanceMobileCard);
