import React from "react";
import TransfersTable from "./TransfersTable";

const UpcomingTransfers = () => (
  <div className="transfer-container u-pv-x-large">
    <div className="u-page-grid">
      <TransfersTable name="Processing" />
      <TransfersTable name="Scheduled" />
    </div>
  </div>
);

export default UpcomingTransfers;
