import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import Form from "../containers/Form";
import { MODE } from "../constants";
import _ from "lodash";

const Container = ({ unlessHidden, children }) => unlessHidden(children);

export function unlessHidden(stepModes, step) {
  return renderIf(
    (!_.isUndefined(stepModes[step]) && stepModes[step] !== MODE.HIDDEN)
  );
}

export function mapStateToProps(state, ownProps) {
  const { stepModes } = state;
  const { step }  = ownProps;

  return {
    unlessHidden: unlessHidden(stepModes, step)
  };
}

const Step = connect(mapStateToProps)(Container);

export function createStep(step) {
  return component => props => (
    <Step step={step}>
      <Form step={step} component={component} {...props} />
    </Step>
  );
}

export default Step;
