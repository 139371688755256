import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Icon from "radius/dist/m2dm/react/icon";
import { DAYS_OF_WEEK } from "../constants";
import { ordinalizeNumber } from "../helpers";
import { showModal, submitTransfer } from "../actions";

const ReviewAndConfirmModal = ({
  frequency,
  dayOfWeek,
  dateOfMonth,
  showReviewAndConfirmModal,
  showModal,
  submitTransfer,
  recurring,
  bankName,
  lastFourBankAccountNumber,
  amount,
  transferType,
}) => {
  const dayOfWeekObject = _.find(DAYS_OF_WEEK, weekDay => weekDay.value === dayOfWeek);
  const day = frequency === "weekly" ? (dayOfWeekObject && dayOfWeekObject.displayText) : (dateOfMonth && ordinalizeNumber(dateOfMonth));
  const frequencyDisplay = frequency && frequency.slice(0, -2);
  const fromAccount = transferType === "deposit" ? `${bankName} account ending in ${lastFourBankAccountNumber}` : "Funding Circle account";
  const toAccount = transferType === "withdrawal" ? `${bankName} account ending in ${lastFourBankAccountNumber}` : "Funding Circle account";
  const decimalAmount = parseFloat(amount).toFixed(2);

  return (
    <div className={`modal review-and-confim-modal ${showReviewAndConfirmModal ? "show" : ""}`}>
      <div className="modal__overlay" tabIndex="-1" onClick={() => showModal("showReviewAndConfirmModal", false)} />
      <div className="modal__box modal__box--no-scroll" role="dialog" aria-labelledby="dialog-title">
        <span className="modal__header" onClick={() => showModal("showReviewAndConfirmModal", false)}>
          <Icon
            name="cross"
            modifiers={["small"]}
          />
        </span>
        <div className="modal__content u-grid-141">
          <h2 className="section-heading section-heading--x-small">Review and confirm transfer</h2>
          {recurring === "true"
            && (
              <div className="u-mb-x-small">
                On the
                <strong>
                  {` ${day} of every ${frequencyDisplay} `}
                </strong>
              </div>
            )
          }
          <div />
          <p className="four-x-large u-mb-x-small">
            {`$${parseFloat(decimalAmount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}
          </p>
          <p className="u-mb-large">
            {` will be transferred from your ${fromAccount} to your ${toAccount}`}
          </p>
          <button className="cta-primary u-mb-medium" onClick={submitTransfer} type="button" data-test="confirm-transfer">Confirm transfer</button>
        </div>
      </div>
    </div>
  );
};

ReviewAndConfirmModal.defaultProps = {
  showReviewAndConfirmModal: false,
  dayOfWeek: "",
  dateOfMonth: "",
  recurring: false,
  lastFourBankAccountNumber: "",
  bankName: "",
  amount: "",
  frequency: "",
  transferType: "",
};

export default connect(state => ({ ...state.transferValues }),
  { showModal, submitTransfer })(ReviewAndConfirmModal);
