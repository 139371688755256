import React from "react";
import { connect } from "react-redux";
import { ACCOUNT_TYPE_LABELS, INVESTOR_RELATIONS_EMAIL, FAQ } from "../constants";

const View = ({
  investorAccountType
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-mb-none">Thanks for your interest in Funding Circle</h2>

        <p className="u-mb-large"> A member of our investor success team will be in contact
            with you shortly to discus the onboarding process
            for a(n) { ACCOUNT_TYPE_LABELS[investorAccountType] }.</p>

        <p> In the meantime, you can familiarize yourself with our
            investing program on our
            <a href={FAQ}> FAQ</a>.</p>

        <p>
          <h4 className="u-mb-none">Email:</h4>
          <a href={`mailto:${INVESTOR_RELATIONS_EMAIL}`}>{INVESTOR_RELATIONS_EMAIL}</a>
        </p>
	
      </div>
    </section>
  );
};

const mapStateToProps = ({ stepModes, profile }) => {
  const { investorAccountType } = profile;
  return {
    investorAccountType: investorAccountType
  };
};

const Step = connect(mapStateToProps)(View);

export default Step;
