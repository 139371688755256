//
// Helpers for conditionally rendering based on media queries.
//

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

//
// For a given view:
//
// {
//   lte: {
//     tablet: {
//       small: true,
//       medium: true,
//       large: false
//     },
//     desktop: {
//       small: false,
//       medium: false,
//       large: false
//     }
//   }
// }
//
// Comparator node, and media path,
// returns the following:
//
// "tablet" => true
// "desktop" => false
// "tablet.large" => false
// "tablet.medium" => true
//
export function matchesMediaPath(comparator, view, path) {
  const p = _.toPath(path);

  //
  // For a single device type in the path, we
  // check if any of the sizes are true.
  //
  if (p.length === 1) {
    const device = p[0];
    const options = _.get(view, [comparator, device], {});

    const matches = Object.keys(options).filter(size => options[size] === true);

    return matches.length > 0;
  }

  //
  // Otherwise we check the full path.
  //
  if (p.length === 2) {
    return _.get(view, _.concat([comparator], p), false);
  }

  //
  // Fall thru with false.
  //
  return false;
}

//
// Renders a component or element if the predicate is truthy.
//
// Example:
//
// <div>
//   {renderIf(true)(
//     <p>Heyo!</p>
//   )}
// </div>
//
export function renderIf(predicate) {
  return (elemOrThunk) => {
    if (predicate) {
      return _.isFunction(elemOrThunk) ? elemOrThunk() : elemOrThunk;
    }
    return null;
  };
}

export function renderIfMedia(comparator) {
  return view => path => renderIf(matchesMediaPath(comparator, view, path));
}

//
// Checks if the current media is in range.
//
// Example:
//
// const myRange = mediaRange(view)('tablet.small', 'desktop.small');
//
// <div>
//   {myRange(
//     <p>I'm in range!</p>
//   )}
// </div>
//
export const mediaRange = view => (from, to) => {
  const gte = matchesMediaPath("gte", view, from);
  const lte = matchesMediaPath("lte", view, to);

  return renderIf(gte && lte);
};

//
// Example:
//
// <div>
//   {mediaEq(view)('tablet')(
//     <p>Tablets only!</p>
//   )}
// </div>
//
// <div>
//   {mediaEq(view)('tablet.small')(
//     <p>Tiny tablets over here!</p>
//   )}
// </div>
//
export const mediaLte = renderIfMedia("lte");
export const mediaGte = renderIfMedia("gte");
export const mediaEq = renderIfMedia("eq");

//
// Matches if the scroll Y position is >= a
// specified size.
//
export function scrollGte(view) {
  return val => renderIf(view.scrollTop >= val);
}

//
// Helper for building CSS media selectors.
//
export function mediaClass(view) {
  return `${view.mq.device} ${view.mq.device}-${view.mq.size}`;
}

//
// Takes any number of view matchers and returns the first
// non-matching matcher, otherwise returns the last remaining
// matcher.
//
export function composeMatchers(...matchers) {
  for (let i = 0; matchers.length; i += 1) {
    const current = matchers[i];
    const next = matchers[i + 1];

    if (!current(true)) { return current; }
    if (!next) { return current; }
  }

  return null;
}

const WithViewMatchers = ({ WrappedComponent, ...rest }) => {
  const { view } = rest;

  return (
    <WrappedComponent
      mediaLte={mediaLte(view)}
      mediaGte={mediaGte(view)}
      mediaEq={mediaEq(view)}
      mediaRange={mediaRange(view)}
      mediaClass={mediaClass(view)}
      scrollGte={scrollGte(view)}
      {...rest}
    />
  );
};

WithViewMatchers.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
};

//
// Injects media matchers into the wrapped component's props.
//
export const withViewMatchers = WrappedComponent => props => (
  <WithViewMatchers
    WrappedComponent={WrappedComponent}
    {...props}
  />
);
