/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import TransferType from "./TransferType";
import Amount from "./Amount";
import Frequency from "./Frequency";
import Recurrence from "./Recurrence";
import DaysOfWeek from "./DaysOfWeek";
import DatesOfMonth from "./DatesOfMonth";
import TransferStartDate from "./TransferStartDate";
import { updateFieldsAndValues } from "../actions";
import { renderIf } from "../../common/utils/matchers";
import NoFunds from "./NoFunds";

const handleTransfer = (values, dispatch, props) => {
  const fieldsAndValues = [{
    field: "showReviewAndConfirmModal",
    value: true,
  }];
  props.updateFieldsAndValues({ fieldsAndValues, omit: [] });
};

const TransferForm = ({
  showFrequency,
  showDaysOfWeek,
  showDatesOfMonth,
  showNoFunds,
  handleSubmit,
  submitText,
  showRestOfTheTransferForm,
  showTransferType,
  isTransferStartDateDisabled,
}) => (
  <div className="transfer-container transfer-container--narrow u-pv-5x-large">
    <div className="u-grid-141">
      {renderIf(showTransferType)(<TransferType />)}
      {showNoFunds && <NoFunds />}
      {showRestOfTheTransferForm && (
        <div>
          <Amount />
          <Recurrence />
          {showFrequency && <Frequency />}
          {showDaysOfWeek && <DaysOfWeek />}
          {showDatesOfMonth && <DatesOfMonth />}
          <TransferStartDate disabled={isTransferStartDateDisabled} />
          <button
            type="button"
            className="cta-primary u-grid-1 u-mt-large"
            onClick={handleSubmit(handleTransfer)}
            data-test="submit-transfer-form"
          >
            {submitText}
          </button>
        </div>
      )}
    </div>
  </div>
);

const ReduxTransferForm = reduxForm({
  form: "makeATransfer",
})(TransferForm);

const mapStateToProps = (state) => {
  const {
    recurring, frequency, availableFunds,
    transferType, hasBankAccount, submitText,
    showRestOfTheTransferForm, showTransferType,
    transferStartDate,
  } = state.transferValues;

  const showFrequency = recurring === "true";
  const showDaysOfWeek = frequency === "weekly" && showFrequency;
  const showDatesOfMonth = frequency === "monthly" && showFrequency;
  const showNoFunds = transferType === "withdrawal" && hasBankAccount && availableFunds <= 0;
  const isTransferStartDateDisabled = recurring === "true";
  return ({
    showFrequency,
    showDaysOfWeek,
    showDatesOfMonth,
    showNoFunds,
    isTransferStartDateDisabled,
    submitText,
    showRestOfTheTransferForm,
    showTransferType,
    initialValues: { transferStartDate },
  });
};

export default connect(mapStateToProps, { updateFieldsAndValues })(ReduxTransferForm);
