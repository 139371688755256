import React from 'react';
import FilterGroup from './FilterGroup';
import FilterBox from './FilterBox';
import { renderIf } from '../../common/utils/matchers';
import { gaEvent } from "../../common/utils/common";

const Filters = ({
  applyFilters,
  clearFilters,
  filtering: {
    groups,
    canClear,
    canApply,
    filterBoxOpen,
    filtersApplied
  },
  toggleFilter,
  toggleFilterBox
}) => {
  const handleApply = () => {
    gaEvent("my-portfolio", "apply-filters", "filters");
    applyFilters();
  }

  return (
    <div className='filters-container'>
      {Object.keys(groups).map(group => (
        <FilterGroup
          group={groups[group]}
          toggleFilter={toggleFilter}
          key={group} />
      ))}
      <div className='filters-submit'>
        <button
          className={'filter-apply-button ' + (canApply ? 'active' : 'inactive')}
          onClick={handleApply}>
          Apply filters
        </button>
      </div>
      <div className='mobile-filter-button-container'>
        <button
          className='mobile-filter-button'
          onClick={toggleFilterBox}>
          Filters
        </button>
      </div>
      <FilterBox
        groups={groups}
        toggleFilter={toggleFilter}
        applyFilters={applyFilters}
        canApply={canApply}
        toggleFilterBox={toggleFilterBox}
        filterBoxOpen={filterBoxOpen} />
      {renderIf(_.some(filtersApplied))(
        <div className='applied-filters-container'>
          <span className='applied-filters-left'>Applied filters: <strong>{filtersApplied.join(', ')}</strong></span>
          <span
            className={'clear-applied ' + (canClear ? 'active' : 'inactive')}
            onClick={clearFilters}>
            Clear filters
          </span>
        </div>
      )}
    </div>
  );
}

export default Filters;
