import React from "react";
import { connect } from "react-redux";
import BankForm from "../components/BankForm";
import Modal from "../modals/Modal";
import { MODAL_NAME } from "../constants";

const BankFormContainer = ({ visible }) => (
  <Modal visible={visible}>
    <div className="modal__content u-grid-141">
      <h2 className="section-heading section-heading--x-small u-mb-none">Add bank account</h2>
      <p className="u-mb-large">
        Please provide details for the bank account you would like to transfer to and from.
        The details will be saved for future transfers.
      </p>
      <BankForm />
    </div>
  </Modal>
);

const mapStateToProps = state => ({
  visible: state.activeModal === MODAL_NAME.ADD_BANK_ACCOUNT,
});

export default connect(mapStateToProps)(BankFormContainer);
