import React from "react";
import PropTypes from "prop-types";

import { errorNotifier as notifier } from "../../common/utils/errors";

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    const { config } = this.props;

    this.state = {
      errorNotifier: notifier(config.honeybadger_key, config.environment),
      hasError: false,
    };
  }

  componentDidCatch(error) {
    const { uncaughtError } = this.props;
    const { errorNotifier } = this.state;

    errorNotifier.notify(error);
    uncaughtError({ error });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { error, WrappedComponent } = this.props;

    if (hasError) {
      return (
        <p className="uncaught-error">
          {error.message}
        </p>
      );
    }
    return (
      <WrappedComponent {...this.props} />
    );
  }
}

ErrorHandler.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  config: PropTypes.shape({
    honeybadger_key: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.shape({ message: PropTypes.string.isRequired }).isRequired,
  uncaughtError: PropTypes.func.isRequired,
};

const withErrorHandling = WrappedComponent => props => (
  <ErrorHandler WrappedComponent={WrappedComponent} {...props} />
);

export default withErrorHandling;
