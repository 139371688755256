import axios from "axios";
import * as Scroll from "react-scroll";
import { gaEvent } from "./helpers";

import { debouncedAction } from "../common/utils/common";

const { scroller } = Scroll;

export { uncaughtError } from "../common/actions";

export function init(opts) {
  return {
    type: "INIT",
    ...opts,
  };
}

export function updateField(step, field, value, errors) {
  return {
    type: "UPDATE_FIELD",
    step,
    field,
    value,
    errors,
  };
}

export function mountField(step, field, value, errors) {
  return {
    type: "MOUNT_FIELD",
    step,
    field,
    value,
    errors,
  };
}

export function unmountField(step, field) {
  return {
    type: "UNMOUNT_FIELD",
    step,
    field,
  };
}

export function focusField(step, field, value, errors) {
  return {
    type: "FOCUS_FIELD",
    step,
    field,
    value,
    errors,
  };
}

export function clearError() {
  return {
    type: "CLEAR_ERROR",
  };
}

export const setErrorTimer = debouncedAction(clearError, 5000);

export function blurField(step, field, value, errors) {
  return {
    type: "BLUR_FIELD",
    step,
    field,
    value,
    errors,
  };
}

export function attemptSubmit(step) {
  return {
    type: "ATTEMPT_SUBMIT",
    step,
  };
}

export function editStep(step) {
  return dispatch => Promise.resolve(
    dispatch({
      type: "EDIT_STEP",
      step,
    }),
  ).then(() => {
    scroller.scrollTo(step, {
      duration: 800,
      smooth: true,
      offset: -17,
    });
  });
}

const updateSuccess = dispatch => (response) => {
  dispatch({
    type: "UPDATE_SUCCESS",
    fields: response.data.props,
  });
};

const updateFailure = dispatch => (result) => {
  const { response } = result;
  const { errors } = response.data;

  dispatch({
    type: "UPDATE_FAILURE",
    errors,
  });
};

export function updateStep(step, field, value) {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_START",
      field,
    });

    const { authToken, onboardingId } = getState();
    const success = updateSuccess(dispatch);
    const failure = updateFailure(dispatch);

    return axios({
      data: { investor_onboarding: { [field]: value } },
      headers: { "X-CSRF-Token": authToken },
      method: "put",
      timeout: 30000,
      url: `${onboardingId}/${step}/update`,
    }).then(success, failure);
  };
}

const submitSuccess = (dispatch, step) => (response) => {
  const {
    currentStep,
    profile,
    ...rest
  } = response.data.props;

  if (step === "eligibility") {
    const value = rest.eligible ? 1 : 0;
    gaEvent("investor_onboarding", "submit", step, value);
  } else {
    gaEvent("investor_onboarding", "submit", step, 0);
  }

  dispatch({
    type: "SUBMIT_SUCCESS",
    nextProfile: profile,
    nextStep: currentStep,
    step,
    ...rest,
  });
  return currentStep;
};

const submitFailure = (dispatch, step) => (result) => {
  const { response } = result;
  let errors = {};

  switch (response && response.status) {
    case 422:
      ({ errors } = response.data);
      return dispatch({
        type: "SUBMIT_INVALID",
        step,
        errors,
      });
    case 500:
      errors = result;
      return dispatch({
        type: "SUBMIT_ERROR",
        step,
        errors,
      });
    default:
      errors = {};
      return dispatch({
        type: "UNCAUGHT_ERROR",
        step,
        errors,
      });
  }
};

export function submitStep(step) {
  return (dispatch, getState) => {
    dispatch({
      type: "SUBMIT_START",
      step,
    });

    const { authToken, profile, onboardingId } = getState();
    const success = submitSuccess(dispatch, step);
    const failure = submitFailure(dispatch, step);

    return axios({
      data: { investor_onboarding: profile },
      headers: { "X-CSRF-Token": authToken },
      method: "put",
      timeout: 30000,
      url: `${onboardingId}/${step}/submit`,
    })
      .then(success, failure)
      .then((currentStep) => {
        if (currentStep) {
          if (["eligibility", "contactInfo", "setPassword", "verifyAccount"].includes(currentStep)) {
            window.scrollTo(0, 0);
          } else {
            scroller.scrollTo(currentStep, {
              duration: 800,
              smooth: true,
              offset: -100,
            });
          }
        }
      });
  };
}
