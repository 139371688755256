import React, { Component } from "react";
import { connect } from "react-redux";
import { renderIf, ifStatus, errorsFor } from "../helpers";
import { validateFields } from "../validations";
import _ from "lodash";

import {
  blurField,
  focusField,
  mountField,
  updateField,
  unmountField,
  updateStep,
} from "../actions";

class Container extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const {
      handleMount,
      name,
      validator,
      value,
    } = this.props;

    handleMount(name, value, validator);
  }

  componentWillUnmount() {
    const {
      handleUnmount,
      name,
      value,
    } = this.props;

    handleUnmount(name, value);
  }

  render() {
    const {
      WrappedComponent,
      errors,
      handleBlur,
      handleFocus,
      handleUpdate,
      handleUpdateDocs,
      ifStatus,
      label,
      name,
      validator,
      value,
      ...rest
    } = this.props;

    return (
      <WrappedComponent
        label={label}
        errors={errors}
        handleBlur={handleBlur(validator)}
        handleFocus={handleFocus(validator)}
        handleUpdate={handleUpdate(validator)}
        handleUpdateDocs={handleUpdateDocs(validator)}
        ifStatus={ifStatus}
        name={name}
        value={value}
        {...rest}
      />
    );
  }
}

export function getEvtOrField(evtOrField, value) {
  if (_.isObject(evtOrField)) {
    return [evtOrField.target.name, evtOrField.target.value];
  } else {
    return [evtOrField, value];
  }
}

export function handleMount(dispatch, step) {
  return (field, value, validator) => {
    const errors = validator(value, field);

    dispatch(mountField(step, field, value, errors));
  };
}

export function handleUnmount(dispatch, step) {
  return (field, value) => {
    dispatch(unmountField(step, field));
  };
}

export function handleFocus(dispatch, step) {
  return (validator) => {
    return (evtOrField, value) => {
      const [field, val] = getEvtOrField(evtOrField, value);
      const errors = validator(val, field);

      dispatch(focusField(step, field, val, errors));
    };
  };
}

export function handleBlur(dispatch, step) {
  return (validator) => {
    return (evtOrField, value) => {
      const [field, val] = getEvtOrField(evtOrField, value);
      const errors = validator(val, field);

      dispatch(blurField(step, field, val, errors));
    };
  };
}

export function handleUpdate(dispatch, step) {
  return (validator) => {
    return (evtOrField, value) => {
      const [field, val] = getEvtOrField(evtOrField, value);
      const errors = validator(val, field);

      dispatch(updateField(step, field, val, errors));
    };
  };
}

export function handleUpdateDocs(dispatch, step) {
  return (validator) => {
    return (evtOrField, value) => {
      const [field, val] = getEvtOrField(evtOrField, value);
      const errors = validator(val, field);

      dispatch(updateStep(step, field, val));
      dispatch(updateField(step, field, val, errors));
    };
  };
}

export function mapStateToProps(state, ownProps) {
  const { fields, steps, profile, questions } = state;
  const { component, name, step, validates, label } = ownProps;

  return {
    label: label || questions[name],
    WrappedComponent: component,
    errors: errorsFor(fields, name),
    ifStatus: ifStatus(fields, name),
    validator: validateFields(validates, profile),
    value: profile[name],
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  const { step } = ownProps;

  return {
    handleBlur: handleBlur(dispatch, step),
    handleFocus: handleFocus(dispatch, step),
    handleMount: handleMount(dispatch, step),
    handleUnmount: handleUnmount(dispatch, step),
    handleUpdate: handleUpdate(dispatch, step),
    handleUpdateDocs: handleUpdateDocs(dispatch, step),
  };
}

const Field = connect(mapStateToProps, mapDispatchToProps)(Container);

export function createField(step) {
  return props => <Field step={step} {...props} />;
}

export default Field;
