/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React from "react";
import _ from "lodash";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { DAYS_OF_WEEK } from "../constants";
import { Error, Message } from "./Status";
import { required } from "../validations";
import { editDayOfWeek } from "../actions";

const Radio = ({ input, weekDay, formProps }) => {
  const {
    id, name, value, abbreviatedDisplayText, isDisabled, dataTestAttribute,
  } = weekDay;
  const { dayOfWeek, editDayOfWeek } = formProps;
  return (
    <div data-test={dataTestAttribute} className="form-stylised-radio radio-small u-mb-small">
      <input
        {...input}
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={value === dayOfWeek}
        onChange={() => { editDayOfWeek("dayOfWeek", value); }}
        disabled={isDisabled}
      />
      <label id={id} className="form-stylised-radio__label" htmlFor={id}>
        <span>{abbreviatedDisplayText}</span>
      </label>
    </div>
  );
};


const RadioList = ({
  label, formProps, input, meta: { touched, error },
}) => {
  const list = _.filter(DAYS_OF_WEEK, day => day.weekday);

  return (
    <div className={`form-element ${touched && error ? "is-error" : ""}`}>
      <label className="form-label form-element__label">
        <span>{label}</span>
        {touched && error && <Error />}
      </label>
      <div>
        {
          list.map(day => (
            <Radio
              key={day.id}
              weekDay={day}
              formProps={formProps}
              input={input}
            />
          ))
        }
      </div>
      {touched && error && <Message message={error} />}
    </div>
  );
};

const presence = required("Please select the day you want this transfer to occur");

const DaysOfWeek = (props) => {
  const { dayOfWeek, editDayOfWeek } = props;
  const formProps = { dayOfWeek, editDayOfWeek };
  return (
    <Field
      name="dayOfWeek"
      label="On this day:"
      formProps={formProps}
      validate={[presence]}
      component={RadioList}
    />
  );
};

const mapStateToProps = state => ({
  dayOfWeek: state.transferValues.dayOfWeek,
});

export default connect(mapStateToProps, { editDayOfWeek })(DaysOfWeek);
