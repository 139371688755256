import _ from "lodash";

import { screenResize, windowScroll, uncaughtError } from "./actions";
import { mediaSizes } from "./constants";
//
// For a given list of screen sizes, watch for media query breakpoint
// changes and broadcast the results.
//
export function observeMedia(dispatch) {
  const onResize = () => dispatch(screenResize(mediaSizes, window.innerWidth));

  onResize();

  mediaSizes.forEach((screen) => {
    //
    // Create a media query for the screen size.
    //
    const mediaQuery = window.matchMedia(
      `(min-width: ${screen.minWidth}px) and (max-width: ${screen.maxWidth}px)`,
    );

    //
    // Whenever the media query matches, broadcast the resize event.
    //
    mediaQuery.addListener((query) => {
      if (query.matches) onResize();
    });
  });
}

export function observeScroll(dispatch) {
  window.onscroll = _.debounce(() => {
    dispatch(windowScroll(window.scrollY));
  }, 200);
}

export function observeErrors(dispatch) {
  window.onerror = (msgOrEvent, url, line, col, error) => {
    dispatch(uncaughtError({
      msgOrEvent, url, line, col, error,
    }));
  };
}
