import React from "react";
import { connect } from "react-redux";
import { createForm } from "../containers/Form";
import { createShow } from "../containers/Show";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import TextField from "../components/TextField";
import ConcealedTextField from "../components/ConcealedTextField";
import SelectField from "../components/SelectField";
import Submit from "../components/Submit";

import { FAQ } from "../constants";

const STEP_NAME = "bankInformation";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  title,
  step
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">
        STEP 5 OF 6
      </p>

      <h2 className="section-heading section-heading--small u-mb-none">
        {title}
      </h2>
      <p>
        For security purposes, we only allow one bank account per investor to be
        used for deposits and withdrawals. For more information about how we keep
        your money safe, please see our <a href={FAQ} className="cta-link" target="_blank">FAQ.</a>
      </p>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          name="bankName"
          component={TextField}
          validates={[
            ["presence", "Please provide a valid bank name"],
            ["maxLength", null, 100]
          ]}
        />

        <Field
          name="nameOnAccount"
          component={TextField}
          validates={[
            ["nameFormat", "Please submit your name without numerical or special characters"],
            ["maxLength", null, 100]
          ]}
        />

        <Field
          name="accountType"
          component={SelectField}
          validates={[
            ["presence", "Please select an account type"]
          ]}>
          <option value="" disabled>Select account type</option>
          <option value="checking">Checking</option>
          <option value="savings">Savings</option>
        </Field>

        <Field
          name="routingNumber"
          component={TextField}
          validates={[
            ["routingNumberFormat", "Please enter a 9 digit routing number"]
          ]}
        />

        <Field
          name="accountNumber"
          component={TextField}
          validates={["accountNumberFormat"]}
        />

        <Submit value="Save and continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(({
  bankName,
  nameOnAccount,
  accountType,
  routingNumber,
  accountNumber,
}) => {
  return (
    <div>
      <b>Bank name</b>
      <p>{bankName}</p>

      <b>Name on account</b>
      <p>{nameOnAccount}</p>

      <b>Account type</b>
      <p>{accountType[0].toUpperCase() + accountType.slice(1)}</p>

      <b>Routing number</b>
      <p>******{routingNumber.slice(6,9)}</p>

      <b>Account number</b>
      <p>{accountNumber.length > 3 ? ("*".repeat(accountNumber.length - 3) + accountNumber.slice(-4, -1)) : accountNumber}</p>
    </div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = () => {
  return {
    step: STEP_NAME,
    title: "Bank Information",
  };
};

export default connect(mapStateToProps)(Step);
