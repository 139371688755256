import React from "react";
import { connect } from "react-redux";
import { createStep } from "../containers/Step";

const STEP_NAME = "documentUploadComplete";

const View = ({
  step,
  title,
}) => {
  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small text-block__heading-bar--primary">{ title }</h2>

        <div className="u-text-center documents-image u-mb-x-small"></div>

        <p>Your profile and documents are being reviewed by our investor success team. A team member will contact you within the next two business days to let you know when your account is activated.</p>
        <p>You can now explore the rest of your investor account.</p>
        <div className="u-text-center submit-button">
          <button className="cta-primary cta--block" type="button">
            <a className="cta-primary--link" href="/us/investors/summary">Go to dashboard</a>
          </button>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ stepModes }) => {
  return {
    step: STEP_NAME,
    title: "Document upload complete"
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
