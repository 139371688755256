import React from 'react';
import { connect, Provider } from 'react-redux';
import configureStore from './store';
import * as actions from './actions';

import Layout from './components/Layout';
import { withViewMatchers } from '../common/utils/matchers';
import withErrorHandling  from './utils/errors';

const mapStateToProps = (state) => {
  return state;
}

const WithErrorHandling = withErrorHandling(Layout);
const WithViewMatchers  = withViewMatchers(WithErrorHandling);
const LayoutContainer   = connect(mapStateToProps, actions)(WithViewMatchers);

const Portfolio = (props) => {
  return (
    <Provider store={configureStore(props)}>
      <LayoutContainer />
    </Provider>
  );
}

export default Portfolio;
