import React from "react";
import { connect } from "react-redux";
import { createStep } from "../containers/Step";
import Submit from "../components/Submit";
import { FAQ, INVESTOR_RELATIONS_EMAIL } from "../constants";

const STEP_NAME = "startProfile";

const View = ({ handleSubmit, firstName }) => {
  const emailLink = `mailto: ${INVESTOR_RELATIONS_EMAIL}`;

  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--medium text-block__heading-bar--primary">
          Welcome, {firstName}!
        </h2>

        <div className="computer-image u-mb-x-small"></div>

        <p className="lead-paragraph lead-paragraph--x-large u-mb-medium">
          Complete your profile to verify your accreditation status.
        </p>

        <p>You will be asked complete a profile and submit documents to verify your identity, bank information, and accreditation status.</p>

        <p className="u-mb-medium">
          If you have any questions about accreditation, or if you’re unsure if you qualify, see our <a href={FAQ} className="cta-link" target="_blank">FAQ</a> or <a href={emailLink} className="cta-link" target="_blank">contact</a> a member of our investor success team.
        </p>

        <form onSubmit={handleSubmit()}>
          <Submit value="Start profile" step={STEP_NAME} />
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = ({ profile }) => {
  const { firstName } = profile;

  return {
    firstName: firstName
  };
};

const Step = createStep(STEP_NAME)(View);

export default connect(mapStateToProps)(Step);
