import React from 'react';
import { gaEvent } from "../../common/utils/common";

const SlidePanel = ({slideLegendItem, slidePanelItem, children}) => {
  const handleSlide = () => {
    gaEvent("my-portfolio", "slide-close", slidePanelItem);
    slideLegendItem(slidePanelItem);
  };

  return (
    <div className='slider-wrapper'>
      <div className={slidePanelItem ? 'slider show' : 'slider'}>
        {children}
      </div>
      <div
        onClick={handleSlide}
        className={`close-arrow ${slidePanelItem}`}></div>
    </div>
  );
}

export default SlidePanel;
