import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import Submit from "../components/Submit";
import TextField from "../components/TextField";
import { PRIVATE_PLACEMENT_MEMORANDUM_LINK, SUBSCRIPTION_AGREEMENT_LINK } from "../constants";

const STEP_NAME = "electronicSignature";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  step,
  title,
  handleSubmit,
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 6 OF 6</p>

      <h2 className="section-heading section-heading--small">{ title }</h2>

      <p className="u-mb-x-small">I certify, under penalty of perjury that:</p>

      <ul className="unordered-list--spacing">
        <li>I have read and understand the <a href={PRIVATE_PLACEMENT_MEMORANDUM_LINK} className="cta-link" target="_blank" download>Confidential Private Placement Memorandum</a></li>
        <li>I agree to the <a href={SUBSCRIPTION_AGREEMENT_LINK} className="cta-link" target="_blank">Subscription Agreement</a></li>
        <li>I am not subject to backup withholding because:
          (a) I am exempt from backup withholding,
          or (b) I have not been notified by the Internal Revenue Service (IRS) that I am
          subject to backup withholding as a result of a failure to report all interest or dividends,
          or (c) the IRS has notified me that I am no longer subject to backup withholding</li>
        <li>I am a US citizen or US resident alien</li>
        <li>All information I have and will provide as a part of this application are true</li>
      </ul>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          name="electronicSignature"
          label="Electronic signature"
          component={TextField}
          validates={[
            ["nameFormat", "Please provide your electronic signature"],
            ["maxLength", null, 100]
          ]}
        />

        <Submit value="Save and continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(({
  electronicSignature
}) => {
  return (
    <div>{electronicSignature}</div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ stepModes, profile }) => {
  return {
    step: STEP_NAME,
    title: "Electronic Signature",
  };
};

export default connect(mapStateToProps)(Step);
