import React from "react";
import { connect } from "react-redux";
import { updateFieldsAndValues } from "../actions";
import { formatMoney } from "../helpers";

const handleTransferIn = ({ dispatch }) => {
  const fieldsAndValues = [{
    field: "transferType",
    value: "deposit",
  }, {
    field: "showRestOfTheTransferForm",
    value: true,
  }];

  dispatch(updateFieldsAndValues({ fieldsAndValues, omit: [] }));
};

const NoFunds = props => (
  <div className="u-pv-large">
    <p className="lead-paragraph u-mb-large">
      Funding Circle available balance:
      <b>{props.availableFundsValue}</b>
    </p>
    <p className="lead-paragraph">
      {`You don’t have any available funds to transfer out of your Funding Circle account.
      Did you mean to `}
      <a className="cta-link pointer" onClick={() => handleTransferIn(props)}>transfer in to Funding Circle</a>?
    </p>
  </div>
);

const mapStateToProps = (state) => {
  const { availableFunds } = state.transferValues;
  const availableFundsValue = ` ${availableFunds < 0 ? "-" : ""}$${formatMoney(Math.abs(availableFunds) || 0)}`;

  return {
    availableFundsValue,
  };
};

export default connect(mapStateToProps)(NoFunds);
