import _ from "lodash";

import { observableFromStore } from "redux-rx";
import { fetchHoldings } from "./actions";

//
// Checks the store after each update to see if any of the
// sorting/filtering/pagination options have changed.
//
export function searchParamsDidChange(prevState, nextState) {
  return !_.isEqual(prevState.sorting.direction, nextState.sorting.direction)
         || !_.isEqual(prevState.sorting.activeColumn, nextState.sorting.activeColumn)
         || !_.isEqual(prevState.filtering.filtersApplied, nextState.filtering.filtersApplied)
         || !_.isEqual(prevState.pagination.currentPage, nextState.pagination.currentPage);
}

export function codesFor(nextState, group) {
  return _.map(nextState.filtering.groups[group].applied, f => f.code);
}

//
// Extracts data from the state store and transforms it into query
// params for the API.
//
export function buildSearchParams(nextState) {
  return {
    limit: nextState.pagination.itemsPerPage,
    offset: (nextState.pagination.itemsPerPage * (nextState.pagination.currentPage - 1)),
    sort: nextState.sorting.activeColumn,
    order: nextState.sorting.direction,
    credit_rating: codesFor(nextState, "credit_rating"),
    status: codesFor(nextState, "status"),
    term_months: codesFor(nextState, "term_months"),
  };
}

//
// Fetches the holdings from the API if the search params
// have changed.
//
export function onStoreDidUpdate(dispatch, getState, prevState, nextState, action) {
  if (searchParamsDidChange(prevState, nextState) && !nextState.error.present) {
    const params = buildSearchParams(nextState);
    return action(params, prevState)(dispatch, getState);
  }
  return null;
}

//
// Watches the store for updates, so that we automatically
// fetch new holdings whenever the search params change.
//
export function observeStore(store) {
  const store$ = observableFromStore(store);
  const { dispatch } = store;
  const { getState } = store;
  let prevState = getState();

  store$
    .debounce(20)
    .subscribe((nextState) => {
      onStoreDidUpdate(dispatch, getState, prevState, nextState, fetchHoldings);
      prevState = nextState;
    });
}
