import React from "react";
import { connect } from "react-redux";
import Icon from "radius/dist/m2dm/react/icon";
import { closeModal } from "../actions";
import { renderIf } from "../../common/utils/matchers";

const Modal = ({
  closeModal,
  activeModal,
  visible,
  children,
}) => renderIf(visible)(
  <div className={`modal ${activeModal} show`}>
    <div className="modal__overlay" tabIndex="-1" onClick={() => closeModal()} />
    <div className="modal__box modal__box--no-scroll modal__box--auto-scroll" role="dialog" aria-labelledby="dialog-title">
      <span className="modal__header" onClick={() => closeModal()}>
        <Icon
          name="cross"
          modifiers={["small"]}
        />
      </span>
      {children}
    </div>
  </div>,
);

const mapStateToProps = (state, ownProps) => ({
  activeModal: state.activeModal ? state.activeModal : "",
  visible: ownProps.visible,
});

export default connect(mapStateToProps, { closeModal })(Modal);
