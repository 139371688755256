import _ from "lodash";
import { filters } from "../constants";
import { flipBool, titlecase } from "../../common/utils/common";

export function codesFor(filterList) {
  return _.map(filterList, f => f.code);
}

export function canApply({ groups }) {
  return _.some(groups, group => _.xor(
    codesFor(group.selected),
    codesFor(group.applied),
  ).length > 0);
}

export function canClear({ groups }) {
  return _.some(groups, group => !_.isEmpty(group.selected) || !_.isEmpty(group.applied));
}

export function assignApplyClearState(nextState) {
  return {
    ...nextState,
    canApply: canApply(nextState),
    canClear: canClear(nextState),
  };
}

export function applyFilters(state) {
  const nextState = {
    ...state,
    filterBoxOpen: false,
    groups: _.mapValues(state.groups, group => _.assign(
      {},
      group,
      { applied: group.selected },
    )),
  };

  nextState.filtersApplied = _.chain(nextState.groups)
    .map(v => v.applied.sort((a, b) => a.position - b.position))
    .flatten()
    .value()
    .map(x => x.name);

  return assignApplyClearState(nextState);
}

export function clearFilters(state) {
  const nextGroup = filterGroup => _.assign({}, filterGroup, { applied: [], selected: [] });
  const nextState = {
    ...state,
    groups: _.mapValues(state.groups, nextGroup),
  };
  nextState.filtersApplied = [];
  return assignApplyClearState(nextState);
}

export function toggleFilterBox(state) {
  const nextState = _.cloneDeep(state);
  _.update(nextState, ["filterBoxOpen"], flipBool);
  return nextState;
}

export function includesFilter(filterList, filter) {
  return _.includes(_.map(filterList, f => f.code), filter.code);
}

export function addOrRemoveFilter(filter) {
  return (filterList) => {
    const newFilters = includesFilter(filterList, filter)
      ? _.reject(filterList, f => f.code === filter.code)
      : _.concat(filterList, filter);

    return _.sortBy(newFilters, f => f.code);
  };
}

export function toggleFilter(state, { group, filter }) {
  const nextState = _.cloneDeep(state);

  _.update(nextState, ["groups", group, "selected"], addOrRemoveFilter(filter));

  return assignApplyClearState(nextState);
}

export function buildFilter(filterCode) {
  const filter = filters[filterCode];
  return {
    code: filterCode,
    name: _.get(filter, "displayName", titlecase(filterCode)),
    position: filter.displayPosition,
  };
}

export function buildFilters(...filterCodes) {
  return _.map(filterCodes, buildFilter);
}

export default function filteringReducer(state = {
  canApply: false,
  canClear: false,
  filterBoxOpen: false,
  filtersApplied: [],
  groups: {
    status: {
      alwaysVisible: buildFilters("current", "late", "defaulted", "paid_in_full", "charged_off"),
      applied: [],
      code: "status",
      displayName: "STATUS",
      selected: [],
    },
    credit_rating: {
      alwaysVisible: buildFilters("A+", "A", "B", "C", "D"),
      applied: [],
      code: "credit_rating",
      displayName: "RISK",
      selected: [],
    },
    term_months: {
      alwaysVisible: buildFilters(6, 12, 24, 36, 48, 60),
      applied: [],
      code: "term_months",
      displayName: "TERM (MONTHS)",
      selected: [],
    },
  },
}, action) {
  switch (action.type) {
    case "TOGGLE_FILTER":
      return toggleFilter(state, action);
    case "APPLY_FILTERS":
      return applyFilters(state);
    case "CLEAR_FILTERS":
      return clearFilters(state);
    case "TOGGLE_FILTER_BOX":
      return toggleFilterBox(state);
    case "FETCH_HOLDINGS_FAILURE":
      return action.prevState.filtering;
    default:
      return state;
  }
}
