import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";

import rootReducer from "./reducers/rootReducer";

import { init } from "./actions";

const middlewares = [
  thunk,
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const configureStore = (props) => {
  const store = createStore(rootReducer, applyMiddleware(...middlewares));

  store.dispatch(init(props));

  return store;
};

export default configureStore;
