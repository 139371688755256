import React from "react";
import { gaEvent } from "../../common/utils/common";

const FlippableCard = ({
  children,
  flipped,
  onFlip,
  cardName
}) => {
  const side = flipped ? "back" : "front";

  const front = children.find(c => (
    c.props.side === "front"
  ));

  const back = children.find(c => (
    c.props.side === "back"
  ));

  const visibility = s => side === s ? "card-visible" : "card-hidden";
  return (
    <div className="card-container" onClick={onFlip}>
      <div className={`card ${flipped ? "flipped" : "unflipped"}`}>
        <div className={`side front ${visibility("front")}`} onClick={() => gaEvent("summary", "flip-to-back", cardName)}>
          {front.props.children}
        </div>
        <div className={`side back ${visibility("back")}`} onClick={() => gaEvent("summary", "flip-to-front", cardName)}>
          {back.props.children}
        </div>
      </div>
    </div>
  );
};

export default FlippableCard;
