import React from 'react';

const DefaultedBreakdown = ({netRecoveries, chargeOffs}) => {
  return(
    <div className='defaulted-breakdown'>
      <div className='panel-header'>ALL-TIME DELINQUENCY METRICS</div>
      <table>
        <tbody>
          <tr>
            <td>Net Recoveries</td>
            <td className='amount'>{netRecoveries}</td>
          </tr>
          <tr>
            <td>Charge-Offs</td>
            <td className='amount'>{chargeOffs}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DefaultedBreakdown;
