export default function currentStepReducer(state = "", action) {
  switch (action.type) {
    case "INIT":
      return action.currentStep;
    case "SUBMIT_SUCCESS":
      return action.nextStep;
    default:
      return state;
  }
}
