import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";

import {
  EMPLOYMENT_AFFILIATIONS,
  EMPLOYMENT_ROLES,
} from "../constants";

import CheckBox from "../components/CheckBox";
import CheckBoxGroup from "../components/CheckBoxGroup";
import CollapsableStep from "../components/CollapsableStep";
import Submit from "../components/Submit";
import TextField from "../components/TextField";

const STEP_NAME = "affiliations";

const Field = createField(STEP_NAME);

const clearNone = (value) => {
  return value.filter((name) => name !== "none");
};

const clearItems = (value, name) => {
  return _.includes(value, name) ? [name] : value;
};

const Edit = createForm(({
  handleSubmit,
  step,
  title,
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 3 OF 6</p>

      <h2 className="section-heading section-heading--small">{ title }</h2>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          name="employmentAffiliation"
          component={CheckBoxGroup}
          validates={["notEmpty"]}>
          {EMPLOYMENT_AFFILIATIONS.map(({ value, label }) => (
            <CheckBox name={value} label={label} onChange={clearNone} />
          ))}

          <CheckBox name="none" label="None of the above" onChange={clearItems} />
        </Field>

        <Field
          name="employmentRole"
          component={CheckBoxGroup}
          validates={["notEmpty"]}>
          {EMPLOYMENT_ROLES.map(({ value, label }) => (
            <CheckBox name={value} label={label} onChange={clearNone} />
          ))}

          <CheckBox name="none" label="None of the above" onChange={clearItems} />
        </Field>

        <Submit value="Save and continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(({
  employmentAffiliation,
  employmentRole,
}) => {
  return (
    <div>
      <b>Are you affiliated with or employed by any of the following:</b>
      <p>
        {employmentAffiliation == "none" ? "None of the above" :
        EMPLOYMENT_AFFILIATIONS.map(({ value, label }) => {
          if (_.includes(employmentAffiliation, value)) {
            return (<span key={value}>{label}<br/></span>);
          }
        })}
      </p>

      <b>Are you a(n):</b>
      <p>
        {employmentRole == "none" ? "None of the above" :
        EMPLOYMENT_ROLES.map(({ value, label }) => {
          if (_.includes(employmentRole, value)) {
            return (<span key={value}>{label}<br/></span>);
          }
        })}
      </p>
    </div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ stepModes }) => {
  return {
    step: STEP_NAME,
    title: "Affiliations",
  };
};

export default connect(mapStateToProps)(Step);
