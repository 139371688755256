import React from "react";
import { connect } from "react-redux";
import { MONTHS, MODAL_NAME } from "../constants";
import { AnimatedSuccessSvg } from "../components/Status";

const CancelTransferConfirmModal = ({
  show, message,
}) => show && (
  <div className={`modal ${MODAL_NAME.CANCEL_TRANSFER_CONFIRM} ${show}`}>
    <div className="modal__overlay modal__overlay--dark" tabIndex="-1" />
    <div className="modal__box modal__box--no-scroll modal__transfer-initiated">
      <div className="modal__content u-text-center">
        <AnimatedSuccessSvg />
        <h2 className="section-heading section-heading--small section-heading--green u-mb-x-small">
          Transfer canceled
        </h2>
        <div className="white">
          <p className="lead-paragraph lead-paragraph--x-large u-mb-2x-large">
            {message}
          </p>
        </div>
      </div>
    </div>
  </div>
);


const mapStateToProps = (state) => {
  const { isCancelTransferSchedule, selectedTransfer, activeModal } = state;

  if (!selectedTransfer) {
    return { show: null };
  }

  const { transfer_at, transfer_frequency } = selectedTransfer;
  const transferAt = new Date(transfer_at);

  let message = "";
  if (isCancelTransferSchedule) {
    message = `You’ve canceled a ${transfer_frequency} recurring transfer`;
  } else {
    message = `You’ve canceled the next upcoming transfer scheduled for ${MONTHS[transferAt.getMonth()]} ${transferAt.getDate()}, ${transferAt.getFullYear()}`;
  }

  return {
    show: activeModal === MODAL_NAME.CANCEL_TRANSFER_CONFIRM ? "show" : null,
    message,
  };
};

export default connect(mapStateToProps)(CancelTransferConfirmModal);
