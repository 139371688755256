import { debouncedAction } from "../common/utils/common";

export { uncaughtError } from "../common/actions";

export function init(props) {
  return {
    type: "INIT",
    config: props.config,
    data: props.data,
  };
}

export function flipCard(cardId) {
  return {
    type: "FLIP_CARD",
    cardId,
  };
}

export function toggleExpand(cardId) {
  return {
    type: "EXPAND_CARD",
    cardId,
  };
}

export function applyFilter(filterCode) {
  return {
    type: "APPLY_FILTER",
    filterCode,
  };
}

export function nextFilter(limit) {
  return {
    type: "NEXT_FILTER",
    limit,
  };
}

export function prevFilter() {
  return {
    type: "PREV_FILTER",
  };
}

export function clearError() {
  return {
    type: "CLEAR_ERROR",
  };
}

export const setErrorTimer = debouncedAction(clearError, 5000);
