import React from "react";
import { connect, Provider } from "react-redux";
import configureStore from "./store";
import withErrorHandling  from './utils/errors';
import Layout from "./containers/Layout";
import { uncaughtError } from './actions.js'

const mapStateToProps = (state) => {
  return state;
}

const WithErrorHandling = withErrorHandling(Layout);
const LayoutContainer = connect(mapStateToProps, {uncaughtError})(WithErrorHandling);

const InvestorOnboarding = (props) => {
  return (
    <Provider store={configureStore(props)}>
      <LayoutContainer />
    </Provider>
  );
}

export default InvestorOnboarding;
