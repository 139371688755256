import React from "react";
import { connect } from "react-redux";
import { flipCard } from "../actions";
import FlippableCard from "./FlippableCard";
import DonutChart from "./DonutChart";
import PortfolioBalanceLegend from "./PortfolioBalanceLegend";
import { PORTFOLIO_BALANCE_ID } from "../constants";

const mapStateToProps = ({ portfolioBalance, config }) => {
  const {
    portfolio_balance,
    outstanding_principal,
    available_funds,
    total_pending,
    pending_repayments,
  } = portfolioBalance.generalAttributes;
  return {
    config: config,
    chartAttributes: portfolioBalance.chartAttributes,
    portfolioBalance: portfolio_balance,
    outstandingPrincipal: outstanding_principal,
    availableFunds: available_funds,
    totalPending: total_pending,
    pendingRepayments: pending_repayments,
    pendingRepaymentsVisible: portfolioBalance.pendingRepaymentsVisible,
    flipped: portfolioBalance.flipped,
    expanded: portfolioBalance.expanded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFlip: () => dispatch(flipCard(PORTFOLIO_BALANCE_ID)),
  };
};

const PortfolioBalanceCard = ({
  config,
  chartAttributes,
  portfolioBalance,
  outstandingPrincipal,
  availableFunds,
  totalPending,
  pendingRepayments,
  pendingRepaymentsVisible,
  flipped,
  onFlip,
}) => (
  <FlippableCard
    flipped={flipped}
    onFlip={onFlip}
    cardName="portfolio-value">
    <div side="front">
      <DonutChart
        chartAttributes={chartAttributes}
        portfolioBalance={portfolioBalance} />
    </div>
    <div side="back">
      <PortfolioBalanceLegend
        portfolioBalance={portfolioBalance}
        availableFunds={availableFunds}
        totalPending={totalPending}
        pendingRepayments={pendingRepayments}
        pendingRepaymentsVisible={pendingRepaymentsVisible}
        outstandingPrincipal={outstandingPrincipal}
        config={config}
      />
    </div>
  </FlippableCard>
);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioBalanceCard);
