import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import CurrencyInput from "react-money-input";

import { Error, Success, Message } from "./Status";
import { required, sufficientFunds } from "../validations";
import { editAmount } from "../actions";

const CurrencyField = ({
  label, input, formProps, meta: { touched, error },
}) => {
  const { amount, editAmount } = formProps;
  const { onBlur } = input;
  return (
    <div className={`form-element ${touched && error ? "is-error" : ""}`}>
      <label className="form-label form-element__label">
        <span>{label}</span>
        {touched && error && <Error />}
        {touched && !error && <Success />}
      </label>
      <div className="form-element__elements u-mb-x-small">
        <div data-test="transfer-amount" className="form-input form-input--icon">
          <CurrencyInput
            className="form-input__element"
            value={amount}
            locale="en-US"
            currency="USD"
            currencyDisplay="symbol"
            onChange={(e, value) => editAmount("amount", value)}
            onBlur={(e, value) => onBlur(value)}
          />
          <input name="amount" type="hidden" value={amount} />
        </div>
      </div>
      {touched && error && <Message message={error} />}
    </div>
  );
};

const presence = required("Please enter an amount");

const Amount = (props) => {
  const { amount, availableFunds, editAmount } = props;
  const formProps = { amount, editAmount };
  const maxFunds = sufficientFunds(parseFloat(availableFunds));
  return (
    <Field
      label="Amount"
      name="maskedAmount"
      formProps={formProps}
      validate={[presence, maxFunds]}
      component={CurrencyField}
    />
  );
};

const mapStateToProps = (state) => {
  const { transferType } = state.transferValues;
  const newState = {
    amount: state.transferValues.amount || "",
  };
  if (transferType === "withdrawal") {
    newState.availableFunds = state.transferValues.availableFunds || 0;
  }
  return newState;
};

export default connect(mapStateToProps, { editAmount })(Amount);
