import React from "react";

import { renderIf } from "../helpers";

const ShowCard = ({
  children,
  isValid,
  onEdit,
  title,
  visible,
}) => {
  return renderIf(visible)(
    <div className="step-card__content u-pv-large">
      <div>
        {isValid ?
          <div className="step-card__support-icon"></div> :
          <img className="step-card__warning-icon" src={require("../../../../assets/images/warning.svg")} />
        }
        <h2 className="paragraph-heading paragraph-heading--large paragraph-heading--light-grey">{ title }</h2>
        <button className="cta-secondary cta-secondary--edit cta--responsive-width" onClick={onEdit}>
          Edit
        </button>
      </div>
      {children}
    </div>
  );
};

export default ShowCard;
