import {
  CANCEL_TRANSFER_SCHEDULE_SUCCESS,
  CANCEL_TRANSFER_SUCCESS,
  CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL,
  CANCEL_TRANSFER, CANCEL_TRANSFER_SCHEDULE, CLOSE_MODAL,
} from "../constants";

export const selectTransferReducer = (selectedTransfer = null, action) => {
  switch (action.type) {
    case CANCEL_TRANSFER:
    case CANCEL_TRANSFER_SCHEDULE:
      return action.payload;
    case CLOSE_MODAL:
    default:
      return selectedTransfer;
  }
};

export const isCancelTransferScheduleReducer = (state = null, action) => {
  switch (action.type) {
    case CANCEL_TRANSFER_SUCCESS:
      return false;
    case CANCEL_TRANSFER_SCHEDULE_SUCCESS:
      return true;
    case CLOSE_MODAL:
      return null;
    case CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL:
      return null;
    default:
      return state;
  }
};
