import React from "react";
import axios from "axios";
import _ from "lodash";
import ValidatedField from "./ValidatedField";
import DragAndDrop from "./DragAndDrop";
import { RADIUS_ASSET_PATH } from "../constants";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: [],
      width: "0%",
      failedUploads: [],
    };
  }

  onChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    const promises = [];

    const uploading = this.state.uploading;

    _.forEach(files, (file) => {
      const fileName = this.renameFile(file.name);
      uploading.push(fileName);
    });
    this.setState({ uploading });

    let failedUploads = this.state.failedUploads;
    _.forEach(files, (file) => {
      const fileName = this.renameFile(file.name);
      // check for correct file size and file type
      if (file.size > 100000000) {
        failedUploads.push({ name: fileName, error: true, errorMessage: "File too large. Please upload a pdf, png, or jpg under 100 MB." });
      } else if (!["image/jpeg", "application/pdf", "image/png"].includes(file.type)) {
        failedUploads.push({ name: fileName, error: true, errorMessage: "Unaccepted file type. Please upload a pdf, png, or jpg under 100 MB." });
      } else {
        promises.push(this.uploadFile(file));
      }
    });

    this.setState({ failedUploads });

    Promise.all(promises).then((values) => {
      this.startProgressBar();

      const failedDocs = _.concat(this.state.failedUploads, _.filter(values, "error"));
      this.setState({ failedUploads: failedDocs });

      const successDocs = _.concat(this.props.documents, _.reject(values, "error"));
      this.props.handleUpdateDocs(this.props.name, successDocs);

      this.endProgressBar();
    });
  }

  renameFile(fileName) {
    const documents = _.concat(this.state.failedUploads, this.props.documents);
    let count = 0;
    documents.map((document) => {
      if (document.name === fileName || document.name.slice(0, -4) === fileName) {
        count += 1;
      }
    });
    return count === 0 ? fileName : `${fileName} (${count})`;
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", this.props.documentType);

    return axios({
      data: formData,
      headers: { "X-CSRF-Token": this.props.authToken },
      method: "POST",
      url: "/us/investors/document_uploads",
    })
      .then((successFile) => {
        return { name: this.renameFile(file.name), error: false };
      }, () => {
        return { name: this.renameFile(file.name), error: true, errorMessage: "Upload error. Please try again.", file: file };
      });
  }

  startProgressBar() {
    setTimeout(
      function() {
        this.setState({ width: "100%" });
      }.bind(this),
      200
    );
  }

  endProgressBar() {
    setTimeout(
      function() {
        this.setState({ uploading: [], width: "0%" });
      }.bind(this),
      1000,
    );
  }

  handleRetry(fileName) {
    this.setState({ uploading: [fileName] });
    const failedUpload = _.find(this.state.failedUploads, ["name", fileName]);
    const promises = [this.uploadFile(failedUpload.file)];

    Promise.all(promises).then((values) => {
      this.startProgressBar();

      const successDocs = _.concat(this.props.documents, _.reject(values, "error"));
      this.props.handleUpdateDocs(this.props.name, successDocs);

      this.endProgressBar();
    });
  }

  render() {
    const { label, errors, ifStatus, name, documents } = this.props;

    return (
      <div>
        <ValidatedField
          label={label}
          errors={errors}
          ifStatus={ifStatus}>
          <DragAndDrop handleDrop={(e) => this.onChange(e)}>
            <label className="file-input-label u-pv-small" htmlFor={name}>
              <svg className="svg-icon svg-icon--upload svg-icon--margin-right" aria-hidden="true">
                <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#upload`}></use>
              </svg>
              Choose the files to upload
            </label>
            <input
              id={name}
              type="file"
              multiple
              accept=".png,.jpg,.pdf"
              name={name}
              onChange={(e) => this.onChange(e)}
            />
          </DragAndDrop>
          <div className="upload-data">
            <div className="text_block--small u-pv-x-small">
              <svg className="svg-icon svg-icon--margin-right" aria-hidden="true">
                <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`}></use>
              </svg>
              Supported file types: JPG, PNG, or PDF under 100 MB
            </div>
            {!_.isEmpty(documents) && documents.map(document => (
              <div key={document.name} className="u-mb-x-small">
                <svg className="svg-icon svg-icon--attach svg-icon--margin-right" aria-hidden="true">
                  <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#attach`}></use>
                </svg>
                {document.name}
                <div className={`progress is-complete ${this.state.uploading.includes(document.name) ? "" : "hidden"}`}>
                  <div className="progress__activity" style={{ width: this.state.width }} />
                </div>
              </div>
            ))}
            {this.state.failedUploads.map(document => (
              <div key={document.name} className="u-mb-x-small">
                <svg className="svg-icon svg-icon--attach svg-icon--margin-right" aria-hidden="true">
                  <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#attach`}></use>
                </svg>
                {document.name}
                {document.errorMessage === "Upload error. Please try again." ? (
                  <svg className="svg-icon svg-icon--button" aria-hidden="true" onClick={() => this.handleRetry(document.name)}>
                    <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#retry`}></use>
                  </svg>
                ) : (<div></div>)}
                <div className={`progress progress-bar--light is-complete ${this.state.uploading.includes(document.name) ? "" : "hidden"}`}>
                  <div className="progress__activity" style={{ width: this.state.width }} />
                </div>
                {!this.state.uploading.includes(document.name) && document.error ? (
                  <div className="text_block--small upload-data--error">{document.errorMessage}</div>
                ) : (
                  <div></div>
                )}
              </div>
            ))}
          </div>
        </ValidatedField>
      </div>
    );
  }
}

export default FileUpload;
