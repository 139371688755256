import _ from "lodash";
import { OPTIONAL_FIELDS } from "../constants";

export function init(_state, action) {
  return _.reduce(action.stepList, (a, e) => ({
    ...a,
    [e.stepName]: {
      isValid: true,
      isSubmitting: false,
      invalidFields: [],
    },
  }), {});
}

export function submitStart(step = {}) {
  return {
    ...step,
    isSubmitting: true,
  };
}

export function submitStop(step = {}) {
  return {
    ...step,
    isSubmitting: false,
  };
}

export function updateStep(state, action, update) {
  const actionWithOptionalErrors = action;
  actionWithOptionalErrors.errors = OPTIONAL_FIELDS.includes(action.field) ? [] : action.errors;

  return {
    ...state,
    [action.step]: update(state[action.step], actionWithOptionalErrors),
  };
}

export function submitSuccess(state, action) {
  return {
    ...state,
    ...updateStep(state, action, submitStop),
    ...init(state, action.nextStep),
  };
}

export function checkStepValid(step, action) {
  const { field, errors } = action;

  const nextInvalid = step.invalidFields.filter(f => (
    !OPTIONAL_FIELDS.includes(f) && (field !== f || errors.length > 0)));

  if (errors.length > 0) { nextInvalid.push(field); }

  return {
    ...step,
    isValid: nextInvalid.length === 0,
    invalidFields: nextInvalid,
  };
}

export function unmountField(step, action) {
  const nextInvalid = step.invalidFields.filter(f => (
    !OPTIONAL_FIELDS.includes(f) && f !== action.field));

  return {
    ...step,
    isValid: nextInvalid.length === 0,
    invalidFields: nextInvalid,
  };
}

export default function stepsReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return init(state, action);
    case "MOUNT_FIELD":
      return updateStep(state, action, checkStepValid);
    case "UNMOUNT_FIELD":
      return updateStep(state, action, unmountField);
    case "UPDATE_FIELD":
      return updateStep(state, action, checkStepValid);
    case "SUBMIT_SUCCESS":
      return submitSuccess(state, action);
    case "SUBMIT_ERROR":
      return updateStep(state, action, submitStop);
    case "SUBMIT_INVALID":
      return updateStep(state, action, submitStop);
    case "SUBMIT_START":
      return updateStep(state, action, submitStart);
    default:
      return state;
  }
}
