import React from "react";
import { composeMatchers } from "../../common/utils/matchers";
import { RADIUS_ASSET_PATH } from "../constants";
import "svgxuse";

const ValidatedField = ({
  children,
  errors,
  ifStatus,
  label,
}) => {
  const ifOptional = composeMatchers(ifStatus("isOptional"));

  const ifOK = ifOptional(true)
    ? composeMatchers(
      ifStatus("isTouched"),
      ifStatus("isValid"),
      ifStatus("isDirty"),
    )
    : composeMatchers(
      ifStatus("isTouched"),
      ifStatus("isValid"),
    );

  const ifInvalid = ifOptional(true)
    ? composeMatchers(
      ifStatus("isTouched"),
      ifStatus("isInvalid"),
      ifStatus("isDirty"),
    )
    : composeMatchers(
      ifStatus("isTouched"),
      ifStatus("isInvalid"),
    );
  return (
    <div className={`form-element ${ifOK("is-success")} ${ifInvalid(_.isEmpty(errors) ? "" : "is-error")}`}>
      <label className="form-label form-element__label">
        <span>{label}</span>
        <svg className="svg-icon svg-icon--solid-error form-label__icon-error" aria-hidden="true">
          <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#cross-circle-solid`}></use>
        </svg>
        {ifOK(<svg className="svg-icon svg-icon--solid-success form-label__icon-success" aria-hidden="true">
          <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#tick-circle-solid`}></use>
        </svg>)}
      </label>
      {children}

      <div className="form-field-status">
        {ifInvalid(
          <svg className="svg-icon form-field-status__icon" aria-hidden="true">
            <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`} />
          </svg>
        )}
        <span className="form-field-status__copy">
          {ifInvalid(<span>{errors[0]}</span>)}
        </span>
      </div>
    </div>
  );
};

export default ValidatedField;
