import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addFunds } from "../actions";

const AddFunds = ({ addFunds }) => (
  <div>
    <div className="add-funds-button-wrapper">
      <button
        type="button"
        className="cta-secondary u-mb-x-small"
        onClick={() => addFunds()}
      >
        Add funds
      </button>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => bindActionCreators({ addFunds }, dispatch);
export default connect(null, mapDispatchToProps)(AddFunds);
