import React from "react";

const ProgessBar = ({
  phase,
}) => {
  const progessClass = () => {
    if (phase == "reviewProfile") {
      return "is-complete";
    } else return "is-active";
  };


  return(
    <div className={`step-card--max-width u-mb-medium ${phase == "createAccount" ? "progress-bar--hidden" : ""}`}>
      <ol className="process-steps">
        <li className="process-steps__item is-complete">
          <div className="process-steps__content">
            Create Account
          </div>
        </li>
        <li className={`process-steps__item ${progessClass()}`}>
          <div className="process-steps__content">
            Complete Profile
          </div>
        </li>
      </ol>
    </div>
  );
};

ProgessBar.defaultProps = { phase: "" };

export default ProgessBar;
