import React from "react";
import { connect } from "react-redux";
import { editStep } from "../actions";
import { MODE } from "../constants";
import CollapsedCard from "./CollapsedCard";
import EditCard from "./EditCard";
import ShowCard from "./ShowCard";
import Step from "../containers/Step";
import * as Scroll from "react-scroll";

const { Element } = Scroll;

const Container = ({
  Show,
  Edit,
  editStep,
  isCollapsed,
  isEdit,
  isShow,
  isValid,
  title,
  ...rest
}) => {
  return (
    <div id={isValid ? "" : "target-scroll"}>
      <div className={`step-card ${isCollapsed ? "is-collapsed step-card--white" : ""}`}>
        <CollapsedCard
          visible={isCollapsed}
          isValid={isValid}
          title={title}
          onEdit={editStep} />

        <EditCard visible={isEdit}>
          <Element name={rest.step}></Element>
          <Edit title={title} {...rest} />
        </EditCard>

        <ShowCard
          visible={isShow}
          isValid={isValid}
          title={title}
          onEdit={editStep}>
          <Show />
        </ShowCard>
      </div>
    </div>
  );
};

export function isValid(steps, step) {
  return steps[step] && steps[step].isValid;
}

export function ifMode(stepModes, step) {
  return (mode) => {
    return !_.isUndefined(stepModes[step]) && stepModes[step] === mode;
  };
}

export function mapStateToProps(state, ownProps) {
  const { step, children, title, edit, show, ...rest } = ownProps;
  const { steps, stepModes } = state;

  const showIf = ifMode(stepModes, step);

  return {
    Edit: edit,
    Show: show,
    isValid: isValid(steps, step),
    isCollapsed: showIf(MODE.COLLAPSED),
    isShow: showIf(MODE.SHOW),
    isEdit: showIf(MODE.EDIT),
    title: title,
  };
}

export function mapDispatchToProps(dispatch, ownProps) {
  const { step } = ownProps;

  return {
    editStep: () => dispatch(editStep(step))
  };
}

const CollapsableStep = connect(mapStateToProps, mapDispatchToProps)(Container);

export function createCollapsableStep(edit, show) {
  return ({ step, ...rest }) => (
    <Step step={step}>
      <CollapsableStep step={step} edit={edit} show={show} {...rest} />
    </Step>
  );
}

export default CollapsableStep;
