import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import CheckBox from "../components/CheckBox";
import CheckBoxGroup from "../components/CheckBoxGroup";
import CheckBoxOther from "../components/CheckBoxOther";
import CurrencyField from "../components/CurrencyField";
import Submit from "../components/Submit";
import TextField from "../components/TextField";
import YesNo from "../components/YesNo";

import {
  FAQ,
  INVESTMENT_VEHICLES,
  INVESTMENT_GOALS,
} from "../constants";

const STEP_NAME = "suitability";

const Field = createField(STEP_NAME);

const clearNone = (value) => {
  return value.filter((name) => name !== "none");
};

const clearItems = (value, name) => {
  return _.includes(value, name) ? [name] : value;
};

const Edit = createForm(({
  handleSubmit,
  step,
  title
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 4 OF 6</p>
      <h2 className="section-heading section-heading--small u-mb-none">{ title }</h2>

      <p className="u-mb-medium">Funding Circle is required to ensure that your goals and experience as an investor
          are aligned with this investment opportunity.
          To learn more about suitability requirements, see our <a href={FAQ} className="cta-link" target="_blank">FAQ</a>.</p>

      <form onSubmit={handleSubmit({ validate: false })}>

        <Field
          name="liquidAssets"
          component={CurrencyField}
          validates={[
            ["moneyFormat", "Please provide a valid dollar amount"]
          ]} />

        <Field
          name="annualHouseholdIncome"
          component={CurrencyField}
          validates={[
            ["moneyFormat", "Please provide a valid dollar amount"]
          ]} />

        <Field
          name="investmentGoals"
          component={CheckBoxGroup}
          validates={["notEmpty"]}>
          {INVESTMENT_GOALS.map(({ value, label }) => (
            <CheckBox name={value} label={label} onChange={clearNone} />
          ))}

          <CheckBoxOther name="other" label="Other (please specify)" onChange={clearNone}>
            <Field
              name="investmentGoalsOther"
              component={TextField}
              validates={[
                ["presence", "Please select an option"],
                ["maxLength", null, 255]
              ]}
            />
          </CheckBoxOther>

          <CheckBox name="none" label="None of the above" onChange={clearItems} />
        </Field>

        <Field
          name="investmentVehicles"
          component={CheckBoxGroup}
          validates={["notEmpty"]}>
          {INVESTMENT_VEHICLES.map(({ value, label }) => (
            <CheckBox name={value} label={label} onChange={clearNone} />
          ))}

          <CheckBoxOther name="other" label="Other (please specify)" onChange={clearNone}>
            <Field
              name="investmentVehiclesOther"
              component={TextField}
              validates={[
                ["presence","Please select an option"],
                ["maxLength", null, 255]
              ]}
            />
          </CheckBoxOther>

          <CheckBox name="none" label="None of the above" onChange={clearItems} />
        </Field>

        <Field
          name="financiallySuitable"
          component={YesNo}
          validates={[
            ["presence", "Please select an option"]
          ]}
        />

        <Submit value="Save and continue" step={STEP_NAME} />
      </form>
    </div>
  );
});

const Show = createShow(({
  liquidAssets,
  annualHouseholdIncome,
  investmentGoals,
  investmentGoalsOther,
  investmentVehicles,
  investmentVehiclesOther,
  financiallySuitable,
}) => {
  const showOther = (field) => {
    if (field && field.length) {
      return (<span>{field}<br/></span>);
    }
  }

  return (
    <div>
      <b>What is the total value of your liquid assets?</b>
      <p>${parseFloat(liquidAssets).toLocaleString('USD',{minimumFractionDigits: 2})}</p>

      <b>What is your projected annual income this calendar year?</b>
      <p>${parseFloat(annualHouseholdIncome).toLocaleString('USD',{minimumFractionDigits: 2})}</p>

      <b>Which of the following descriptions reflect your goals of investing in Funding Circle notes?</b>
      <p>
        {investmentGoals == "none" ? "None of the above" :
        INVESTMENT_GOALS.map(({ value, label }) => {
          if (_.includes(investmentGoals, value)) {
            return (<span key={value}>{label}<br/></span>);
          }
        })}
        {showOther(investmentGoalsOther)}
      </p>

      <b>Which of these have you invested in before?</b>
      <p>
        {investmentVehicles == "none" ? "None of the above" :
        INVESTMENT_VEHICLES.map(({ value, label }) => {
          if (_.includes(investmentVehicles, value)) {
            return (<span key={value}>{label}<br/></span>);
          }
        })}
        {showOther(investmentVehiclesOther)}
      </p>

      <b>Do you have adequate means of providing for your needs and personal contingencies, considering that this investment provides no options for liquidation?</b>
      <p>{financiallySuitable ? "Yes" : "No"}</p>
    </div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ stepModes }) => {
  return {
    step: STEP_NAME,
    title: "Suitability",
  };
};

export default connect(mapStateToProps)(Step);
