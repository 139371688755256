import React from 'react';
import HoldingRow from './HoldingRow';
import SortArrow from './SortArrow';
import SortBar from './SortBar';

import { columnNames } from '../constants';
import { activeChecker, directionChecker } from '../utils/sorting';
import { composeMatchers } from '../../common/utils/matchers';

const Holdings = ({
  holdings,
  loading,
  mediaEq,
  scrollGte,
  sorting,
  toggleSortColumn,
  toggleSortOptions,
}) => {
  const isActive   = activeChecker(sorting.activeColumn);
  const direction  = directionChecker(isActive, sorting.direction);
  const ifMobile   = mediaEq('mobile');
  const waypoint   = scrollGte(1682);
  const ifScrolled = composeMatchers(ifMobile, waypoint);

  return (
    <div>
      {ifMobile(
        <SortBar
          sorting={sorting}
          toggleSortOptions={toggleSortOptions}
          toggleSortColumn={toggleSortColumn}
        />
      )}

      {ifScrolled(
        <div
          className='floating-button'
          onClick={() => window.scrollTo(0,0) }>
        </div>
      )}

      <table className={`table-striped ${loading ? 'loading' : ''}`}>
        <thead>
          <tr>
            <th>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('purchased_at')}>
                <div>{columnNames['purchased_at']}</div>
                <SortArrow
                  className='sort-arrow'
                  active={isActive('purchased_at')}
                  direction={direction('purchased_at')}/>
              </div>
            </th>
            <th>
              <div>Loan Title</div>
            </th>
            <th>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('status')}>
                <div>{columnNames['status']}</div>
                <SortArrow
                  className='sort-arrow'
                  active={isActive('status')}
                  direction={direction('status')}/>
              </div>
            </th>
            <th className='right'>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('original_principal_cents')}>
                <div className='principal-header'>{columnNames['original_principal_cents']}</div>
                <SortArrow
                  className='principal-arrow'
                  active={isActive('original_principal_cents')}
                  direction={direction('original_principal_cents')}/>
              </div>
            </th>
            <th className='right u-pr-small'>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('remaining_principal_cents')}>
                <div className='principal-header'>{columnNames['remaining_principal_cents']}</div>
                <SortArrow
                  className='principal-arrow'
                  active={isActive('remaining_principal_cents')}
                  direction={direction('remaining_principal_cents')}/>
              </div>
            </th>
            <th className='left'>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('credit_rating')}>
                <div>{columnNames['credit_rating']}</div>
                <SortArrow
                  className='sort-arrow'
                  active={isActive('credit_rating')}
                  direction={direction('credit_rating')}/>
              </div>
            </th>
            <th className='center'>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('term_months')}>
                <div>{columnNames['term_months']}</div>
                <SortArrow
                  className='sort-arrow'
                  active={isActive('term_months')}
                  direction={direction('term_months')}/>
              </div>
            </th>
            <th className='right'>
              <div
                className='sort-header'
                onClick={() => toggleSortColumn('interest_rate')}>
                <div>{columnNames['interest_rate']}</div>
                <SortArrow
                  className='sort-arrow'
                  active={isActive('interest_rate')}
                  direction={direction('interest_rate')}/>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='holdings-table-desktop-tablet'>
          {holdings.map(holding => (
            <HoldingRow key={holding.id} holding={holding} />
          ))}
        </tbody>
        <tbody className='holdings-table-mobile'>
          {holdings.map(holding => (
            <HoldingRow key={holding.id} holding={holding} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Holdings;
