export default function errorReducer(state = {
  present: false,
  message: "",
}, action) {
  switch (action.type) {
    case "CLEAR_ERROR":
      return {
        present: false,
        message: "",
      };
    case "FETCH_HOLDINGS_FAILURE":
    case "FETCH_TOTAL_EARNINGS_FAILURE":
      return {
        present: true,
        message: "An error occured. Please try again later.",
      };
    case "UNCAUGHT_ERROR":
      return {
        present: true,
        message: "An unexpected error occured.",
      };
    default:
      return state;
  }
}
