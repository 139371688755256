import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import TextField from "../components/TextField";
import ConcealedTextField from "../components/ConcealedTextField";
import Submit from "../components/Submit";
import DateField from "../components/DateField";

const STEP_NAME = "personalDetails";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  title,
  step
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">
        STEP 1 OF 6
      </p>

      <h2 className="section-heading section-heading--small u-mb-medium">
        {title}
      </h2>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          name="firstName"
          label="Legal first name"
          component={TextField}
          validates={[
            ["nameFormat", "Please submit your first name without numerical or special characters"],
            ["maxLength", null, 40]
          ]}
        />

        <Field
          name="lastName"
          label="Legal last name"
          component={TextField}
          validates={[
            ["nameFormat", "Please submit your last name without numerical or special characters"],
            ["maxLength", null, 80]
          ]}
        />

        <Field
          name="birthDate"
          label="Date of birth"
          component={DateField}
          validates={[
            ["dateFormat"],
            ["pastDateFormat", "Your birthday must be before today's date"],
          ]}
        />

        <Field
          name="ssn"
          label="Social Security number"
          component={ConcealedTextField}
          mask="999-99-9999"
          validates={[
            ["ssnFormat", "Please enter your 9 digit Social Security number"],
          ]}
        />

        <p>Your SSN is used by our security team to verify your identity. We do not run a credit check and your credit score will not be affected.</p>

        <div className="u-mb-x-small BBB-svg"></div>

        <Submit value="Save and continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(({
  firstName,
  lastName,
  birthDate,
  ssn,
}) => {
  const formatBirthDate = (birthDate) => {
    return new Date(birthDate.year, (parseInt(birthDate.month) - 1), birthDate.day).toLocaleDateString("en-US", {month: "long", day: "numeric", year: "numeric"});
  };

  return (
    <div>
      <b>Legal Name</b>
      <p>{firstName} {lastName}</p>

      <b>Date of birth</b>
      <p>{formatBirthDate(birthDate)}</p>
      <b>Social security number</b>
      <p className="u-mb-none">*****{ssn.slice(5,9)}</p>
    </div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = () => {
  return {
    step: STEP_NAME,
    title: "Personal Details",
  };
};

export default connect(mapStateToProps)(Step);
