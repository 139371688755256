import _ from "lodash";

import { MODE, PHASE } from "../constants";

export function setVisibity(stepList, stepName) {
  const nextState = {};

  const currentStep = _.find(stepList, step => step.stepName === stepName);

  if (!currentStep) {
    throw new Error(`invalid step name: ${stepName}`);
  }

  const preceedsCurrentStep = step => _.indexOf(stepList, step) < _.indexOf(stepList, currentStep);

  const partOfPhase = (step, phase) => step.phase === phase;

  const currentPhase = currentStep.phase;

  _.each(stepList, (step) => {
    let mode;

    [
      PHASE.CREATE_ACCOUNT,
      PHASE.COMPLETE_PROFILE,
      PHASE.REVIEW_PROFILE,
      PHASE.UPLOAD_DOCUMENTS,
      PHASE.DOCUMENT_UPLOAD_COMPLETE,
    ].forEach((phase) => {
      if (currentPhase === phase) {
        mode = preceedsCurrentStep(step) && partOfPhase(step, phase)
          ? step.onComplete : MODE.HIDDEN;
      }
    });

    if (currentPhase === PHASE.REVIEW_PROFILE && step.reviewable === "true") {
      mode = MODE.SHOW;
    }

    nextState[step.stepName] = mode;
  });

  nextState[currentStep.stepName] = MODE.EDIT;

  return nextState;
}

const editStep = (state, action) => ({
  ...state,
  [action.step]: "edit",
});

export default function stepModesReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return setVisibity(action.stepList, action.currentStep);
    case "EDIT_STEP":
      return editStep(state, action);
    case "SUBMIT_SUCCESS":
      return setVisibity(action.stepList, action.nextStep);
    default:
      return state;
  }
}
