import React from "react";
import ValidatedField from "./ValidatedField";

const YesNo = ({
  errors,
  handleBlur,
  handleUpdate,
  ifStatus,
  label,
  name,
  value
}) => {
  const enhanceBlur = (handler) => {
    return (evt) => {
      handler(name, evt.target.checked ? evt.target.value : null);
    };
  };

  return (
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      <div className="yes-no--flex">
        <label className="form-radio">
          <input
            className="form-radio__input"
            type="radio"
            name={name}
            value="true"
            checked={value === true || value === "true"}
            onChange={handleUpdate}
            onBlur={enhanceBlur(handleBlur)}
          />
          <span className="form-radio__icon"></span>
          Yes
        </label>
        <label className="form-radio">
          <input
            className="form-radio__input"
            type="radio"
            name={name}
            value="false"
            checked={value === false || value === "false"}
            onChange={handleUpdate}
            onBlur={enhanceBlur(handleBlur)}
          />
          <span className="form-radio__icon"></span>
          No
        </label>
      </div>
    </ValidatedField>
  );
};

YesNo.defaultProps = { name: "", value: "" };

export default YesNo;
