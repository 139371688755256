import { LOAD } from "../constants";

export default (state = "", action) => {
  switch (action.type) {
    case LOAD:
      return action.payload.transfersPath;
    default:
      return state;
  }
};
