import React from 'react';

class PieChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let {dimensions, data} = this.props

    let width = dimensions.width,
        height = dimensions.height,
        radius = Math.min(width, height) / 2;

    let arc = d3.svg.arc()
        .outerRadius(radius - 10)

    let pie = d3.layout.pie()
        .sort(null)
        .value(function(d) { return d.cents; });


    let svg = d3.select("#__pie-chart").append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("class", "shadow")
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let g = svg.selectAll(".arc")
      .data(pie(data))
      .enter().append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arc)
      .attr("class", function(d) { return d.data.displayName.toLowerCase(); });
  }

  render() {
    return (
      <div id="__pie-chart"></div>
    );
  }
}

export default PieChart;
