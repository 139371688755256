import React from "react";
import ValidatedField from "./ValidatedField";

const SelectOption = ({
  errors,
  ifStatus,
  label,
  name,
  value,
  optionList,
  selectMethod
}) => {
  return (
    <ValidatedField
      label={label}
      errors={errors}
      ifStatus={ifStatus}>
      {optionList.map((listItem) => {
        return (
          <div className="u-mb-medium" key={listItem.value}>
            <label>
              <div>
                <strong>{listItem.label}</strong>
                <p className="u-mb-x-small">{listItem.description}</p>
              </div>
            </label>
            <button
              type="button"
              className="cta-secondary cta--minor"
              name={name}
              onClick={selectMethod(listItem.value)}>
              Select this option
            </button>
          </div>
        )
      })}
    </ValidatedField>
  );
};

export default SelectOption;
