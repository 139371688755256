/* eslint-disable */
import * as d3 from "d3";

const constant$1 = function (x) {
  return function constant() {
    return x;
  };
};

const pi = Math.PI;
const halfPi = pi / 2;
const chevronDefaultAngle = 0.0873; // around 5 degrees or so in radians

const chevronArcInnerRadius = d => d.innerRadius;
const chevronArcOuterRadius = d => d.outerRadius;
const chevronArcStartAngle = d => d.startAngle;
const chevronArcEndAngle = d => d.endAngle;

// calculates the coordinates of the tip of the chevron where the tip of the
// chevron extends `chevronAngle` radians past the arc
const tipCoords = function (chevronAngle, arcAngle, outerRad, innerRad) {
  const midRadius = (outerRad + innerRad) / 2;
  return {
    x: midRadius * (Math.cos(chevronAngle + arcAngle)),
    y: midRadius * (Math.sin(chevronAngle + arcAngle)),
  };
};

const chevronArc = function () {
  let innerRadius = chevronArcInnerRadius;
  let outerRadius = chevronArcOuterRadius;
  let startAngle = chevronArcStartAngle;
  let endAngle = chevronArcEndAngle;
  let context = null;

  const chevronArc = function () {
    let buffer;
    let r;
    let r0 = +innerRadius.apply(this, arguments);
    let r1 = +outerRadius.apply(this, arguments);
    const a0 = startAngle.apply(this, arguments) - halfPi;
    const a1 = endAngle.apply(this, arguments) - halfPi;
    const cw = a1 > a0;

    if (!context) context = buffer = d3.path();

    // Ensure that the outer radius is always larger than the inner radius.
    if (r1 < r0) r = r1, r1 = r0, r0 = r;

    // Or is it a circular or annular sector?
    else {
      // inner arc coordinates
      const xInnerStart = r0 * Math.cos(a0);
      const yInnerStart = r0 * Math.sin(a0);

      const chevronPoint = tipCoords(a1, chevronDefaultAngle, r0, r1);
      const tailPoint = tipCoords(a0, chevronDefaultAngle, r0, r1);

      context.moveTo(xInnerStart, yInnerStart);
      context.arc(0, 0, r0, a0, a1, !cw); // draw inner arc
      context.lineTo(chevronPoint.x, chevronPoint.y); // draw line to chevron tip

      // We don't need to explicitly draw a line to outer radius end point?
      context.arc(0, 0, r1, a1, a0, cw); // draw arc to outer radius start point
      context.lineTo(tailPoint.x, tailPoint.y); // draw line to tail point
      context.lineTo(xInnerStart, yInnerStart); // draw line to inner radius start point
    }

    context.closePath();

    if (buffer) return context = null, `${buffer}` || null;
  };

  chevronArc.centroid = function () {
    const r = (+innerRadius.apply(this, arguments) + +outerRadius.apply(this, arguments)) / 2;
    const a = (+startAngle.apply(this, arguments) + +endAngle.apply(this, arguments)) / 2 - pi / 2;
    return [Math.cos(a) * r, Math.sin(a) * r];
  };

  chevronArc.innerRadius = function (_) {
    return arguments.length ? (innerRadius = typeof _ === "function" ? _ : constant$1(+_), chevronArc) : innerRadius;
  };

  chevronArc.outerRadius = function (_) {
    return arguments.length ? (outerRadius = typeof _ === "function" ? _ : constant$1(+_), chevronArc) : outerRadius;
  };

  chevronArc.startAngle = function (_) {
    return arguments.length ? (startAngle = typeof _ === "function" ? _ : constant$1(+_), chevronArc) : startAngle;
  };

  chevronArc.endAngle = function (_) {
    return arguments.length ? (endAngle = typeof _ === "function" ? _ : constant$1(+_), chevronArc) : endAngle;
  };

  chevronArc.context = function (_) {
    return arguments.length ? ((context = _ == null ? null : _), chevronArc) : context;
  };

  return chevronArc;
};

export default chevronArc;
