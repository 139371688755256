import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";

import TextInputField from "./TextInputField";
import AccountType from "./AccountType";
import {
  required, maxLength, fieldFormat,
} from "../validations";
import { createBankAccount } from "../actions";

const maxLength100 = maxLength(100);

const requiredName = required("Please submit your name without numerical or special characters");
const nameOnAaccountFormat = fieldFormat("Please submit your name without numerical or special characters", /^[A-Za-z-' ]*$/);

const requiredBankName = required("Please provide a valid bank name");

const requiredRoutingNumber = required("Please enter a 9 digit routing number");
const routingNumber = fieldFormat("Please enter a 9 digit routing number", /^\d{9}$/);

const requiredAccountNumber = required("Please enter an account number", /^\d{9}$/);
const accountNumber = fieldFormat("Please enter an account number", /^(\d){4,100}$/);

const requiredAccountType = required("Please select an account type");

const onSubmit = (values, dispatch) => {
  dispatch(createBankAccount(values));
};

const BankForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="name_on_account"
        label="Name on account"
        validate={[requiredName, nameOnAaccountFormat, maxLength100]}
        component={TextInputField}
      />
      <Field
        name="bank_name"
        label="Bank name"
        validate={[requiredBankName, maxLength100]}
        component={TextInputField}
      />
      <Field
        name="routing_number"
        label="Routing number"
        validate={[requiredRoutingNumber, routingNumber]}
        component={TextInputField}
      />
      <Field
        name="account_number"
        label="Account number"
        validate={[requiredAccountNumber, accountNumber]}
        component={TextInputField}
      />
      <Field
        name="account_type"
        label="Account Type"
        validate={[requiredAccountType]}
        component={AccountType}
      />
      <button className="cta-primary u-grid-1 u-mt-large" type="submit">Add bank account</button>
    </form>
  );
};

const ReduxBankForm = reduxForm({
  form: "bankform",
})(BankForm);

export default connect()(ReduxBankForm);
