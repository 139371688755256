import React from "react";
import { connect } from "react-redux";

const Container = ({
  WrappedComponent,
  profile
}) => {
  return (
    <WrappedComponent {...profile} />
  );
};

export function mapStateToProps(state, ownProps) {
  const { component } = ownProps;
  const { profile } = state;

  return {
    WrappedComponent: component,
    profile: profile,
  };
}

const Show = connect(mapStateToProps)(Container);

export function createShow(component) {
  return props => <Show component={component} {...props} />;
}

export default Show;
