import _ from "lodash";

export function flipBool(bool) {
  return !bool;
}

export function titlecase(str) {
  return str.toString().charAt(0).toUpperCase() + str.toString().slice(1);
}

export function debouncedAction(action, wait, options) {
  const debounced = _.debounce(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    options,
  );

  return (...actionArgs) => dispatch => debounced(dispatch, actionArgs);
}

export function gaEvent(category, action, label) {
  window.ga("send", "event", category, action, label);
}
