import React from "react";
import PropTypes from "prop-types";

import { errorNotifier as notifier } from "../../common/utils/errors";

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    const { config } = this.props;
    const errorNotifier = notifier(config.honeybadger_key, config.environment);

    this.state = {
      errorNotifier,
      hasError: false,
    };
  }

  componentDidCatch(error) {
    const { errorNotifier } = this.state;
    const { uncaughtError } = this.props;

    errorNotifier.notify(error);
    uncaughtError({ error });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    const {
      applyFilters,
      error,
      selectNext,
      selectPage,
      selectPrevious,
      toggleSortColumn,
      WrappedComponent,
    } = this.props;

    const noopOnError = (action) => {
      if (error.present) {
        return () => {};
      }
      return action;
    };

    if (hasError) {
      return (
        <p className="uncaught-error">
          {error.message}
        </p>
      );
    }
    return (
      <WrappedComponent
        {...this.props}
        applyFilters={noopOnError(applyFilters)}
        selectNext={noopOnError(selectNext)}
        selectPage={noopOnError(selectPage)}
        selectPrevious={noopOnError(selectPrevious)}
        toggleSortColumn={noopOnError(toggleSortColumn)}
      />
    );
  }
}

ErrorHandler.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  config: PropTypes.shape({
    honeybadger_key: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.shape({
    present: PropTypes.bool,
    message: PropTypes.string.isRequired,
  }).isRequired,
  selectNext: PropTypes.func.isRequired,
  selectPage: PropTypes.func.isRequired,
  selectPrevious: PropTypes.func.isRequired,
  toggleSortColumn: PropTypes.func.isRequired,
  uncaughtError: PropTypes.func.isRequired,
};

const withErrorHandling = WrappedComponent => props => (
  <ErrorHandler WrappedComponent={WrappedComponent} {...props} />
);

export default withErrorHandling;
