import React from 'react';

import { renderIf } from '../../common/utils/matchers';
import { buttonWidth, selected, applied } from '../utils/filtering';

const FilterGroup = ({group, toggleFilter}) => {
  return (
    <div className={`filter-group ${group.code}`}>
      <div className='filter-group-header'>{group.displayName}</div>
      {group.alwaysVisible.map(filter => (
        <button
          className={`filter-button ${buttonWidth(group, filter)} ${applied(group, filter)} ${selected(group, filter)}`}
          onClick={() => toggleFilter(group, filter)}
          key={filter.code}>
          {filter.name}
        </button>
      ))}
    </div>
  );
};

export default FilterGroup;
