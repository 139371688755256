import React from "react";
import { connect } from "react-redux";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import Submit from "../components/Submit";
import FileUpload from "../components/FileUpload";

const STEP_NAME = "uploadIdentification";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  step,
  title,
  authToken,
  idFront,
  idBack,
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 1 OF 3</p>

      <h2 className="section-heading section-heading--small">
        {title}
      </h2>

      <p className="u-mb-medium">Upload a clear scan or photo of both the front and back of a valid form of government-issued U.S. identification, such as a driver’s license. If you choose to upload an image of your passport, only the identification page is required.</p>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          component={FileUpload}
          name="idFront"
          documentType="identification"
          authToken={authToken}
          documents={idFront}
          validates={[
            ["presence", "Please upload a file"],
            ["successfulUpload"]
          ]}
        />
        <Field
          component={FileUpload}
          name="idBack"
          documentType="identification"
          authToken={authToken}
          documents={idBack}
          validates={["successfulUpload"]}
        />
        <Submit value="Continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(() => {
  return <div></div>;
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ authToken, profile }) => {
  return {
    step: STEP_NAME,
    title: "Upload Identification",
    authToken,
    idFront: profile.idFront,
    idBack: profile.idBack,
  };
};

export default connect(mapStateToProps)(Step);
