export const RADIUS_ASSET_PATH = "https://dgnvhpcjbd3ba.cloudfront.net/radius/10.5.1";
export const mediaSizes = [
  {
    device: "desktop",
    size: "large",
    maxWidth: 9999,
    minWidth: 1400,
  },
  {
    device: "desktop",
    size: "medium",
    maxWidth: 1399,
    minWidth: 1200,
  },
  {
    device: "desktop",
    size: "small",
    maxWidth: 1199,
    minWidth: 1024,
  },
  {
    device: "tablet",
    size: "large",
    maxWidth: 1023,
    minWidth: 980,
  },
  {
    device: "tablet",
    size: "medium",
    maxWidth: 979,
    minWidth: 800,
  },
  {
    device: "tablet",
    size: "small",
    maxWidth: 799,
    minWidth: 768,
  },
  {
    device: "mobile",
    size: "large",
    maxWidth: 767,
    minWidth: 640,
  },
  {
    device: "mobile",
    size: "medium",
    maxWidth: 639,
    minWidth: 480,
  },
  {
    device: "mobile",
    size: "small",
    maxWidth: 479,
    minWidth: 0,
  },
];

export const TERMS_GLOSSARY = [
  {
    code: "availableFunds",
    term: "Available Funds",
    definition: "The amount of funds available to invest or withdraw.",
  },
  {
    code: "chargedOff",
    term: "Charged-off",
    definition: "Defaulted loans are charged-off on a case-by-case basis when our collections team believes that they will no longer be able to net any recoveries from the borrower.",
  },
  {
    code: "defaulted",
    term: "Defaulted",
    definition: "A loan is defaulted when it reaches 90 days past due. A loan may be defaulted before 90 days if it is filing for a double bankruptcy or in rare cases, if there is a significant impact on the collectibility of the loan. In case of early default, investors are notified via the Loan Information Tab.",
  },
  {
    code: "grossRecoveries",
    term: "Gross Recoveries",
    definition: "The total dollar amount of recoveries collected on defaulted loans in your portfolio before deducting collection fees.",
  },
  {
    code: "totalInterestEarned",
    label: "INTEREST EARNED TO DATE",
    term: "Interest Earned to Date",
    definition: "The interest received from borrower repayments to date.",
  },
  {
    code: "lateFees",
    term: "Late Fees",
    definition: "Your prorated share of late fees paid by borrowers.",
  },
  {
    code: "netRecoveries",
    term: "Net Recoveries",
    definition: "The sum of gross principal and interest recoveries received minus any collection fees.",
  },
  {
    code: "outstandingPrincipal",
    term: "Outstanding Principal",
    definition: "Remaining principal amount that has not yet been repaid by the borrower.",
  },
  {
    code: "pendingOrders",
    term: "Pending Orders",
    definition: "The sum of orders that have been placed on loans which are still live on the marketplace.",
  },
  {
    code: "performingOutstandingPrincipal",
    term: "Performing Outstanding Principal",
    definition: "Remaining principal amount (excluding defaults) that has not yet been repaid by the borrower.",
  },
  {
    code: "portfolioBalance",
    term: "Portfolio Balance",
    definition: "The sum of outstanding principal, pending orders, and available funds.",
  },
  {
    code: "remainingTerm",
    term: "Remaining Term",
    definition: "The amount of time from now to when a loan matures rounded up to the nearest month.",
  },
  {
    code: "servicingFee",
    term: "Servicing Fee",
    definition: "Funding Circle deducts a fee from each principal or interest payment.",
  },
  {
    code: "totalEarned",
    term: "Total Earned",
    definition: "Total amount of interest payments and late fees, net of servicing fees.",
  },
  {
    code: "totalAmountInvested",
    label: "TOTAL INVESTED TO DATE",
    term: "Total Invested to Date",
    definition: "The sum of all principal you have invested to date. This amount may be higher than your deposits if you have reinvested proceeds from payments.",
  },
  {
    code: "weightedAverageCoupon",
    label: "WEIGHTED AVERAGE COUPON",
    term: "Weighted Average Coupon",
    definition: "The weighted average gross interest rate on your portfolio of outstanding principal (excluding defaults).",
  },
];
