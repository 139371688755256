import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../helpers";
import { createShow } from "../containers/Show";
import { createForm } from "../containers/Form";
import { createField } from "../containers/Field";
import { createCollapsableStep } from "../components/CollapsableStep";
import SelectField from "../components/SelectField";
import Submit from "../components/Submit";
import TextField from "../components/TextField";
import YesNo from "../components/YesNo";

const STEP_NAME = "address";

const Field = createField(STEP_NAME);

const Edit = createForm(({
  handleSubmit,
  ifResidentialNonMatch,
  stateList,
  step,
  title
}) => {
  return (
    <div className="step-card__content">
      <p className="u-mb-x-small step-card__content--bold">STEP 2 OF 6</p>

      <h2 className="section-heading section-heading--small u-mb-medium">{ title }</h2>
      <h2 className="paragraph-heading paragraph-heading--medium u-mb-small">Residential address</h2>

      <form onSubmit={handleSubmit({ validate: false })}>
        <Field
          name="residentialAddressOne"
          component={TextField}
          validates={[
            ["presence", "Please enter a valid street address"],
            ["maxLength", null, 255]
          ]}
        />

        <Field
          name="residentialAddressTwo"
          component={TextField}
          validates={[["maxLength", null, 255]]} />

        <Field
          name="residentialCity"
          component={TextField}
          validates={["cityFormat", ["maxLength", null, 40]]} />

        <div className="field--flex">
          <div className="form-select__element--48">
            <Field
              name="residentialState"
              component={SelectField}
              validates={[
                ["presence", "Please select a state"]
              ]}
            >
                <option value="" disabled>State</option>
                {_.keys(_.fromPairs(stateList)).map((stateName) => (<option value={stateName} key={stateName}>{stateName}</option>))}
            </Field>
          </div>

          <div className="form-select__element--48">
            <Field
              name="residentialZip"
              component={TextField}
              validates={["zipCodeFormat"]} />
          </div>
        </div>

        <h2 className="paragraph-heading paragraph-heading--medium u-mb-small">Mailing address</h2>

        <Field
          name="residentialMailingMatch"
          component={YesNo}
          validates={["presence"] } />

        {ifResidentialNonMatch(
          <div>
            <Field
              name="mailingAddressOne"
              component={TextField}
              validates={[
                ["presence", "Please enter a valid street address"],
                ["maxLength", null, 255]
              ]}
            />

            <Field
              name="mailingAddressTwo"
              component={TextField}
              validates={["mailingAddressLength"]} />

            <Field
              name="mailingCity"
              component={TextField}
              validates={["cityFormat", ["maxLength", null, 40]]} />

            <div className="field--flex">
              <div className="form-select__element--48">
                <Field
                  name="mailingState"
                  component={SelectField}
                  validates={[
                    ["presence", "Please select a state"]
                  ]}
                >
                    <option value="" disabled>State</option>
                    {_.keys(_.fromPairs(stateList)).map((stateName) => (<option value={stateName} key={stateName}>{stateName}</option>))}
                </Field>
              </div>

              <div className="form-select__element--48">
                <Field
                  name="mailingZip"
                  component={TextField}
                  validates={["zipCodeFormat"]} />
              </div>
            </div>
          </div>
        )}

        <Submit value="Save and continue" step={step} />
      </form>
    </div>
  );
});

const Show = createShow(({
  residentialAddressOne,
  residentialAddressTwo,
  residentialCity,
  residentialState,
  residentialZip,
  residentialMailingMatch,
  mailingAddressOne,
  mailingAddressTwo,
  mailingCity,
  mailingState,
  mailingZip,
}) => {
  return (
    <div>
      <b>Residential Address</b>
      <p className="u-mb-none">{residentialAddressOne}</p>
      <p className="u-mb-none">{residentialAddressTwo}</p>
      <p>{residentialCity} {residentialState}, {residentialZip}</p>

      <b>Mailing Address</b>
      {residentialMailingMatch ?
        <div>
          <p className="u-mb-none">{residentialAddressOne}</p>
          <p className="u-mb-none">{residentialAddressTwo}</p>
          <p>{residentialCity} {residentialState}, {residentialZip}</p>
        </div> :
        <div>
          <p className="u-mb-none">{mailingAddressOne}</p>
          <p className="u-mb-none">{mailingAddressTwo}</p>
          <p>{mailingCity} {mailingState}, {mailingZip}</p>
        </div>
      }
    </div>
  );
});

const Step = createCollapsableStep(Edit, Show);

const mapStateToProps = ({ stateList, profile }) => {
  return {
    ifResidentialNonMatch: renderIf(profile.residentialMailingMatch == "false" || profile.residentialMailingMatch == false),
    stateList: stateList,
    step: STEP_NAME,
    title: "Address",
  };
};

export default connect(mapStateToProps)(Step);
