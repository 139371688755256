import React from "react";
import { RADIUS_ASSET_PATH } from "../constants";

const PerformanceMetric = metric => (
  <div className={`performance-metric u-text-center ${metric.code === "totalInterestEarned" ? "interest-earned" : ""}`}>
    <div className="paragraph-heading figure u-mb-none">
      <span className="figure-prefix-suffix">{metric.amount.prefix}</span>
      <span className="figure-body">{metric.amount.body}</span>
      <span className="figure-prefix-suffix">{metric.amount.suffix}</span>
    </div>
    <div className="label">
      {metric.label}
      <span className="info">
        <svg className="svg-icon cta-primary__icon svg-icon--info">
          <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`} />
        </svg>
        <span className="pop">{metric.info}</span>
      </span>
    </div>
  </div>
);

export default PerformanceMetric;
