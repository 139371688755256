import _ from "lodash";
import moment from "moment";

import { renderIf } from "../common/utils/matchers";

export { renderIf, composeMatchers } from "../common/utils/matchers";

export function ifStatus(fields, field) {
  return status => renderIf(fields[field] && fields[field][status]);
}

export function errorsFor(fields, field) {
  if (fields[field] && fields[field].errors) {
    return fields[field].errors;
  }
  return [];
}

export function parseDate(val) {
  let dateAsArray;

  if (_.isString(val)) { return moment(val); }

  if (_.isArray(val)) {
    dateAsArray = val;
  } else if (_.isObject(val)) {
    dateAsArray = [val.year, val.month, val.day];
  }

  if (dateAsArray && dateAsArray.filter(Number).length === 3) {
    // index 1 is month and is 0 indexed;
    return moment([dateAsArray[0], dateAsArray[1] - 1, dateAsArray[2]]);
  }

  return null;
}

export function gaEvent(category, action, label, value) {
  window.ga("send", "event", category, action, label, value);
}
