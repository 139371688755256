import React from "react";
import { connect } from "react-redux";
import { renderIf } from "../../common/utils/matchers";
import {
  applyFilter,
  nextFilter,
  prevFilter,
  toggleExpand,
} from "../actions";

import FilterCard from "./FilterCard";
import TotalEarnedDetails from "./TotalEarnedDetails";
import { RADIUS_ASSET_PATH } from "../constants";

const mapStateToProps = (state) => {
  return {
    selectedFilter: state.totalEarned.selectedFilter,
    filterOptions: state.totalEarned.filterOptions,
    loading: state.totalEarned.loading,
    offset: state.totalEarned.offset,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFilter: code => dispatch(applyFilter(code)),
    onNext: () => dispatch(nextFilter()),
    onPrev: () => dispatch(prevFilter()),
  };
};


const totalEarnedMobileCard = ({
  filterOptions,
  flipped,
  isExpanded,
  limit,
  loading,
  offset,
  onFilter,
  onNext,
  onPrev,
  selectedFilter,
}) => (
  <div className="mobile-card-container total-earned">
    <div className="total-earned-summary-container">
      <div className="total-earned summary">
        <div className="paragraph-heading u-mb-none">
          <span className="paragraph-heading--fixed-large">$</span>
          <span className="paragraph-heading--4x-large">{selectedFilter.total_earned.dollars}</span>
          <span className="paragraph-heading--fixed-large">{`.${selectedFilter.total_earned.cents}`}</span>
        </div>

        <div className="sub-text__block u-text-center">
          TOTAL EARNED
          <span className="info">
            <svg className="svg-icon cta-primary__icon svg-icon--info">
              <use xlinkHref={`${RADIUS_ASSET_PATH}/icons/svg-sprite.svg#info-circle-outline`} />
            </svg>
            <span className="pop">Total amount of interest payments and late fees, net of servicing fees</span>
          </span>
        </div>
      </div>
    </div>

    {renderIf(isExpanded)(
      <TotalEarnedDetails filterData={selectedFilter} />
    )}
    <FilterCard
      limit={4}
      loading={loading}
      offset={offset}
      onSelect={onFilter}
      onNext={onNext}
      onPrev={onPrev}
      options={filterOptions}
      selected={selectedFilter.timeframe} />
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(totalEarnedMobileCard);
