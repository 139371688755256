import _ from "lodash";

export function fetchHoldingsSuccess(state, action) {
  return _.assign(
    { ...state },
    {
      isEmpty: _.isEmpty(action.data.holdings),
      filtersApplied: action.filtersApplied,
    },
  );
}

export function init(state, action) {
  return _.assign(
    { ...state },
    {
      isEmpty: _.isEmpty(action.data.holdings),
      filtersApplied: false,
    },
  );
}

//
// between clearing filters and fetching holdings there
// will be a gap of time where the UI would reflect no holdings
// and no filters applied. We want to avoid updating the UI at this
// juncture, so we return `true` here to keep the "no holdings
// and filters are applied" state while we wait for holdings to fetch.
//
export function clearFilters(state) {
  return _.assign(
    { ...state },
    { filtersApplied: true },
  );
}

export default function emptyStateReducer(state = {
  isEmpty: true,
  filtersApplied: false,
}, action) {
  switch (action.type) {
    case "INIT":
      return init(state, action);
    case "FETCH_HOLDINGS_SUCCESS":
      return fetchHoldingsSuccess(state, action);
    case "CLEAR_FILTERS":
      return clearFilters(state, action);
    default:
      return state;
  }
}
