import _ from "lodash";
import { NON_TEXT_FIELDS, OPTIONAL_FIELDS } from "../constants";

export function mountField(state, action) {
  const { field, errors } = action;

  return {
    ...state,
    [field]: {
      isDirty: false,
      isPristine: true,
      isTouched: false,
      isVisited: false,
      isValid: errors.length === 0,
      isInvalid: errors.length > 0,
      isOptional: OPTIONAL_FIELDS.includes(field),
      errors,
    },
  };
}

export function unmountField(state, action) {
  return { ...(_.omit(state, action.field)) };
}

export function updateField(state, action) {
  const { field, errors } = action;
  const prevField = state[field];

  return {
    ...state,
    [field]: {
      ...prevField,
      isDirty: true,
      isPristine: false,
      isVisited: true,
      isTouched: NON_TEXT_FIELDS.includes(field),
      isValid: errors.length === 0,
      isInvalid: errors.length > 0,
      isOptional: OPTIONAL_FIELDS.includes(field),
      errors,
    },
  };
}

export function focusField(state, action) {
  const { field, errors } = action;
  const prevField = state[field];

  return {
    ...state,
    [field]: {
      ...prevField,
      isVisited: true,
      isValid: errors.length === 0,
      isInvalid: errors.length > 0,
      isOptional: OPTIONAL_FIELDS.includes(field),
      errors,
    },
  };
}

export function blurField(state, action) {
  const { field, errors } = action;
  const prevField = state[field];

  return {
    ...state,
    [field]: {
      ...prevField,
      isVisited: true,
      isTouched: true,
      isValid: errors.length === 0,
      isInvalid: errors.length > 0,
      isOptional: OPTIONAL_FIELDS.includes(field),
      errors,
    },
  };
}

// Marks all the fields as touched so validation
// errors render if the user clicks the submit
// button.
export function attemptSubmit(state) {
  return Object.keys(state).reduce((a, e) => ({
    ...a,
    [e]: {
      ...state[e],
      isTouched: true,
    },
  }), {});
}

export function submitInvalid(state, action) {
  const { errors } = action;

  const withErrorsFromServer = Object.keys(errors).reduce((a, e) => {
    const prevState = state[e];

    return {
      ...a,
      [e]: {
        ...prevState,
        isValid: false,
        isInvalid: true,
        isVisited: true,
        isTouched: true,
        errors: errors[e],
      },
    };
  }, {});

  return {
    ...state,
    ...withErrorsFromServer,
  };
}

export default function fieldsReducer(state = {}, action) {
  switch (action.type) {
    case "INIT":
      return {};
    case "MOUNT_FIELD":
      return mountField(state, action);
    case "UPDATE_FIELD":
      return updateField(state, action);
    case "UNMOUNT_FIELD":
      return unmountField(state, action);
    case "FOCUS_FIELD":
      return focusField(state, action);
    case "BLUR_FIELD":
      return blurField(state, action);
    case "ATTEMPT_SUBMIT":
      return attemptSubmit(state, action);
    case "SUBMIT_INVALID":
      return submitInvalid(state, action);
    default:
      return state;
  }
}
