import React from "react";
import PortfolioBalanceCard from "./PortfolioBalanceCard";
import TotalEarnedCard from "./TotalEarnedCard";
import PortfolioBalanceMobileCard from "./PortfolioBalanceMobileCard";
import TotalEarnedMobileCard from "./TotalEarnedMobileCard";
import { makeExpandable } from "../../common/helpers/wrappers";
import { PORTFOLIO_BALANCE_ID, TOTAL_EARNED_ID } from "../constants";
import ErrorMessage from "../../common/components/ErrorMessage";
import LimitedPortfolioCard from "./LimitedPortfolioCard";
import Performance from "./Performance";

const MobileLayout = (props) => {
  const ExpandableCard = (cardID, isExpanded, card) => {
    return makeExpandable(() => props.toggleExpand(cardID), isExpanded)(card);
  };

  return (
    <div className={`${props.mediaClass}`}>
      {ExpandableCard(
        PORTFOLIO_BALANCE_ID,
        props.portfolioBalance.expanded,
        PortfolioBalanceMobileCard,
      )}
      {ExpandableCard(
        TOTAL_EARNED_ID,
        props.totalEarned.expanded,
        TotalEarnedMobileCard,
      )}
      <Performance />
    </div>
  );
};

const Layout = (props) => {
  const limitedView = props.config.limited_view;

  if (limitedView) {
    return (
      <div className="summary-v2">
        <div className={`${props.mediaClass}`}>
          <LimitedPortfolioCard redactedViewEnabled={limitedView} />
        </div>
        <ErrorMessage {...props} />
      </div>
    );
  } else {
    return (
      <div className="summary-v2">
        {props.mediaGte("tablet")(
          <div className={`${props.mediaClass}`}>
            <div className={`row`}>
              <div className="portfolio-balance-and-total-earned">
                <div className="col"><PortfolioBalanceCard /></div>
                <div className="col"><TotalEarnedCard limit={5} /></div>
              </div>
              <Performance />
            </div>
          </div>
        )}

        {props.mediaEq("mobile")(() => MobileLayout(props))}
        <ErrorMessage {...props} />
      </div>
    );
  }
};

export default Layout;
