import React from 'react';
import { renderIf } from '../../common/utils/matchers';

const ExpandBar = ({onClick, isExpanded}) => {
  return (
    <div className='expand-bar' onClick={onClick}>
      {renderIf(isExpanded)(
        <div>
          <span>VIEW LESS</span>
          <span className='collapse-icon'></span>
        </div>
      )}
      {renderIf(!isExpanded)(
        <div>
          <span>VIEW DETAILS</span>
          <span className='expand-icon'></span>
        </div>
      )}
    </div>
  );
}

export default ExpandBar;
