import React from "react";
import ValidatedField from "./ValidatedField";

class ConcealedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false
    };
  }

  handleToggleConcealed() {
    this.setState({display: !this.state.display});
  }

  render() {
    return (
      <ValidatedField
        label={this.props.label}
        errors={this.props.errors}
        ifStatus={this.props.ifStatus}>
        <div className="form-input form-input--icon">
          <input
            type={this.state.display ? "input" : "password"}
            className="form-input__element"
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            onChange={this.props.handleUpdate}
            onFocus={this.props.handleFocus}
            onBlur={this.props.handleBlur} />
          <button tabIndex="-1" className="input-item__toggle-password" type="button" onClick={() => this.handleToggleConcealed()}>{this.state.display ? "HIDE" : "SHOW"}</button>
        </div>
      </ValidatedField>
    );
  }
};

ConcealedTextField.defaultProps = { name: "", value: "" };

export default ConcealedTextField;
