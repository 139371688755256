import React from "react";

import { INVESTOR_RELATIONS_EMAIL } from "../constants";

const Ineligible = () => {
  const emailLink = "mailto:" + INVESTOR_RELATIONS_EMAIL;

  return (
    <section className="step-card">
      <div className="step-card__content">
        <h2 className="section-heading section-heading--small u-mb-none">Thank you for your interest in Funding Circle.</h2>
        <p>
          At this time, only accredited investors residing in the U.S. can participate on Funding Circle USA's platform. The SEC considers individuals to be accredited investors if they meet one or more of the following sets of criteria:
        </p>
        <ul className="unordered-list--spacing">
          <li>
            A natural person with an individual net worth, or joint net worth with his or her own spouse, excluding the value of his, her, or their primary residence, exceeding $1,000,000
          </li>
          <li>
            A natural person who had an individual income in excess of $200,000 in each of the two most recent calendar years or joint income with his or her spouse in excess of $300,000 in each of the two most recent calendar years and who reasonably expects reaching the same income level in the current calendar year
          </li>
        </ul>
        <p>
          If you have any questions, please contact our investor success team.
        </p>

        <p>
          <h4 className="u-mb-none">Email:</h4>
          <a href={emailLink}>{INVESTOR_RELATIONS_EMAIL}</a>
        </p>
      </div>
    </section>
  );
};

export default Ineligible;
