import React from 'react';
import { connect } from 'react-redux';
import Icon from "radius/dist/m2dm/react/icon";

import { renderIf } from '../../common/utils/matchers';
import ExpandBar from '../../common/components/ExpandBar';
import { selectLate } from '../reducers/principalOutstandingReducer';

const mapStateToProps = (state) => {
  return {
    lateHoldings: state.principalOutstanding.data.lateHoldings,
    late: selectLate(state)
  };
}

const MobileLateBreakdown = ({lateHoldings, late, isExpanded, toggleExpand}) => {
  return(
    <div className='trg'>
      <div onClick={toggleExpand} className={`late tr ${isExpanded ? 'expanded' : ''}`}>
        <span className='td label'>
          <i />
          Late
        </span>
        <span className='td amount'>{late.amount}</span>
        <span className='td percentage'>{late.percentOfWhole}</span>
        <span>
          <Icon
            name={`${isExpanded ? "minus-circle-outline" : "plus-circle-outline"}`}
            extraClasses="expand-collapse svg-icon--action svg-icon--large"
          />
        </span>
      </div>
      {renderIf(isExpanded)(
        <div className='late-holdings-breakdown'>
          <table>
            <tbody>
              {lateHoldings.map((lateHolding, i) => (
                <tr
                  className={`late-${i}`}
                  key={lateHolding.bucketRange}>
                  <td className='bucket'>
                    {lateHolding.bucketRange} <span className='units'>days</span>
                  </td>
                  <td className='amount'>{lateHolding.amount}</td>
                  <td className='percentage'>{lateHolding.percentOfWhole}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MobileLateBreakdown);
