import React from "react";
import { connect } from "react-redux";
import { INVESTOR_RELATIONS_EMAIL, MODAL_NAME } from "../constants";
import { closeModal } from "../actions";
import Modal from "../modals/Modal";

const ContactSupportContainer = ({ closeModal, visible }) => (
  <Modal visible={visible}>
    <div className="modal__content u-grid-141">
      <h2 className="section-heading section-heading--x-small">Withdrawal of Funds</h2>
      <p className="paragraph-heading paragraph-heading--normal u-mb-medium">
        Please reach out to <a href={`mailto:${INVESTOR_RELATIONS_EMAIL}`}>{` ${INVESTOR_RELATIONS_EMAIL} `}</a> to have funds wired to your account.
      </p>
      <div className="cta-combo">
        <a
          className="cta-primary cta-combo__primary u-mb-medium"
          href={`mailto:${INVESTOR_RELATIONS_EMAIL}`}
        >
          Email Support team
        </a>
        <div className="cta-combo__secondary-wrap u-mb-large">
          <button
            type="button"
            className="cta-link cta-combo__secondary pointer"
            onClick={() => closeModal()}
          >
            {"I'll do this later"}
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

const mapStateToProps = state => ({
  visible: state.activeModal === MODAL_NAME.CONTACT_SUPPORT,
});

export default connect(mapStateToProps, { closeModal })(ContactSupportContainer);
