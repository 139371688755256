import React from "react";

import { renderIf } from "../helpers";

const CollapsedCard = ({
  children,
  isValid,
  onEdit,
  title,
  visible,
}) => {
  return renderIf(visible)(
    <div className="step-card__collapse">
      <h2 className="paragraph-heading paragraph-heading--large paragraph-heading--collapsed u-mb-none">
        {isValid ?
          <div className="step-card__support-icon step-card__support-icon--mobile-display"></div> :
          <div className="step-card__support-icon step-card__warning-icon step-card__support-icon--mobile-display"></div>
        }

        { title }
      </h2>
      <button
        className="cta-secondary cta-secondary--edit cta--responsive-width"
        onClick={onEdit}>Edit
      </button>
    </div>
  );
};

export default CollapsedCard;
