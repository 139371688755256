import React from "react";
import { gaEvent } from "../../common/utils/common";

const FilterTab = ({
  children,
  onSelect,
  isSelected
}) => (
  <div
    className={`tab filter-tab ${isSelected ? "selected" : ""}`}
    onClick={onSelect}>
    <span className={`label`}>{children}</span>
  </div>
);

const FilterCard = ({
  children,
  limit,
  loading,
  offset,
  onContentClick,
  onNext,
  onPrev,
  onSelect,
  options,
  selected,
}) => {
  const nextDisabled = (offset + limit) >= options.length;
  const prevDisabled = offset === 0;

  const handleTabSelect = (filter) => {
    gaEvent("summary", "select-total-earned-filter", filter.name);
    onSelect(filter.code);
  };

  return (
    <div className="filter-card">
      <div
        className={`content ${loading ? "loading" : ""}`}
        onClick={onContentClick}>
        {children}
      </div>
      <div className="tabs">
        <div
          className={`tab prev ${prevDisabled ? "disabled" : ""}`}
          onClick={onPrev}>
        </div>
        {options.slice(offset, (limit + offset)).map((filter) => (
          <FilterTab
            key={filter.code}
            onSelect={() => handleTabSelect(filter)}
            isSelected={filter.code === selected}>
            {filter.name}
          </FilterTab>
        ))}
        <div
          className={`tab next ${nextDisabled ? "disabled" : ""}`}
          onClick={() => onNext(limit)}>
        </div>
      </div>
    </div>
  );
};

export default FilterCard;
