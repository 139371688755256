import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import * as Scroll from "react-scroll";
import { editStep } from "../actions";
import { createStep } from "../containers/Step";

let Link = Scroll.Link;
let scroll = Scroll.animateScroll;

const STEP_NAME = "reviewProfile";

class View extends React.Component {
  onSubmit (evt) {
    const { allStepsValid, firstInvalidStep, handleSubmit, editStep } = this.props;

    if (allStepsValid) {
      handleSubmit({ validate: false })(evt);
    } else {
      editStep(firstInvalidStep);
    }
  }

  render() {
    return(
      <div className="u-text-center submit-button">
        <Link to="target-scroll" activeclass="active" delay={500} smooth={true} duration={800} isDynamic={true} offset={-17}>
          <button className="cta-primary" onClick={this.onSubmit.bind(this)}>
            Review Profile
          </button>
        </Link>
      </div>
    );
  }
};

const Step = createStep(STEP_NAME)(View);

const allStepsValid = steps => _.every(steps, { isValid: true });

const firstInvalidStep = (steps) => {
  return _.keys(_.omitBy(_.mapValues(steps, { isValid: false }), step => !step))[0];
}

const mapStateToProps = ({ steps }) => {
  return {
    allStepsValid: allStepsValid(steps),
    firstInvalidStep: firstInvalidStep(steps)
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    editStep: step => dispatch(editStep(step))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Step);
