import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../actions";
import { submitCancelTransfer } from "../actions/cancelTransfer";
import { MONTHS, MODAL_NAME } from "../constants";
import { formatMoney } from "../helpers";
import Modal from "../modals/Modal";

const CancelTransferContainer = ({
  submitCancelTransfer,
  closeModal,
  visible,
  toAccount,
  amount,
  month,
  date,
}) => (
  <Modal visible={visible}>
    <div className="modal__content u-grid-141">
      <h2 className="section-heading section-heading--x-small u-mb-medium">Are you sure you want to cancel this transfer?</h2>
      <div className="u-mb-x-small">
        {`On ${month} ${date}`}
      </div>
      <div />
      <p className="four-x-large u-mb-x-small">
        {`$${amount}`}
      </p>
      <p className="u-mb-large">
        {`is scheduled to be transferred into your ${toAccount}`}
      </p>
      <div className="cta-combo u-mb-large">
        <button type="button" className="cta-primary cta-combo__primary" onClick={() => submitCancelTransfer()}>Cancel transfer</button>
        <span className="cta-combo__secondary-wrap">
          <a className="cta-link cta-combo__secondary pointer" onClick={() => closeModal()}>No, go back</a>
        </span>
      </div>
    </div>
  </Modal>
);

const mapStateToProps = (state) => {
  const { selectedTransfer, transferValues, activeModal } = state;

  if (!selectedTransfer || !activeModal) {
    return { visible: false };
  }

  const {
    transfer_at, amount_cents, action,
  } = selectedTransfer;

  const { bankName, lastFourBankAccountNumber } = transferValues;
  const toAccount = action === "withdrawal" ? `${bankName} account ending in ${lastFourBankAccountNumber}` : "Funding Circle account";
  const transferAt = new Date(transfer_at);
  return {
    visible: activeModal === MODAL_NAME.CANCEL_TRANSFER,
    toAccount,
    amount: formatMoney(amount_cents),
    month: MONTHS[transferAt.getMonth()],
    date: transferAt.getDate(),
  };
};

export default connect(mapStateToProps, {
  submitCancelTransfer, closeModal,
})(CancelTransferContainer);
