import React from "react";
import { gaEvent } from "../../common/utils/common";

const PrincipalOutstandingLegend = ({
  principalTypes,
  slidePanelItem,
  slideLegendItem,
  limitedViewEnabled
}) => {
  const cssClasses = (displayName, slidePanelItem, limitedViewEnabled) => {
    const legendItem = displayName.toLowerCase();
    const expanded = slidePanelItem === displayName ? "expanded" : "";
    const inactive = slidePanelItem && slidePanelItem !== displayName ? "inactive" : "";
    let limitedView;

    if (limitedViewEnabled && legendItem === "defaulted") {
      limitedView = "limited-view-enabled";
    } else {
      limitedView = "";
    }

    return `${legendItem} ${expanded} ${inactive} ${limitedView}`;
  };

  const handleSlide = (displayName) => {
    gaEvent("my-portfolio", slidePanelItem === displayName ? "slide-close" : "slide-open", displayName);
    slideLegendItem(displayName);
  };

  return (
    <table className="legend-table">
      <tbody>
        {principalTypes.map(principalType => (
          <tr
            className={`${cssClasses(principalType.displayName, slidePanelItem, limitedViewEnabled)}`}
            onClick={() => handleSlide(principalType.displayName)}
            key={principalType.displayName}
          >
            <td className="label">
              <i />
              {principalType.displayName}
            </td>
            <td className="amount">{principalType.amount}</td>
            <td className="percentage">{principalType.percentOfWhole}</td>
            <td className={`open-arrow ${slidePanelItem === principalType.displayName ? "hidden" : ""}`} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PrincipalOutstandingLegend;
