import React from "react";
import { Provider } from "react-redux";
import ReviewAndConfirmModal from "./modals/ReviewAndConfirmModal";
import TransferInitiatedModal from "./modals/TransferInitiatedModal";
import AddFunds from "./components/AddFunds";
import CancelTransferContainer from "./containers/CancelTransferContainer";
import CancelTransferScheduleContainer from "./containers/CancelTransferScheduleContainer";
import BankFormContainer from "./containers/BankFormContainer";
import AddFundsContainer from "./containers/AddFundsContainer";
import ContactSupportContainer from "./containers/ContactSupportContainer";
import CancelTransferConfirmModal from "./modals/CancelTransferConfirmModal";
import configureStore from "./store";
import RecurringTransfers from "./RecurringTransfers";
import BankForm from "./components/BankForm";
import { ADD_FUNDS, RECURRING_TRANSFERS, BANK_FORM } from "./constants";

const renderView = (props) => {
  const { view } = props;
  switch (view) {
    case ADD_FUNDS:
      return <AddFunds />;
    case RECURRING_TRANSFERS:
      return <RecurringTransfers />;
    case BANK_FORM:
      return <BankForm />;
    default:
      return null;
  }
};

const RecurringTransfersWrapper = props => (
  <Provider store={configureStore(props)}>
    <div>
      <ReviewAndConfirmModal />
      <TransferInitiatedModal />
      <AddFundsContainer />
      <BankFormContainer />
      <ContactSupportContainer />
      <CancelTransferContainer />
      <CancelTransferScheduleContainer />
      <CancelTransferConfirmModal />
      {renderView(props)}
    </div>
  </Provider>
);

export default RecurringTransfersWrapper;
