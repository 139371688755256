import React from "react";

const EditCard = ({
  children,
  visible
}) => {
  return (
    <div style={{ display: visible ? "block" : "none" }}>
      {children}
    </div>
  );
};

export default EditCard;
