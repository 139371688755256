import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { Error, Message } from "./Status";
import { required, dateInRange } from "../validations";
import { editTransferStartDate } from "../actions";
import { transferDisclaimers } from "../constants";
import { getDateWithCurrentTime, formatDate } from "../helpers";

const handleChange = (value, input, dispatch) => {
  const date = value.split("-");
  const dateTime = getDateWithCurrentTime(date[0], parseInt(date[1], 10) - 1, date[2]);
  dispatch(editTransferStartDate("transferStartDate", dateTime));
  input.onChange(dateTime);
};

const DateInput = ({
  formProps, input, label, disabled, meta: { touched, error },
}) => {
  const { transferStartDate, dispatch, minDate } = formProps;
  const startDate = formatDate(transferStartDate);
  return (
    <div className="form-element u-mb-none">
      <label className="form-label form-element__label">
        <span>{label}</span>
        {touched && error && <Error />}
      </label>
      <div className="form-input form-input--icon u-mb-x-small" data-test="transfer-start-date">
        <input
          {...input}
          type="date"
          className="form-input__element"
          id="scheduledTransferDate"
          value={startDate}
          min={minDate}
          disabled={disabled ? "disabled" : ""}
          onChange={e => handleChange(e.target.value, input, dispatch)}
          onBlur={() => input.onBlur(transferStartDate)}
        />
      </div>
      {touched && error && <Message message={error} />}
    </div>
  );
};

const TransferStartDate = (props) => {
  const {
    transferStartDate, disabled, isRecurring, isWithdrawal,
  } = props;

  const presence = required("Please enter a date.");
  const inRange = dateInRange("Scheduled transfer date must be today or later.");
  let disclaimerText = transferDisclaimers.oneTime;
  if (isWithdrawal) {
    disclaimerText = transferDisclaimers.startDateWithdrawal;
  } else if (isRecurring) {
    disclaimerText = transferDisclaimers.recurring;
  }
  return (
    <div>
      <Field
        name="transferStartDate"
        label="Next transfer scheduled for"
        formProps={props}
        component={DateInput}
        validate={disabled ? [] : [presence, inRange]}
        disabled={disabled}
      />
      {transferStartDate && <Message message={disclaimerText} />}
    </div>
  );
};

const mapStateToProps = state => ({
  transferStartDate: state.transferValues.transferStartDate,
  isWithdrawal: state.transferValues.transferType === "withdrawal",
  isRecurring: state.transferValues.recurring === "true",
  minDate: formatDate(),
});

export default connect(mapStateToProps)(TransferStartDate);
