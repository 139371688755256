import React from "react";

import { renderIf } from "../helpers";

const CheckBoxOther = ({
  checked,
  children,
  label,
  onChange,
  name,
}) => {
  return (
    <div className="form-checkbox--custom-wrapper">
      <label className="form-checkbox">
        <input className="form-checkbox__input" type="checkbox" name={name} checked={checked} onChange={onChange} />
        <span className="form-checkbox__icon"></span>
        {label}
      </label>
      {renderIf(checked)(<div>{children}</div>)}
    </div>
  );
};

CheckBoxOther.defaultProps = { name: "", value: "", label: "", checked: false };

export default CheckBoxOther;
