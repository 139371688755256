import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import reducers from "./reducers";

import { loadInitialValues } from "./actions";

const middlewares = [
  thunk,
];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}
const initialValues = {
  recurring: "false",
  submitText: "Transfer",
  showTransferType: true,
  showRestOfTheTransferForm: false,
  showReviewAndConfirmModal: false,
  showTransferInitiatedModal: false,
  showAddBankAccountModal: false,
  showContactSupportModal: false,
  showAddFundsModal: false,
  transferStartDate: new Date(),
};
const configureStore = (props) => {
  const store = createStore(reducers, applyMiddleware(...middlewares));
  store.dispatch(loadInitialValues({ ...initialValues, ...props }));
  return store;
};

export default configureStore;
