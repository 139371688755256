import React from 'react';
import _ from 'lodash';

const ErrorMessage = ({error, setErrorTimer, clearError}) => {
  if (error.present) {
    setErrorTimer();
    return (
      <div className={`error error-message`}>
        <a onClick={clearError}>
          <i className={'close fa fa-times'} />
        </a>

        <div className='message'>
          {error.message}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ErrorMessage;
