import React from 'react';

import { columnNames } from '../constants';

const HoldingRow = ({holding}) => {
  return(
    <tr className="background-mobile">
      <td className="left holdings__date">
        <div className="holdings__heading">{columnNames['purchased_at']}</div>
        <div className="holdings__data">{holding.purchased_at}</div>
      </td>
      <td className="left holdings__summary">
        <div className="holdings__data holdings__name">
          <a href={holding.item_url} > {holding.loan_name} </a>
        </div>
      </td>
      <td className="holdings__date-mobile">
        <div className="holdings__heading">{columnNames['purchased_at']}</div>
        <div className="holdings__data">{holding.purchased_at}</div>
      </td>
      <td className="left">
        <div className="holdings__heading">{columnNames['status']}</div>
        <div className="holdings__data">{holding.status}</div>
      </td>
      <td className="right">
        <div className="holdings__heading">{columnNames['original_principal_cents']}</div>
        <div className="holdings__data">
          {holding.original_principal}
        </div>
      </td>
      <td className="right u-pr-small">
        <div className="holdings__heading">{columnNames['remaining_principal_cents']}</div>
        <div className="holdings__data">
          {holding.remaining_principal}
        </div>
      </td>
      <td className="left">
        <div className="holdings__heading">{columnNames['credit_rating']}</div>
        <div className="holdings__data">{holding.credit_rating}</div>
      </td>
      <td className="center">
        <div className="holdings__heading">{columnNames['term_months']}</div>
        <div className="holdings__data">{holding.term}</div>
      </td>
      <td className="right">
        <div className="holdings__heading">{columnNames['interest_rate']}</div>
        <div className="holdings__data">{holding.interest_rate}</div>
      </td>
    </tr>
  )
}

export default HoldingRow;
