export const required = message => value => (value ? undefined : message);

export const sufficientFunds = balance => value => (
  value && parseFloat(value) > parseFloat(balance)
    ? `You do not have a sufficient balance to
    withdraw this amount. Please enter an amount less than your available funds.`
    : undefined
);

export const dateInRange = message => date => (
  (date && date >= new Date().setHours(0, 0, 0, 0)) ? undefined : message
);

export const maxLength = max => value => (value && value.length > max);

export const fieldFormat = (message, regex) => value => (
  value && !regex.test(value) ? message : undefined
);
