import { combineReducers } from "redux";

import totalEarnedReducer from "./totalEarnedReducer";
import configReducer from "../../common/reducers/configReducer";
import performanceFiguresReducer from "./performanceFiguresReducer";
import errorReducer from "../../common/reducers/errorReducer";
import portfolioBalanceReducer from "./portfolioBalanceReducer";
import viewReducer from "../../common/reducers/viewReducer";
import showModalReducer from "./showModalReducer";

const rootReducer = combineReducers({
  config: configReducer,
  error: errorReducer,
  performanceFigures: performanceFiguresReducer,
  portfolioBalance: portfolioBalanceReducer,
  showModal: showModalReducer,
  totalEarned: totalEarnedReducer,
  view: viewReducer,
});

export default rootReducer;
