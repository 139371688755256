import _ from "lodash";

export function TRANSFER_TYPES(lastFourBankAccountNumber) {
  return (
    [{
      id: "transfer-type-1",
      name: "transferType",
      value: "deposit",
      displayImage: "fc-image",
      boldText: "Transfer in",
      displayText: " to your Funding Circle account",
      dataTestAttribute: "transfer-type-deposit",
    }, {
      id: "transfer-type-2",
      name: "transferType",
      value: "withdrawal",
      boldText: "Transfer out",
      displayText: ` to your bank account ${lastFourBankAccountNumber}`,
      dataTestAttribute: "transfer-type-withdrawal",
    }]
  );
}

export const recurringOptions = disableRecurrence => (
  [{
    id: "recurring-option-1",
    name: "recurring",
    value: "false",
    displayText: "One time",
    isDisabled: false,
    dataTestAttribute: "recurrence-one-time",
  }, {
    id: "recurring-option-2",
    name: "recurring",
    value: "true",
    displayText: "Recurring",
    isDisabled: disableRecurrence,
    dataTestAttribute: "recurrence-recurring",
  }]
);

export const FREQUENCY_OPTIONS = [{
  id: "frequency-option-1",
  name: "frequency",
  value: "weekly",
  displayText: "Weekly",
  dataTestAttribute: "frequency-weekly",
}, {
  id: "frequency-option-2",
  name: "frequency",
  value: "monthly",
  displayText: "Monthly",
  dataTestAttribute: "frequency-monthly",
}];

export const DAYS_OF_WEEK = [{
  id: "day-0",
  name: "dayOfWeek",
  weekday: false,
  value: 0,
  abbreviatedDisplayText: "Sun",
  displayText: "Sunday",
  dataTestAttribute: "day-of-week-sunday",
}, {
  id: "day-1",
  name: "dayOfWeek",
  weekday: true,
  value: 1,
  abbreviatedDisplayText: "Mon",
  displayText: "Monday",
  dataTestAttribute: "day-of-week-monday",
}, {
  id: "day-2",
  name: "dayOfWeek",
  weekday: true,
  value: 2,
  abbreviatedDisplayText: "Tue",
  displayText: "Tuesday",
  dataTestAttribute: "day-of-week-tuesday",
}, {
  id: "day-3",
  name: "dayOfWeek",
  weekday: true,
  value: 3,
  abbreviatedDisplayText: "Wed",
  displayText: "Wednesday",
  dataTestAttribute: "day-of-week-wednesday",
}, {
  id: "day-4",
  name: "dayOfWeek",
  weekday: true,
  value: 4,
  abbreviatedDisplayText: "Thu",
  displayText: "Thursday",
  dataTestAttribute: "day-of-week-thursday",
}, {
  id: "day-5",
  name: "dayOfWeek",
  weekday: true,
  value: 5,
  abbreviatedDisplayText: "Fri",
  displayText: "Friday",
  dataTestAttribute: "day-of-week-friday",
}, {
  id: "day-6",
  name: "dayOfWeek",
  weekday: false,
  value: 6,
  abbreviatedDisplayText: "Sat",
  displayText: "Saturday",
  dataTestAttribute: "day-of-week-saturday",
}];

export const DATES_OF_MONTH = [];

_.times(28, (index) => {
  const i = index + 1;
  const date = {
    id: `date-${i}`,
    name: "dateOfMonth",
    value: `${i}`,
    displayText: i,
    dataTestAttribute: `day-of-month-${i}`,
  };
  DATES_OF_MONTH.push(date);
});

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const transferDisclaimers = {
  recurring: "Transfers to Funding Circle can take up to 8 business days to appear in your account. Transfers in this schedule that fall on a weekend or holiday will execute on the next business day.",
  oneTime: "Transfers to Funding Circle can take up to 8 business days to appear in your account. Transfers are executed on business days only.",
  recurringWithdrawal: "Recurring transfers are only available for transfers into your Funding Circle account",
  startDateWithdrawal: "Transfers are executed on business days only",
};

export const ACCOUNT_TYPE = [{
  id: "account-type-1",
  name: "account_type",
  value: "checking",
  displayText: "Checking",
  dataTestAttribute: "account-type-checking",
}, {
  id: "account-type-2",
  name: "account_type",
  value: "savings",
  displayText: "Savings",
  dataTestAttribute: "account-type-savings",
}];

export const MODAL_NAME = Object.freeze({
  ADD_FUNDS: "add-funds-modal",
  ADD_BANK_ACCOUNT: "add-bank-account-modal",
  CONTACT_SUPPORT: "contact-support-modal",
  CANCEL_TRANSFER: "cancel-transfer-modal",
  CANCEL_TRANSFER_SCHEDULE: "cancel-transfer-schedule-modal",
  CANCEL_TRANSFER_CONFIRM: "cancel-transfer-confirmation-modal",
});

export const INVESTOR_RELATIONS_EMAIL = "support@fundingcircle-invest.com";
export const RECURRING_TRANSFERS = "RECURRING_TRANSFERS";
export const BANK_FORM = "BANK_FORM";

export const MODAL_TIME_OUT = 5000; //  time in ms

export const LOAD = "LOAD";
export const EDIT_AMOUNT = "EDIT_AMOUNT";
export const EDIT_FREQUENCY = "EDIT_FREQUENCY";
export const EDIT_RECURRENCE = "EDIT_RECURRENCE";
export const EDIT_DAY_OF_WEEK = "EDIT_DAY_OF_WEEK";
export const EDIT_DATE_OF_MONTH = "EDIT_DATE_OF_MONTH";
export const EDIT_TRANSFER_START_DATE = "EDIT_TRANSFER_START_DATE";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_REVIEW_AND_CONFIRM_MODAL = "SHOW_REVIEW_AND_CONFIRM_MODAL";
export const SHOW_TRANSFER_INITIATED_MODAL = "SHOW_TRANSFER_INITIATED_MODAL";
export const SUBMIT_TRANSFER = "SUBMIT_TRANSFER";
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_FAILURE = "TRANSFER_FAILURE";

export const ADD_FUNDS = "ADD_FUNDS";
export const SELECT_TRANSFER_TYPE = "SELECT_TRANSFER_TYPE";

export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const SHOW_ADD_BANK_ACCOUNT_MODAL = "SHOW_ADD_BANK_ACCOUNT_MODAL";
export const SHOW_REST_OF_THE_TRANSFER_FORM = "SHOW_REST_OF_THE_TRANSFER_FORM";
export const UPDATE_FIELDS_AND_VALUES = "UPDATE_FIELDS_AND_VALUES";


export const CANCEL_TRANSFER = "CANCEL_TRANSFER";
export const CANCEL_TRANSFER_SCHEDULE = "CANCEL_TRANSFER_SCHEDULE";

export const CANCEL_TRANSFER_SUCCESS = "CANCEL_TRANSFER_SUCCESS";
export const CANCEL_TRANSFER_FAILURE = "CANCEL_TRANSFER_FAILURE";

export const CANCEL_TRANSFER_SCHEDULE_SUCCESS = "CANCEL_TRANSFER_SCHEDULE_SUCCESS";
export const CANCEL_TRANSFER_SCHEDULE_FAILURE = "CANCEL_TRANSFER_SCHEDULE_FAILURE";

export const CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL = "CLOSE_CANCEL_TRANSFER_CONFIRM_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
