import _ from "lodash";

export function matchers(mediaSizes, width) {
  const fromScreen = (screen, w) => {
    const eq = _.inRange(w, screen.minWidth, screen.maxWidth + 1);
    const gte = w >= screen.minWidth;
    const lte = w <= screen.maxWidth;

    return {
      lte: {
        [screen.device]: {
          [screen.size]: lte,
        },
      },
      gte: {
        [screen.device]: {
          [screen.size]: gte,
        },
      },
      eq: {
        [screen.device]: {
          [screen.size]: eq,
        },
      },
    };
  };

  const reducer = (obj, screen) => _.merge(obj, fromScreen(screen, width));

  return _.reduce(mediaSizes, reducer, {});
}

export function media(mediaSizes, width) {
  // need the + 1 because _.inRange goes up to but not including the last parameter
  return _.find(mediaSizes, screen => _.inRange(width, screen.minWidth, screen.maxWidth + 1));
}

export function screenResize(mediaSizes, width) {
  return _.assign(
    {},
    { mq: media(mediaSizes, width) },
    { win: { width } },
    matchers(mediaSizes, width),
  );
}

export default function viewReducer(state = {
  scrollTop: 0,
}, action) {
  switch (action.type) {
    case "WINDOW_SCROLL":
      return { ...state, scrollTop: action.yPos };
    case "SCREEN_RESIZE":
      return screenResize(action.mediaSizes, action.width);
    default:
      return state;
  }
}
