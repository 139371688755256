import _ from "lodash";

import { ACCOUNT_TYPES } from "./constants";

import { parseDate } from "./helpers";

const messageOrDefault = message => defaultMessage => message || defaultMessage;

export function validationResult(isValid, message) {
  const msg = (!isValid && !message) ? "is invalid" : message;

  return {
    isValid,
    message: isValid ? "" : msg,
  };
}

const isBlank = value => (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value);

export function presence(val, msg) {
  const str = _.toString(val);
  const isValid = !isBlank(str);
  const message = msg("Please provide a response");

  return validationResult(isValid, message);
}

export function nameFormat(val, msg) {
  const str = _.toString(val);
  const isValid = str.length && /^[A-Za-z-' ]*$/.test(str);
  const message = msg("can only contain letters and whitespace");

  return validationResult(isValid, message);
}

export function emailFormat(val, msg) {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
  const message = msg("Please enter a valid email address, containing '@' and '.'");

  return validationResult(isValid, message);
}

export function presentIfIndividual(val, msg, field, profile) {
  const isValid = profile.investorAccountType === ACCOUNT_TYPES.INDIVIDUAL && !isBlank(val);
  const message = msg("required field");

  return validationResult(isValid, message);
}

export function phoneFormat(val, msg) {
  const isValid = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/.test(val);
  const message = msg("Please provide a valid U.S. phone number, including your area code");
  return validationResult(isValid, message);
}

export function callBetween(val, msg) {
  if (isBlank(val)) {
    return validationResult(true, "");
  }

  if (!isBlank(val.startVal) && isBlank(val.endVal)) {
    return validationResult(false, msg("A latest time to call is required if you have selected an earliest time to call"));
  }

  if (isBlank(val.startVal) && !isBlank(val.endVal)) {
    return validationResult(false, msg("An earliest time to call is required if you have selected a latest time to call"));
  }

  if (parseInt(val.startVal, 10) >= parseInt(val.endVal, 10)) {
    return validationResult(false, msg("The latest time to call must be later than the earliest time to call"));
  }

  return validationResult(true, "");
}

export function ssnFormat(val, msg) {
  const isValid = (!isBlank(val)
                  && /(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)/.test(val)
                  && val.toString().replace(/-/g, "").length === 9);

  const message = msg("invalid ssn format");

  return validationResult(isValid, message);
}

export function routingNumberFormat(val, msg) {
  const isValid = /^\d{9}$/.test(val);

  const message = msg("Please enter a 9 digit routing number");

  return validationResult(isValid, message);
}

export function accountNumberFormat(val, msg) {
  const isValid = /^(\d){3,100}$/.test(val);
  const message = msg("Please provide a valid bank account containing between 3 and 100 numbers");

  return validationResult(isValid, message);
}

export function dateFormat(val, msg) {
  const date = parseDate(val);
  const isValid = (!_.isNull(date) && date.isValid());
  const message = msg("Please provide a valid date");
  return validationResult(isValid, message);
}

export function pastDateFormat(val, msg) {
  const date = parseDate(val);

  if (_.isDate(date)) {
    const dateEpoch = parseDate(val).getTime();
    const currentDateEpoch = new Date().setHours(0, 0, 0, 0);

    const isValid = dateEpoch < currentDateEpoch;
    const message = msg("Date must be before today");
    return validationResult(isValid, message);
  }
  return dateFormat(val, msg);
}

export function passwordFormat(val, msg) {
  const isValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/.test(val);
  const message = msg("Your password must be between 8 and 128 characters and include one uppercase letter, one lowercase letter, and a number");

  return validationResult(isValid, message);
}

export function matchesPassword(val, msg, field, profile) {
  const isValid = profile.password === val;
  const message = msg("The password and confirmation password must match");

  return validationResult(isValid, message);
}

export function zipCodeFormat(val, msg) {
  const isValid = /^\d{5}$/.test(val);
  const message = msg("Please provide a 5 digit zip code");

  return validationResult(isValid, message);
}

export function notEmpty(val, msg) {
  const isValid = val && val.length > 0;
  const message = msg("Please select an option");

  return validationResult(isValid, message);
}

export function moneyFormat(val, msg) {
  const isValid = /^[0-9]+(\.[0-9]{1,2})?$/.test(val);
  const message = msg("invalid amount");

  return validationResult(isValid, message);
}

export function maxLength(val, msg, _field, _profile, size) {
  const isValid = !val || val.length <= size;
  const message = msg(`Must not exceed ${size} characters`);

  return validationResult(isValid, message);
}

export function minLength(val, msg, _field, _profile, size) {
  const isValid = !val || val.length >= size;
  const message = msg(`Must exceed ${size} characters`);

  return validationResult(isValid, message);
}

export function mailingAddressLength(val, msg, field, profile) {
  const isValid = `${profile.mailingAddressOne}, ${profile.mailingAddressTwo}`.length <= 255;
  const message = msg("Mailing address cannot exceed 255 characters");
  return validationResult(isValid, message);
}

export function cityFormat(val, msg) {
  const isValid = /^([^0-9]{1,})$/.test(val);
  const message = msg("Please enter a valid city");

  return validationResult(isValid, message);
}

export function successfulUpload(val, msg) {
  const isValid = _.some(val, ["error", false]);
  const message = msg("Please upload a file");

  return validationResult(isValid, message);
}

export const validations = {
  callBetween,
  presence,
  nameFormat,
  notEmpty,
  emailFormat,
  presentIfIndividual,
  phoneFormat,
  passwordFormat,
  matchesPassword,
  zipCodeFormat,
  ssnFormat,
  routingNumberFormat,
  accountNumberFormat,
  dateFormat,
  pastDateFormat,
  moneyFormat,
  maxLength,
  minLength,
  mailingAddressLength,
  cityFormat,
  successfulUpload,
};

export function validateFields(keys, profile) {
  return (value, field) => keys.map((key) => {
    const [validation, message, args] = _.isArray(key) ? key : [key, null, null];

    if (!validations[validation]) {
      throw new Error(`validations does not return ${validation}`);
    }
    return validations[validation](
      value,
      messageOrDefault(message),
      field,
      profile,
      args,
    );
  })
    .filter(result => !result.isValid)
    .map(result => result.message);
}
