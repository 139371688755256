import React from "react";
import MobileLateBreakdown from "./MobileLateBreakdown";
import MobileDefaultedBreakdown from "./MobileDefaultedBreakdown";
import PieChart from "./PieChart";
import { LATE_ID, DEFAULTED_ID } from "../constants";
import { makeExpandableInline } from "../../common/helpers/wrappers";

const PrincipalOutstandingMobile = ({principalOutstanding, toggleExpand, mediaClass}) => {
  const current = _.find(principalOutstanding.data.principalTypes, { displayName: "current" });
  const ExpandableCard = (cardID, isExpanded, card) => {
    return makeExpandableInline(() => toggleExpand(cardID), isExpanded)(card);
  };

  return (
    <div className={`principal-outstanding-container ${mediaClass}`}>
      <div className="principal-outstanding-header">
        <span className="paragraph-heading label">Outstanding Principal</span>
        {principalOutstanding.data.total}
      </div>
      <div className="principal-outstanding-data">
        <PieChart
          dimensions={{ width: 167, height: 167 }}
          data={principalOutstanding.data.principalTypes}
        />
        <div className="legend-table">
          <div className="current tr">
            <span className="td label">
              <i />
              Current
            </span>
            <span className="td amount">{current.amount}</span>
            <span className="td percentage">{current.percentOfWhole}</span>
          </div>
          {ExpandableCard(
            LATE_ID,
            principalOutstanding.lateExpanded,
            MobileLateBreakdown,
          )}
          {ExpandableCard(
            DEFAULTED_ID,
            principalOutstanding.defaultedExpanded,
            MobileDefaultedBreakdown,
          )}
        </div>
      </div>
    </div>
  );
};

export default PrincipalOutstandingMobile;
